import { useMutation } from "@apollo/client";
import { PaginatedRestIndexQuery, QueryOptions, SelectOption } from "../../types";
import { Comment as CommentType } from "../../types";
import { TextIconButton } from "../common/TextIconButton";
import { APPROVED_ICON, REJECTED_ICON } from "../../constants/icons";
import { Loader } from "../common/Loader";
import { useCallback, useEffect, useState } from "react";
import { UPDATE_COMMENT_SCREENING_STATUS } from "../../mutations/updateCommentScreeningStatus";
import { publish } from "../../util/events";
import { RejectionFormModal } from "./RejectionFormModal";
import { PaginationControl } from "../common/PaginationControl";
import { Link } from "react-router-dom";
import { useRestQueryBuilder } from "../../hooks/useRestQueryBuilder";
import { SortWidget } from "./SortWidget";
import { TextFilterWidget } from "./TextFilterWidget";
import { SelectFilterWidget } from "./SelectFilterWidget";
import { useCsvDownload } from "../../hooks/useCsvDownload";
import { AsyncTaskButton } from "./AsyncTaskButton";

const MODERATION_STATUS_OPTIONS: SelectOption<string>[] = [
  {
    value: 'approved',
    displayName: 'Approved'
  },
  {
    value: 'rejected',
    displayName: 'Rejected'
  },
  {
    value: 'pending',
    displayName: 'Pending'
  }
];

export const ModerateComments = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rejectedComment, setRejectedComment] = useState<CommentType | null>(
    null
  );
  const [query, setQuery] = useState<QueryOptions>({
    filters: [],
    sort: {
      field: "createdAt",
      direction: "desc"
    },
    populate: ['user'],
    pagination: {
      page: pageNumber,
      pageSize: 10
    }
  });

  const { exportCsv, isLoading, statusMessage } = useCsvDownload("/csv/comments", "photo-contest-comments_" + new Date().toISOString() + ".csv");

  const { data: commentData, loading, errors } = useRestQueryBuilder<PaginatedRestIndexQuery<CommentType>>('comments', query);

  useEffect(() => {
    setQuery((q) => {
      q.pagination.page = pageNumber;
      return { ...q };
    });
  }, [pageNumber]);

  const [setScreeningStatus] = useMutation(UPDATE_COMMENT_SCREENING_STATUS);

  const approveComment = useCallback(
    async (comment: CommentType) => {
      if (comment.attributes.screeningStatus === 'approved') return;

      await setScreeningStatus({
        variables: {
          id: comment.id,
          screeningStatus: "approved",
        },
      });

      publish("showToast", {
        title: "Comment Approved",
        message: `Comment with ID ${comment.id} has been marked as approved by a moderator.`,
        id: Date.now(),
      });

      setQuery(q => ({ ...q }));
    },
    [setScreeningStatus]
  );

  const launchRejectCommentModal = useCallback(
    (comment: CommentType) => {
      if (comment.attributes.screeningStatus === 'rejected') return;

      setRejectedComment(comment);
    },
    []
  );

  let body: JSX.Element;
  if (loading && commentData === undefined) {
    body = <Loader message="Loading comments..." />;
  } else if (errors.length > 0 || commentData === undefined) {
    body = (
      <div className="alert alert-danger my-3" role="alert">
        <h6 className="fs-5">Error: Failed to load comments</h6>
        <ul>
          {
            errors.map((err, i) => (
              <li key={`err-${i}`}>{err}</li>
            ))
          }
        </ul>
      </div>
    );
  } else {
    const { pageSize, total, pageCount } = commentData.meta.pagination;

    body = (
      <>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="align-top cursor-pointer">
                Id&nbsp;
                <SortWidget query={query} setQuery={setQuery} field="id" />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Posted At&nbsp;
                <SortWidget query={query} setQuery={setQuery} field="createdAt" />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Content&nbsp;
                <SortWidget query={query} setQuery={setQuery} field="body" />
                <TextFilterWidget query={query} setQuery={setQuery} field="body" />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                User&nbsp;
                <SortWidget query={query} setQuery={setQuery} field={['user.lastName', 'user.firstName']} />
                <TextFilterWidget query={query} setQuery={setQuery} field={['user.lastName', 'user.firstName']} />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Moderation Status&nbsp;
                <SortWidget query={query} setQuery={setQuery} field="id" />
                <SelectFilterWidget query={query}
                  setQuery={setQuery}
                  field="screeningStatus"
                  options={MODERATION_STATUS_OPTIONS} />
              </th>
            </tr>
          </thead>
          <tbody>
            {commentData.data.map((c) => (
              <tr key={c.id}>
                <th>{c.id}</th>
                <td>{new Date(c.attributes.createdAt).toLocaleString()}</td>
                <td>{c.attributes.body}</td>
                <td>
                  {c.attributes.user.data.attributes.lastName},{" "}
                  {c.attributes.user.data.attributes.firstName}
                </td>
                <td>
                  <div className="d-flex gap-2">
                    <TextIconButton
                      text={c.attributes.screeningStatus === 'approved' ? 'Approved' : 'Approve'}
                      icon={APPROVED_ICON}
                      variant={c.attributes.screeningStatus === 'approved' ? 'success' : 'outline-success'}
                      size="sm"
                      onClick={() => approveComment(c)}
                    />
                    <TextIconButton
                      text={c.attributes.screeningStatus === 'rejected' ? 'Rejected' : 'Reject'}
                      icon={REJECTED_ICON}
                      variant={c.attributes.screeningStatus === 'rejected' ? 'danger' : 'outline-danger'}
                      size="sm"
                      onClick={() => launchRejectCommentModal(c)}
                    />

                    {
                      c.attributes.screeningStatus === 'pending' && (
                        <span className="badge border border-secondary text-secondary align-self-center ms-3">Pending Review</span>
                      )
                    }
                  </div>
                </td>
              </tr>
            ))}

            {commentData.data.length === 0 && loading === false && (
              <tr>
                <td colSpan={6} className="py-5 text-center">
                  <p className="text-secondary fst-italic">
                    There are no more comments to moderate.
                  </p>
                  <Link to="/moderate">Back to Moderation Dashboard</Link>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <PaginationControl
          page={pageNumber}
          pageSize={pageSize}
          total={total}
          pageCount={pageCount}
          setPageNumber={setPageNumber}
        />
      </>
    );
  }

  return (
    <>
      <section className="container">
        <h2>Moderate Comments</h2>
        <p>
          <span className="fw-bold">Instructions:</span> Reject any comments
          that are not appropriate for work. Those comments will be hidden from
          public view, and the author should be contacted directly by a
          moderator to explain the situation.
        </p>

        <div className="d-flex gap-2 align-items-center">
          <AsyncTaskButton callback={exportCsv}
            isLoading={isLoading}
            statusMessage={statusMessage}
            buttonText="Export Comments as CSV" />
        </div>

        {body}
      </section>

      <RejectionFormModal
        show={rejectedComment !== null}
        onHide={() => setRejectedComment(null)}
        contentType="Comment"
        content={rejectedComment as CommentType}
        onReject={(content) => {
          publish("showToast", {
            id: `toast-${new Date().toISOString()}`,
            title: "Comment Rejected",
            message: `Comment with ID ${content?.id ?? "unknown"
              } will be hidden from public view.`,
          });

          setQuery(q => ({ ...q }));
        }}
      />
    </>
  );
};
