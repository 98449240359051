import React, { useCallback, useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { RejectionFormModal } from "./RejectionFormModal";
import {
  ModerationStatus,
  PaginatedRestIndexQuery,
  Photo,
  QueryOptions,
  SelectOption,
} from "../../types";
import { Lightbox } from "../photo/Lightbox";
import {
  APPROVED_ICON,
  FULLSCREEN_ICON,
  HOLD_FOR_REVIEW_ICON,
  REJECTED_ICON,
} from "../../constants/icons";
import { TextIconButton } from "../common/TextIconButton";
import { useMutation } from "@apollo/client";
import { UPDATE_PHOTO_SCREENING_STATUS } from "../../mutations/updatePhotoScreeningStatus";
import { publish } from "../../util/events";
import { Loader } from "../common/Loader";
import { PaginationControl } from "../common/PaginationControl";
import { useRestQueryBuilder } from "../../hooks/useRestQueryBuilder";
import { CategoriesContext } from "../../context/CategoriesContext";
import { SortWidget } from "./SortWidget";
import { TextFilterWidget } from "./TextFilterWidget";
import { SelectFilterWidget } from "./SelectFilterWidget";
import { useCsvDownload } from "../../hooks/useCsvDownload";
import { AsyncTaskButton } from "./AsyncTaskButton";

const MODERATION_STATUS_OPTIONS: SelectOption<string>[] = [
  {
    value: "approved",
    displayName: "Approved",
  },
  {
    value: "rejected",
    displayName: "Rejected",
  },
  {
    value: "pending",
    displayName: "Pending",
  },
  {
    value: "heldForReview",
    displayName: "Held for Review"
  }
];

export const ModeratePhotos = () => {
  const [rejectedPhoto, setRejectedPhoto] = useState<Photo | null>(null);
  const [lightboxPhoto, setLightboxPhoto] = useState<Photo | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [query, setQuery] = useState<QueryOptions>({
    filters: [],
    populate: ["user", "category", "photo"],
    pagination: {
      page: 1,
      pageSize: 10,
    },
    sort: {
      field: "createdAt",
      direction: "desc"
    }
  });
  const categoryOptions: SelectOption<number>[] = useContext(
    CategoriesContext
  ).map((c) => ({
    value: c.id,
    displayName: c.attributes.displayName,
  }));

  const { exportCsv, isLoading, statusMessage } = useCsvDownload(
    "/csv/photos",
    "photo-contest-photos_" + new Date().toISOString() + ".csv"
  );

  const {
    data: photoData,
    loading,
    errors,
  } = useRestQueryBuilder<PaginatedRestIndexQuery<Photo>>("photos", query);

  useEffect(() => {
    setQuery((q) => {
      q.pagination.page = pageNumber;
      return { ...q };
    });
  }, [pageNumber]);

  const [setScreeningStatus] = useMutation(UPDATE_PHOTO_SCREENING_STATUS);

  const launchRejectPhotoModal = useCallback((photo: Photo) => {
    if (photo.attributes.screeningStatus === "rejected") return;

    setRejectedPhoto(photo);
  }, []);

  const togglePhotoHeldForReview = useCallback(async (photo: Photo) => {
    let newScreeningStatus: ModerationStatus;
    const toastOptions: any = {
      id: `toast-${new Date().toISOString()}`
    };

    if (photo.attributes.screeningStatus === "pending") {
      newScreeningStatus = "heldForReview";
      toastOptions.title = "Photo Held for Review";
      toastOptions.message = `Photo with ID ${photo.id} is marked as held for review.`;
    } else {
      newScreeningStatus = "pending";
      toastOptions.title = "Photo No Longer Held for Review";
      toastOptions.message = `Photo with ID ${photo.id} is no longer marked as held for review.`;
    }

    await setScreeningStatus({
      variables: {
        id: photo.id,
        screeningStatus: newScreeningStatus,
      },
    });

    publish("showToast", toastOptions);

    setQuery((q) => ({ ...q }));
  }, [
    setScreeningStatus,
    setQuery
  ]);

  const approvePhoto = useCallback(
    async (photo: Photo) => {
      if (photo.attributes.screeningStatus === "approved") return;

      await setScreeningStatus({
        variables: {
          id: photo.id,
          screeningStatus: "approved",
        },
      });

      publish("showToast", {
        id: `toast-${new Date().toISOString()}`,
        title: "Photo Approved",
        message: `Photo with ID ${photo.id} will be displayed publicly.`,
      });

      setQuery((q) => ({ ...q }));
    },
    [setScreeningStatus, setQuery]
  );

  let body: JSX.Element;
  if (loading && photoData === undefined) {
    body = <Loader message="Loading Photos..." />;
  } else if (errors.length > 0 || photoData === undefined) {
    body = (
      <div className="alert alert-warning" role="alert">
        <p className="fs-5 fw-semibold">There was an error loading photos.</p>
        <ul>
          {errors.map((err, i) => (
            <li key={`err-${i}`}>{err}</li>
          ))}
        </ul>
        <Link to="/moderate">Back to Moderation Dashboard</Link>
      </div>
    );
  } else {
    const photos = photoData.data;

    const { total, pageSize, pageCount } = photoData.meta.pagination;

    body = (
      <>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="align-top cursor-pointer">
                Id&nbsp;
                <SortWidget query={query} setQuery={setQuery} field="id" />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Name&nbsp;
                <SortWidget query={query} setQuery={setQuery} field="name" />
                <TextFilterWidget
                  query={query}
                  setQuery={setQuery}
                  field="name"
                />
              </th>
              <th scope="col" className="align-top">
                Photo
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Category&nbsp;
                <SortWidget
                  query={query}
                  setQuery={setQuery}
                  field="category.name"
                />
                <SelectFilterWidget
                  query={query}
                  setQuery={setQuery}
                  field="category.id"
                  options={categoryOptions}
                />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Submitted By&nbsp;
                <SortWidget
                  query={query}
                  setQuery={setQuery}
                  field={["user.lastName", "user.firstName"]}
                />
                <TextFilterWidget
                  query={query}
                  setQuery={setQuery}
                  field={["user.lastName", "user.firstName"]}
                />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Moderation Status&nbsp;
                <SortWidget
                  query={query}
                  setQuery={setQuery}
                  field="screeningStatus"
                />
                <SelectFilterWidget
                  query={query}
                  setQuery={setQuery}
                  field="screeningStatus"
                  options={MODERATION_STATUS_OPTIONS}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {photos.map((p) => (
              <tr key={p.id}>
                <th scope="row">{p.id}</th>
                <td>{p.attributes.name}</td>
                <td>
                  <TextIconButton
                    text="View Full"
                    icon={FULLSCREEN_ICON}
                    size="sm"
                    onClick={() => setLightboxPhoto(p)}
                  />
                </td>
                <td>{p.attributes.category.data.attributes.displayName}</td>
                <td>
                  {p.attributes.user.data.attributes.lastName},&nbsp;
                  {p.attributes.user.data.attributes.firstName}
                </td>
                <td>
                  <div className="d-flex gap-2">
                    {
                      (() => {
                        switch (p.attributes.screeningStatus) {
                          case "approved":
                            return (
                              <>
                                <TextIconButton text="Approved"
                                  icon={APPROVED_ICON}
                                  variant="success"
                                  size="sm"
                                  onClick={() => { }}
                                />
                                <TextIconButton text="Reject"
                                  icon={REJECTED_ICON}
                                  variant="outline-danger"
                                  size="sm"
                                  onClick={() => launchRejectPhotoModal(p)}
                                />
                              </>
                            );
                          case "rejected":
                            return (
                              <>
                                <TextIconButton text="Approve"
                                  icon={APPROVED_ICON}
                                  variant="outline-success"
                                  size="sm"
                                  onClick={() => approvePhoto(p)}
                                />
                                <TextIconButton text="Rejected"
                                  icon={REJECTED_ICON}
                                  variant="danger"
                                  size="sm"
                                  onClick={() => { }}
                                />
                              </>
                            );
                          case "pending":
                            return (
                              <>
                                <TextIconButton text="Approve"
                                  icon={APPROVED_ICON}
                                  variant="outline-success"
                                  size="sm"
                                  onClick={() => approvePhoto(p)}
                                />
                                <TextIconButton text="Reject"
                                  icon={REJECTED_ICON}
                                  variant="outline-danger"
                                  size="sm"
                                  onClick={() => launchRejectPhotoModal(p)}
                                />
                                <TextIconButton text="Hold for Review"
                                  icon={HOLD_FOR_REVIEW_ICON}
                                  variant="outline-warning"
                                  size="sm"
                                  onClick={() => togglePhotoHeldForReview(p)}
                                />
                              </>
                            );
                          case "heldForReview":
                            return (
                              <>
                                <TextIconButton text="Approve"
                                  icon={APPROVED_ICON}
                                  variant="outline-success"
                                  size="sm"
                                  onClick={() => approvePhoto(p)}
                                  disabled={true}
                                />
                                <TextIconButton text="Reject"
                                  icon={REJECTED_ICON}
                                  variant="outline-danger"
                                  size="sm"
                                  onClick={() => launchRejectPhotoModal(p)}
                                  disabled={true}
                                />
                                <TextIconButton text="Unhold"
                                  icon={HOLD_FOR_REVIEW_ICON}
                                  variant="warning"
                                  size="sm"
                                  onClick={() => togglePhotoHeldForReview(p)}
                                />
                              </>
                            );
                        }
                      })()
                    }

                    {p.attributes.screeningStatus === "pending" && (
                      <span className="badge border border-secondary text-secondary align-self-center ms-3">
                        Pending Review
                      </span>
                    )}

                    {p.attributes.screeningStatus === "heldForReview" && (
                      <span className="badge bg-warning align-self-center ms-3">
                        Held for Review
                      </span>
                    )}
                  </div>
                </td>
              </tr>
            ))}

            {photos.length === 0 && loading === false && (
              <tr>
                <td colSpan={6} className="py-5 text-center">
                  <p className="text-secondary fst-italic">
                    There are no more photos to moderate.
                  </p>
                  <Link to="/moderate">Back to Moderation Dashboard</Link>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <PaginationControl
          page={pageNumber}
          pageSize={pageSize}
          total={total}
          pageCount={pageCount}
          setPageNumber={setPageNumber}
        />
      </>
    );
  }

  return (
    <>
      {/* TODO this rejection form should really be made simpler */}
      <RejectionFormModal
        show={rejectedPhoto !== null}
        onHide={() => setRejectedPhoto(null)}
        contentType="Photo"
        content={rejectedPhoto as Photo}
        onReject={(content) => {
          publish("showToast", {
            id: `toast-${new Date().toISOString()}`,
            title: "Photo Rejected",
            message: `Photo with ID ${content?.id ?? "unknown"
              } will be hidden from public view.`,
          });

          setQuery((q) => ({ ...q }));
        }}
      />
      <Lightbox
        show={lightboxPhoto !== null}
        image={lightboxPhoto?.attributes.photo.data}
        alt={lightboxPhoto?.attributes.name ?? "lightbox photo"}
        onHide={() => {
          setLightboxPhoto(null);
        }}
      />
      <section className="container">
        <h2>Moderate Photos</h2>
        <p>
          <span className="fw-bold">Instructions: </span>
          Approve or reject photos that are pending on a moderator's review. Be
          on the look out for inappropriate content, unsafe conditions, improper
          PPE, and harassment of others.
        </p>

        <div className="d-flex gap-2 align-items-center">
          <AsyncTaskButton
            callback={exportCsv}
            isLoading={isLoading}
            statusMessage={statusMessage}
            buttonText="Export Photos as CSV" />
        </div>

        {body}
      </section>
    </>
  );
};
