import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_USER_LOGINS } from '../queries/getUserLogins';
import { UserLogin, UserLoginStat, IndexQuery } from '../types';

export function useUserLoginData(startDate: Date, endDate: Date) {
  const [transformedData, setTransformedData] = useState<UserLoginStat[]>([]);
  const { data, loading, error } = useQuery<IndexQuery<UserLogin>>(GET_USER_LOGINS, {
    variables: {
      startDate,
      endDate
    }
  });

  useEffect(() => {
    const datasets: UserLoginStat[] = [];

    if (data !== undefined) {
      for (
        let date = new Date(startDate);
        date.valueOf() <= endDate.valueOf();
        date.setUTCDate(date.getUTCDate() + 1)
      ) {
        const loginStat = {
          label: date.toLocaleString("en-US", {
            month: "short",
            day: "numeric"
          }),
          count: data.userLogins.data.filter(ul => {
            const createdAt = new Date(ul.attributes.createdAt);

            return createdAt.getFullYear() === date.getFullYear() &&
              createdAt.getMonth() === date.getMonth() &&
              createdAt.getDate() === date.getDate();
          }).length
        }

        datasets.push(loginStat);
      }

      setTransformedData(datasets);
    }
  }, [data, endDate, startDate]);

  return {
    data: transformedData,
    loading,
    error
  }
}
