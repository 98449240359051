import { useEffect, useRef, useState } from "react";
import { TopBar } from "../common/TopBar";
import { LayoutControls } from "../common/LayoutControls";
import { SubNavBar } from "../navigation/SubNavBar";
import { ScrollableElementContext } from "../../context/ScrollableElementContext";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { MY_ACTIVITY_NAV_ITEMS } from "../../constants/myActivityNavItems";
import { PageHelpModal } from "../common/PageHelpModal";
import { ONBOARDING_SLIDES } from "../../constants/onboardingSlides";
import { MobileHelpButton } from "../common/MobileHelpButton";
import { useAuthorization } from "../../hooks/useAuthorization";

export const MyActivityPage = () => {
  useAuthorization('authenticated', 'moderator');
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const handleShowHelp = () => setShowHelp(true);
  const handleHideHelp = () => setShowHelp(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/my-activity") {
      navigate("/my-activity/liked");
    }
  }, [location, navigate]);
  const scrollableRef = useRef(null);

  const headerContent: { [key: string]: string } = {
    "/my-activity/liked": "Photos You Liked",
    "/my-activity/commented": "Photos You Commented On",
  };

  return (
    <section className="page d-flex flex-column">
      <TopBar
        pageTitle="My Activity"
        showHelpButton
        onClickHelp={handleShowHelp}
      >
        <SubNavBar navItems={MY_ACTIVITY_NAV_ITEMS} />
        <LayoutControls />
      </TopBar>
      <MobileHelpButton onClick={handleShowHelp} />

      <div
        className="flex-grow-1 overflow-y-auto position-relative"
        ref={scrollableRef}
      >
        <ScrollableElementContext.Provider value={scrollableRef.current}>
          <div className="container-lg">
            <h2 className="fs-3 my-lg-3 my-2">
              {headerContent[location.pathname] || ""}
            </h2>
            <Outlet />
          </div>
        </ScrollableElementContext.Provider>
      </div>

      <PageHelpModal
        pageTitle="My Activity"
        show={showHelp}
        onHide={handleHideHelp}
        content={ONBOARDING_SLIDES[3]}
      />
    </section>
  );
};
