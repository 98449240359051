import { Link } from "react-router-dom"

export const UserNotConfirmed: React.FC = () => {
  return (
    <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
      <div className="rounded px-md-5 px-4 py-5 bg-body shadow col-lg-6">
        <h2 className="mb-3">Email not confirmed</h2>
        <p className="mb-3">You need to confirm your email before you can log into the contest.</p>
        <div className="d-flex gap-2">
          <Link className="btn btn-primary" to="/auth/resend-confirm-email">
            Re-send confirmation email
          </Link>
          <Link className="btn btn-primary" to="/auth/login">
            Back to login
          </Link>
        </div>
      </div>
    </div>
  );
}
