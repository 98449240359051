import { DocumentNode, gql } from "@apollo/client";

export const UPDATE_COMMENT_SCREENING_STATUS: DocumentNode = gql`
  mutation UpdateScreeningStatus(
    $id: ID!
    $screeningStatus: ENUM_COMMENT_SCREENINGSTATUS!
  ) {
    updateComment(id: $id, data: { screeningStatus: $screeningStatus }) {
      data {
        id
        attributes {
          screeningStatus
        }
      }
    }
  }
`;
