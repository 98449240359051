import React, { ChangeEventHandler, useEffect, useState } from "react";
import { SortFunction } from "../../types";
import { sortPhotosByDateUploaded } from "../../util/sortPhotosByDateUploaded";
import { sortPhotosByCategory } from "../../util/sortPhotosByCategory";
import { sortPhotosByScreeningStatus } from "../../util/sortPhotosByScreeningStatus";

interface GroupBySelectProps {
  sortFunction: SortFunction;
  setSortFunction: React.Dispatch<React.SetStateAction<SortFunction>>;
}

const OPTIONS = [
  {
    name: "Date Uploaded",
    value: sortPhotosByDateUploaded,
  },
  {
    name: "Category",
    value: sortPhotosByCategory,
  },
  {
    name: "Screening Status",
    value: sortPhotosByScreeningStatus,
  },
];

export const GroupBySelect: React.FC<GroupBySelectProps> = ({
  sortFunction,
  setSortFunction,
}) => {
  const [value, setValue] = useState<string>(
    OPTIONS.find((o) => o.value === sortFunction)?.name || "Select an Option"
  );

  useEffect(() => {
    const current = OPTIONS.find((o) => o.name === value);

    if (current === undefined) {
      throw new Error(
        `Invalid selection! Could not find an option with name matching ${value}`
      );
    }

    if (current.value !== sortFunction) {
      setSortFunction(() => current.value);
    }
  }, [value, setSortFunction, sortFunction]);

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="d-flex gap-2 align-items-center">
      <label htmlFor="group-by" className="text-nowrap fw-semibold">Group By</label>
      <select
        name="group-by"
        id="group-by"
        value={value}
        onChange={handleChange}
        className="form-select"
      >
        {OPTIONS.map((option) => (
          <option key={option.name} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};
