import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const GET_COMMENT_BY_ID: DocumentNode = gql`
  query GetCommentById($id: ID!) {
    comment(id: $id) {
      data {
        id
        attributes {
          body
          screeningStatus
          createdAt
          user {
            data {
              id
              attributes {
                firstName
                lastName
                email
              }
            }
          }
        }
      }
    }
  }
`;
