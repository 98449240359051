import { createContext } from "react";

const DEFAULT_CONTEXT = {
  currentSlide: 0,
  totalSlides: 0,
  onNext: () => {
    throw new Error("Missing SlideshowContext provider.");
  },
  onLast: () => {
    throw new Error("Missing SlideshowContext provider.");
  },
};

export const SlideshowContext = createContext<{
  currentSlide: number;
  totalSlides: number;
  onNext: Function;
  onLast: Function;
}>(DEFAULT_CONTEXT);
