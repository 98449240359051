import { gql } from "@apollo/client";

export const GET_PHOTOS_BY_USER = gql`
  query GetPhotosByUser($userId: ID!) {
    photos(
      filters: { user: { id: { eq: $userId } } }
      sort: "createdAt:desc"
      pagination: { start: 0, limit: 25 }
    ) {
      data {
        id
        attributes {
          name
          createdAt
          moderatedAt
          screeningStatus
          additionalData
          category {
            data {
              id
              attributes {
                displayName
                icon
                color
                name
              }
            }
          }
          photo {
            data {
              id
              attributes {
                url
                formats
              }
            }
          }
        }
      }
    }
  }
`;
