import { DocumentNode, gql } from "@apollo/client";

export const DELETE_LIKE: DocumentNode = gql`
# Delete a like given its ID
mutation DeleteLike($id: ID!) {
  deleteLike(id: $id) {
    data {
      id
      attributes {
        photo {
          data {
            id
          }
        }
      }
    }
  }
}
`;