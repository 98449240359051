import { gql } from "@apollo/client";

export const UPDATE_REJECTION_COMMUNICATION_STATUS = gql`
  mutation UpdateRejectionCommunicationStatus(
    $id: ID!
    $hasBeenCommunicated: Boolean!
  ) {
    updateRejection(
      id: $id
      data: { hasBeenCommunicated: $hasBeenCommunicated }
    ) {
      data {
        id
        attributes {
          hasBeenCommunicated
        }
      }
    }
  }
`;
