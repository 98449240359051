import React from "react";
import { Category } from "../../types";
import { CategoryIcon } from "./CategoryIcon";

interface CategoryRibbonProps {
  category: Category;
}

export const CategoryRibbon: React.FC<CategoryRibbonProps> = ({ category }) => {
  return (
    <div
      className="category-ribbon position-absolute"
      style={{ backgroundColor: category.attributes.color }}
    >
      <CategoryIcon category={category} size="2x" color="white" />
    </div>
  );
};
