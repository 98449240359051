import React, { useCallback } from "react";
import { Photo } from "../../types";
import { SquareFilledPhoto } from "../common/SquareFilledPhoto";
import { CategoryRibbon } from "../common/CategoryRibbon";
import { ScreeningStatusBadge } from "../common/ScreeningStatusBadge";
import { MyPhotoCardButton } from "./MyPhotoCardButton";
import { FULLSCREEN_ICON, TRASH_ICON } from "../../constants/icons";
import { publish } from "../../util/events";

interface MyPhotoCardProps {
  photo: Photo;
  onViewFull: (id: number) => void;
  deletePhoto: (id: number) => any;
}

export const MyPhotoCard: React.FC<MyPhotoCardProps> = ({
  photo,
  onViewFull,
  deletePhoto,
}) => {
  const {
    name,
    screeningStatus,
    category,
    photo: image,
    createdAt,
  } = photo.attributes;
  const { formats, url } = image.data.attributes;

  const deletePhotoWithNotification = useCallback(async () => {
    const response = await deletePhoto(photo.id);
    const photoId = response.data.deletePhoto.data.id;

    publish("showToast", {
      id: `toast-${new Date().toISOString()}`,
      title: "Delete Successful",
      message: `Successfully deleted photo ${photoId}.`,
    });

    return response;
  }, [deletePhoto, photo]);

  const onDelete = useCallback(() => {
    publish("showConfirmPrompt", {
      message: "Are you sure you want to delete this photo?",
      onConfirm: deletePhotoWithNotification,
    });
  }, [deletePhotoWithNotification]);

  return (
    <>
      <article className="my-photo-card p-1 position-relative">
        <div className="border border-secondary">
          <SquareFilledPhoto photoUrl={formats.small?.url || url} alt={name} />
        </div>
        <CategoryRibbon category={category.data} />
        <h3 className="fs-5 fw-normal m-0 lh-base">{name}</h3>
        <div className="d-flex justify-content-between">
          <ScreeningStatusBadge screeningStatus={screeningStatus} />
          <p className="m-0 text-secondary">
            {new Date(createdAt).toLocaleString()}
          </p>
        </div>
        <div className="d-flex gap-2 mt-1">
          <MyPhotoCardButton
            text="Delete"
            icon={TRASH_ICON}
            onClick={onDelete}
            className="my-photo-card-btn"
          ></MyPhotoCardButton>
          <MyPhotoCardButton
            text="View Full"
            icon={FULLSCREEN_ICON}
            onClick={() => onViewFull(photo.id)}
            className="my-photo-card-btn"
          ></MyPhotoCardButton>
        </div>
      </article>
    </>
  );
};
