import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const GET_VOTE_SETTINGS: DocumentNode = gql`
  query GetVoteSettings {
    voteSetting {
      data {
        attributes {
          maxVotes
          maxVotesPerCategory
          maxVotesPerPhoto
        }
      }
    }
  }
`;
