import { useQuery } from "@apollo/client";
import { GET_MODERATION_TASK_COUNTS } from "../queries/getModerationTaskCounts";
import { useEffect, useState } from "react";

export const useModerationTaskCounts = () => {
  const { data } = useQuery(GET_MODERATION_TASK_COUNTS);
  const [taskCounts, setTaskCounts] = useState({
    photos: 0,
    comments: 0,
    rejections: 0,
    users: 0,
  });

  useEffect(() => {
    if (data === undefined) return;

    setTaskCounts({
      photos: data.photos.meta.pagination.total,
      comments: data.comments.meta.pagination.total,
      rejections: data.rejections.meta.pagination.total,
      users: data.usersPermissionsUsers.meta.pagination.total
    });
  }, [data]);

  return taskCounts;
};
