import React from "react";
import { ModerationStatus } from "../../types";

interface ScreeningStatusBadgeProps {
  screeningStatus: ModerationStatus;
}

// For the user, pending and held for review will appear the same since it's an arbitrary classification
const COLORS = {
  pending: "#999999",
  approved: "#1CA425",
  rejected: "#DC3545",
  heldForReview: "#999999"
};

const DISPLAY_NAMES = {
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
  heldForReview: "Pending"
};

export const ScreeningStatusBadge: React.FC<ScreeningStatusBadgeProps> = ({
  screeningStatus,
}) => {
  return (
    <span className="d-inline-block px-1 text-white fw-semibold" style={{ backgroundColor: COLORS[screeningStatus] }}>
      {DISPLAY_NAMES[screeningStatus]}
    </span>
  );
};
