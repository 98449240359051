import { useCallback, useContext, useEffect } from "react";
import { SettingsContext } from "../context/SettingsContext";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const DEFAULT_CONFIG = {
  doRedirects: true,
};

// This hook is used to control what pages a user can access based on the current state of the photo contest.
// The hook is simply added to the root component of any page that should be restricted (that page's logic
// must be in this hook or else the page is automatically restricted), and it performs the appropriate redirect if necessary.
// The hook can be given a configuration object with the following properties:
// - doRedirects: should the user be redirected if the page is not allowed (default = true)
export const useRestrictedPageBasedOnContestStatus = (config?: {
  doRedirects: boolean;
}) => {
  const { doRedirects } = Object.assign(DEFAULT_CONFIG, config || {});

  const {
    attributes: {
      isSubmissionsEnabled,
      isVotingEnabled,
      enableSubmissionsDate,
      disableVotingDate,
    },
  } = useContext(SettingsContext);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const isContestRunning = useCallback(() => {
    const today = new Date();

    return (
      today >= new Date(enableSubmissionsDate) &&
      today < new Date(disableVotingDate)
    );
  }, [enableSubmissionsDate, disableVotingDate]);

  const isPageAllowed = useCallback(
    (pagePath: string) => {
      const pagePathIndex = pagePath.indexOf("/", 1);
      const rootPagePath = pagePath.slice(
        0,
        pagePathIndex > 0 ? pagePathIndex : pagePath.length
      );
      let output: boolean = false;

      switch (rootPagePath.toLowerCase()) {
        case "/": // gallery
          output = isContestRunning() === true && isVotingEnabled === false;
          break;
        case "/my-photos":
          output = isContestRunning() === true && isSubmissionsEnabled === true;
          break;
        case "/my-activity":
          output = isContestRunning() === true && isVotingEnabled === false;
          break;
        case "/vote":
          output = isContestRunning() === true && isVotingEnabled === true;
          break;
        case "/closed":
          output = isContestRunning() === false;
          break;
        case "/moderate":
        case "/prizes":
          output = true;
          break;
        case "/account":
          output = true;
          break;
        default:
          output = false;
          break;
      }

      return output;
    },
    [isContestRunning, isVotingEnabled, isSubmissionsEnabled]
  );

  const getRedirectPage = useCallback(() => {
    let output: string = "closed";

    if (isContestRunning() === false) {
      output = "/closed";
    } else if (isVotingEnabled === false) {
      output = "/";
    } else if (isVotingEnabled === true) {
      output = "/vote";
    }

    return output;
  }, [isContestRunning, isVotingEnabled]);

  // checks if page is allowed, redirects if necessary
  useEffect(() => {
    if (isPageAllowed(location.pathname) === false) {
      if (currentUser?.role.type !== "moderator" && doRedirects === true) {
        navigate(getRedirectPage());
      }
    }
  }, [
    isPageAllowed,
    getRedirectPage,
    navigate,
    doRedirects,
    location,
    currentUser,
  ]);

  return { isPageAllowed };
};
