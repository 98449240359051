import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { subscribe, unsubscribe } from "../../util/events";

export const confirmEventName = "showConfirmPrompt";

export const SingletonConfirmationModal = () => {
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [onConfirm, setOnConfirm] = useState<(() => Promise<any>) | null>(null);
  const [message, setMessage] = useState<string>("");

  const handleShowConfirmPrompt = (event: CustomEvent) => {
    const { message, onConfirm } = event.detail;

    setDisabled(true);
    setShow(true);
    setMessage(message);
    setOnConfirm(() => onConfirm);
    setDisabled(false);
  };

  const handleHide = () => {
    setOnConfirm(null);
    setShow(false);
    setDisabled(false);
    setMessage("");
  };

  const handleConfirm = async () => {
    setDisabled(true);
    if (onConfirm !== null) {
      await onConfirm();
    }
    handleHide();
  };

  useEffect(() => {
    subscribe(confirmEventName, handleShowConfirmPrompt);

    return () => {
      unsubscribe(confirmEventName, handleShowConfirmPrompt);
    };
  }, []);

  return (
    <Modal show={show} centered onHide={handleHide} backdrop="static">
      <Modal.Header className="bg-theme-primary text-white">
        <Modal.Title className="m-0">Confirm</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="fs-5 m-0">{message}</p>
      </Modal.Body>

      <Modal.Footer className="">
        <Button variant="secondary" onClick={handleHide} disabled={disabled}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleConfirm} disabled={disabled}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
