import { gql } from "@apollo/client";
import { NUMBER_OF_COMMENTS_TO_PULL_IN } from "../constants/constants";

export const GET_PHOTOS_BY_CATEGORY = gql`
  query GetPhotosByCategory($start: Int!, $limit: Int!, $category: String!) {
    photos(
      filters: {
        screeningStatus: { eq: "approved" },
        category: {
          name: { eq: $category }
        }
      }
      sort: "moderatedAt:desc"
      pagination: { start: $start, limit: $limit }
    ) {
      data {
        id
        attributes {
          name
          createdAt
          moderatedAt
          additionalData
          category {
            data {
              id
              attributes {
                displayName
                icon
                color
                name
              }
            }
          }
          comments(
            filters: { screeningStatus: { ne: "rejected" } }
            sort: "createdAt:desc"
            pagination: { start: 0, limit: ${NUMBER_OF_COMMENTS_TO_PULL_IN} }
          ) {
            data {
              id
              attributes {
                body
                screeningStatus
                createdAt
                user {
                  data {
                    id
                    attributes {
                      firstName
                      lastName
                      email
                    }
                  }
                }
              }
            }
          }
          likes {
            count
            currentUsersLike {
              data {
                id
              }
            }
          }
          photo {
            data {
              id
              attributes {
                url
                formats
              }
            }
          }
          user {
            data {
              id
              attributes {
                firstName
                lastName
                email
              }
            }
          }
          votes {
            id
            count
          }
        }
      }
    }
  }
`;
