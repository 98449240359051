import {
  ACCOUNT_ICON,
  IMAGES_ICON,
  MODERATE_ICON,
  MY_ACTIVITY_ICON,
  SUBMIT_ICON,
  VOTE_ICON,
} from "./icons";

export const NAV_ITEMS = [
  {
    id: 1,
    name: "Gallery",
    to: "/",
    icon: IMAGES_ICON,
    class: "",
    disabled: false,
  },
  {
    id: 2,
    name: "My Photos",
    to: "/my-photos",
    icon: SUBMIT_ICON,
    class: "",
    disabled: false,
  },
  {
    id: 3,
    name: "My Activity",
    to: "/my-activity",
    icon: MY_ACTIVITY_ICON,
    class: "",
    disabled: false,
  },
  // {
  //   id: 4,
  //   name: "Prizes",
  //   to: "/prizes",
  //   icon: PRIZES_ICON,
  //   class: "",
  //   disabled: false,
  // },
  {
    id: 5,
    name: "Vote",
    to: "/vote",
    icon: VOTE_ICON,
    class: "",
    disabled: false,
  },
  {
    id: 6,
    name: "My Account",
    to: "/account",
    icon: ACCOUNT_ICON,
    class: "nav-item-gap-below",
    disabled: false
  }
];

export const MODERATOR_NAV_ITEM = {
  id: 7,
  name: "Moderate",
  to: "/moderate",
  icon: MODERATE_ICON,
  class: "",
  disabled: false,
};
