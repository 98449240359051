import { PhotoForm } from "../types";

export const DEFAULT_PHOTO_FORM: PhotoForm = {
  name: null,
  image: null,
  category: null,
  projectName: null,
  projectNumber: null,
  clientName: null
};
