import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const GET_COMMENTS_BY_PHOTO: DocumentNode = gql`
  query GetCommentsByPhoto($photoId: ID!, $start: Int!, $limit: Int!) {
    comments(
      filters: {
        photo: { id: { eq: $photoId } }
        and: { screeningStatus: { ne: "rejected" } }
      }
      sort: "createdAt:desc"
      pagination: { start: $start, limit: $limit }
    ) {
      data {
        id
        attributes {
          body
          screeningStatus
          createdAt
          user {
            data {
              id
              attributes {
                firstName
                lastName
                email
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
