import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";

export const Logout: React.FC = () => {
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    (async function() {
      logout();

      window.location.replace('/auth/login');
    })();
  }, [logout]);

  return (
    <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
      <div className="rounded px-md-5 px-4 py-5 bg-body shadow col-lg-6">
        <h2 className="mb-3">Logging out...</h2>
      </div>
    </div>
  )
}
