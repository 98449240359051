import { Outlet } from "react-router-dom";
import { TopBar } from "../common/TopBar";
import { SubNavBar } from "../navigation/SubNavBar";
import { MODERATE_NAV_ITEMS } from "../../constants/moderateNavItems";
import { useAuthorization } from "../../hooks/useAuthorization";

export const ModerationPage = () => {
  useAuthorization("moderator");

  return (
    <>
      <section className="page d-flex flex-column">
        <TopBar pageTitle="Moderate">
          <SubNavBar navItems={MODERATE_NAV_ITEMS} />
        </TopBar>

        <div className="flex-grow-1 overflow-y-auto">
          <Outlet />
        </div>
      </section>
    </>
  );
};
