import { ThemeConfig } from "../types";
import { useState, useEffect } from 'react';

export function useTheme(): {
  themeConfig: ThemeConfig | null,
  loading: boolean,
  error: string | null,
  setThemeName: React.Dispatch<React.SetStateAction<string>>,
  themeOptions: string[]
} {
  const [themeName, setThemeName] = useState<string>("default");
  const [themeConfig, setThemeConfig] = useState<ThemeConfig | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // load theme from local storage
  useEffect(() => {
    const storedThemeName = localStorage.getItem("photoContestTheme");

    if (storedThemeName) {
      setThemeName(storedThemeName);
    }
  }, [setThemeName]);

  // get config file
  useEffect(() => {
    setLoading(true);

    fetch(`${process.env.PUBLIC_URL}/themes/${themeName}/config.json`)
      .then(res => res.json())
      .then(data => {
        setThemeConfig(data);
        // save selected theme to local storage
        localStorage.setItem("photoContestTheme", themeName);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [themeName, setThemeConfig]);

  return {
    themeConfig,
    loading,
    error,
    setThemeName,
    themeOptions: ["mccormick-taylor", "septa"]
  };
}
