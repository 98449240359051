import { useRef, useState } from "react";
import { usePhotos } from "../../hooks/usePhotos";
import { GET_PHOTOS } from "../../queries/getPhotos";
import { TopBar } from "../common/TopBar";
import { DropdownCategoryFilter } from "./DropdownCategoryFilter";
import { LayoutControls } from "../common/LayoutControls";
import { ScrollableElementContext } from "../../context/ScrollableElementContext";
import { useSearchParams } from "react-router-dom";
import { ColumnPhotoDisplay } from "../common/ColumnPhotoDisplay";
import { GridPhotoDisplay } from "../common/GridPhotoDisplay";
import { PhotoDisplayContainer } from "../common/PhotoDisplayContainer";
import { Photo } from "../../types";
import { PageHelpModal } from "../common/PageHelpModal";
import { ONBOARDING_SLIDES } from "../../constants/onboardingSlides";
import { MobileHelpButton } from "../common/MobileHelpButton";
import { GET_PHOTOS_BY_CATEGORY } from "../../queries/getPhotosByCategory";
import { useAuthorization } from "../../hooks/useAuthorization";

export const GalleryPage = () => {
  useAuthorization('authenticated', 'moderator');
  const [currentCategory, setCurrentCategory] = useState<string>("all");
  const [showHelp, setShowHelp] = useState<boolean>(false);

  // pass in the photo category as a query argument if it has been selected
  const usePhotosVariables =
    currentCategory !== "all" ? { category: currentCategory } : {};
  const query = currentCategory !== "all" ? GET_PHOTOS_BY_CATEGORY : GET_PHOTOS;
  const { photos, loading, getNextPage } = usePhotos("photos", query, {
    variables: usePhotosVariables,
  });
  const scrollableRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const viewMode = searchParams.get("view");

  const handleClickGridPhoto = (photo: Photo) => {
    setSearchParams({
      view: "feed",
      photo: photo.id.toString(),
    });
  };

  return (
    <section className="page d-flex flex-column">
      <TopBar pageTitle="Gallery" showHelpButton onClickHelp={() => setShowHelp(true)}>
        <DropdownCategoryFilter
          selectedOption={currentCategory}
          setSelectedOption={setCurrentCategory}
        />
        <LayoutControls />
      </TopBar>
      <MobileHelpButton onClick={() => setShowHelp(true)} />

      <div className="flex-grow-1 overflow-y-auto" ref={scrollableRef}>
        <ScrollableElementContext.Provider value={scrollableRef.current}>
          <div className="container-lg h-100">
            <PhotoDisplayContainer photos={photos} loading={loading}>
              {viewMode === "feed" ? (
                <ColumnPhotoDisplay
                  photos={photos}
                  onScrollBottom={getNextPage}
                />
              ) : (
                <GridPhotoDisplay
                  photos={photos}
                  onScrollBottom={getNextPage}
                  onClickPhoto={handleClickGridPhoto}
                />
              )}
            </PhotoDisplayContainer>
          </div>
        </ScrollableElementContext.Provider>
      </div>

      <PageHelpModal
        pageTitle="Gallery"
        show={showHelp}
        onHide={() => setShowHelp(false)}
        content={ONBOARDING_SLIDES[1]}
      />
    </section>
  );
};
