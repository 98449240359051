import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import { Photo, SingleQuery } from "../../types";
import { ColumnPhoto } from "../photo/ColumnPhoto";
import { useQuery } from "@apollo/client";
import { GET_PHOTO_BY_ID } from "../../queries/getPhotoById";

interface PhotoDetailModalProps {
  show: boolean;
  onHide: () => void;
  photoId: number | null;
}

export const PhotoDetailModal: React.FC<PhotoDetailModalProps> = ({
  show,
  onHide,
  photoId,
}) => {
  const { data, loading } = useQuery<SingleQuery<Photo>>(GET_PHOTO_BY_ID, {
    variables: { id: photoId },
  });

  return (
    <Modal show={show} onHide={onHide} centered scrollable>
      <Modal.Header
        closeButton
        className="bg-theme-primary"
        closeVariant="white"
      ></Modal.Header>
      <Modal.Body>
        {loading && <p>Loading...</p>}
        {data && !loading && <ColumnPhoto photo={data.photo.data} readonly />}
      </Modal.Body>
    </Modal>
  );
};
