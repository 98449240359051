import { useCallback, useState, useContext } from "react";
import { PhotoForm, PhotoUpload } from "../types";
import { DEFAULT_PHOTO_FORM } from "../constants/defaultPhotoForm";
import { useMedia } from "./useMedia";
import { CategoriesContext } from "../context/CategoriesContext";
import { useMyPhotos } from "./useMyPhotos";
import { SettingsContext } from "../context/SettingsContext";

export const usePhotoForm = () => {
  const [formData, setFormData] = useState<PhotoForm>(DEFAULT_PHOTO_FORM);
  const [loading, setLoading] = useState<boolean>(false);
  const categories = useContext(CategoriesContext);
  const {
    attributes: { maxPhotoNameLength },
  } = useContext(SettingsContext);

  const { createMedia } = useMedia();
  const { createPhoto } = useMyPhotos();

  /**
   * Check if a given field is valid.
   */
  const isValidField = useCallback(
    (field: keyof PhotoForm): boolean => {
      const validations = {
        image: (photo: PhotoForm): boolean => !!photo.image,
        name: (photo: PhotoForm): boolean =>
          !!photo.name && photo.name.length <= maxPhotoNameLength,
        category: (photo: PhotoForm): boolean =>
          !!photo.category &&
          categories.map((c) => c.attributes.name).includes(photo.category),
        clientName: (photo: PhotoForm): boolean => (
          photo.category !== "project" ||
          (!!photo.clientName && photo.clientName.length > 0)
        ),
        projectName: (photo: PhotoForm): boolean => (
          photo.category !== "project" ||
          (!!photo.projectName && photo.projectName.length > 0)
        ),
        projectNumber: (photo: PhotoForm): boolean => (
          photo.category !== "project" ||
          (!!photo.projectNumber && photo.projectNumber.length > 0)
        ),
      };

      return validations[field](formData);
    },
    [categories, formData, maxPhotoNameLength]
  );

  /**
   * Check if the form is valid as a whole.
   */
  const isValid = useCallback((): boolean => {
    let isValid = true;

    const fields = Object.keys(formData);
    for (let field of fields) {
      if (!isValidField(field as keyof PhotoForm)) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }, [formData, isValidField]);

  const submit = useCallback(async () => {
    setLoading(true);
    if (!isValid()) {
      setLoading(false);
      throw new Error("Submit was triggered while formData was invalid.");
    }

    const categoryId = categories.find(
      (c) => c.attributes.name === formData.category
    )?.id;
    if (categoryId === undefined) {
      setLoading(false);
      throw new Error(`Category ${formData.category} could not be found.`);
    }

    const { id: imageId } = await createMedia(formData.image as File);

    const newPhoto: PhotoUpload = {
      name: formData.name!,
    };

    // add in project metadata if applicable
    if (formData.category === "project" &&
      formData.projectName && formData.projectNumber && formData.clientName) {
      newPhoto.additionalData = {
        projectName: formData.projectName,
        projectNumber: formData.projectNumber,
        clientName: formData.clientName
      };
    }

    const data = await createPhoto(newPhoto, imageId, categoryId);

    setLoading(false);

    return data;
  }, [isValid, categories, formData, createMedia, createPhoto]);

  /**
   * Resets form data to default values.
   */
  const reset = useCallback(() => {
    setFormData(DEFAULT_PHOTO_FORM);
  }, []);

  /**
   * Set a field of the form data.
   */
  const setField = useCallback(<T>(field: string, value: T) => {
    setFormData((formData) => ({ ...formData, [field]: value }));
  }, []);

  return {
    formData,
    submit,
    reset,
    loading,
    isValidField,
    isValid,
    setField
  };
};
