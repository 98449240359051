import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const GET_ME: DocumentNode = gql`
  query GetMe {
    me {
      id
      username
      email
      voteBalance
      hasBeenOnboarded
      firstName
      lastName
      confirmed
      approved
      provider
      role {
        id
        type
      }
    }
  }
`;
