import React from "react";
import { CategoriesContext } from "../../context/CategoriesContext";
import { SettingsContext } from "../../context/SettingsContext";
import { useQuery } from "@apollo/client";
import { GET_CATEGORIES } from "../../queries/getCategories";
import { LoadingScreen } from "../authentication/LoadingScreen";
import { SingleQuery, Settings } from "../../types";
import { GET_SETTINGS } from "../../queries/getSettings";

export const GlobalContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { data: settings, loading: settingsLoading, error: settingsError } = useQuery<SingleQuery<Settings>>(GET_SETTINGS);
  const { data, loading: categoriesLoading, error: categoriesError } = useQuery(GET_CATEGORIES);

  if (categoriesLoading || settingsLoading) {
    return <LoadingScreen />;
  }

  if (settingsError || categoriesError || settings === undefined) {
    return (
      <div className="alert alert-danger m-4 p-3">
        {
          settingsError && (
            <p className="fs-5 mb-3">Error Loading App Settings: {settingsError.message}</p>
          )
        }
        {
          categoriesError && (
            <p className="fs-5 mb-0">Error Loading Categories Error: {categoriesError.message}</p>
          )
        }
      </div>
    );
  }

  return (
    <CategoriesContext.Provider value={data?.categories.data || []}>
      <SettingsContext.Provider value={settings?.setting.data}>
        {children}
      </SettingsContext.Provider>
    </CategoriesContext.Provider>
  );
};
