import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';

/**
 * Displays an Alert with a message about any error that has been caught and prints the full error to the console for more info.
 */
export const ErrorPage: React.FC = () => {
  const error = useRouteError();
  let errorMessage: string;

  // send more details to the console
  console.warn(error);

  // set error message based on the status of the error
  if (isRouteErrorResponse(error)) {
    errorMessage = error.data;
  } else {
    errorMessage = "An unknown error has been thrown. Check the console for more details.";
  }

  return (
    <div>
      <Alert variant="warning">
        <Alert.Heading>Oops, an error has occured!</Alert.Heading>
        <p>
          { errorMessage }
        </p>
      </Alert>
    </div>
  )
}