import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export const MY_ACTIVITY_NAV_ITEMS = [
  {
    id: 1,
    name: "Liked Photos",
    icon: icon({ name: "thumbs-up", style: "solid" }),
    to: "/my-activity/liked",
  },
  {
    id: 2,
    name: "Commented Photos",
    icon: icon({ name: "comment", style: "solid" }),
    to: "/my-activity/commented",
  },
];

export type MyActivityTab = "Liked Photos" | "Commented Photos";
