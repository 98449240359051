import { useCallback } from 'react';
import { QueryOptions, Sort } from '../../types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { SORT_ICON, SORT_UP_ICON, SORT_DOWN_ICON } from '../../constants/icons';

type SortWidgetProps = {
  query?: QueryOptions,
  setQuery: React.Dispatch<React.SetStateAction<QueryOptions>>,
  field: string | string[]
};

function compareArrContents(a: any, b: any) {
  if (Array.isArray(a) && Array.isArray(b)) {
    let isEqual = true;

    for (const item of a) {
      if (b.includes(item) === false) {
        isEqual = false;
        break;
      }
    }

    return isEqual;
  } else {
    return false;
  }
}

export function SortWidget({ query, setQuery, field }: SortWidgetProps) {
  let icon: IconDefinition;
  let sortValue: Sort | undefined = undefined;

  if (!query?.sort?.field || (
    query?.sort?.field !== field &&
    compareArrContents(query?.sort?.field, field) === false
  )) {
    icon = SORT_ICON;
    sortValue = {
      field,
      direction: 'asc'
    };
  } else if (query?.sort.direction === 'asc') {
    icon = SORT_DOWN_ICON;
    sortValue = {
      field,
      direction: 'desc'
    };
  } else {
    icon = SORT_UP_ICON;
  }

  const updateSort = useCallback(() => {
    setQuery(q => {
      q.sort = sortValue;

      return { ...q };
    });
  }, [setQuery, sortValue]);

  return (
    <button onClick={updateSort} className="border-0 bg-transparent">
      <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
    </button>
  );
}
