import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const GET_SETTINGS: DocumentNode = gql`
  query GetSettings {
    setting {
      data {
        attributes {
          isVotingEnabled
          isSubmissionsEnabled
          maxPhotosPerCategory
          maxPhotoNameLength
          maxCommentBodyLength
          enableSubmissionsDate
          enableVotingDate
          disableVotingDate
        }
      }
    }
  }
`;
