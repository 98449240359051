import Button from "react-bootstrap/esm/Button";
import { LIKE_ICON, UNLIKE_ICON } from "../../constants/icons";
import { useLikes } from "../../hooks/useLikes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Like } from "../../types";

interface LikeButtonProps {
  photoId: number;
  likes: {
    count: number;
    currentUsersLike: { data: Like | null };
  };
  disabled?: boolean;
}

export const LikeButton: React.FC<LikeButtonProps> = ({
  photoId,
  likes,
  disabled = false,
}) => {
  const { count, isLiked, onClick } = useLikes(photoId, likes);

  const color = isLiked ? "primary" : "secondary";

  return (
    <Button
      onClick={onClick}
      variant="transparent"
      className={`text-${color} border-0`}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={isLiked ? LIKE_ICON : UNLIKE_ICON} size="xl" />
      <span className="fs-6 ms-2">{count} Likes</span>
    </Button>
  );
};
