import React, { useEffect, useState } from "react";
import Toast from "react-bootstrap/esm/Toast";
import { subscribe, unsubscribe } from "../../util/events";
import ToastContainer from "react-bootstrap/esm/ToastContainer";

/**
 * Catches any "showToast" events and displays a toast notification popup for them
 */
export const ToastNotifier = () => {
  const [toastList, setToastList] = useState<
    { id: number; title: string; message: string }[]
  >([]);

  const popToast = (data: any) => {
    setToastList((toastList) => [...toastList, data.detail]);
  };

  // generates a callback that will delete a toast by ID
  const hideToast = (id: number | string) => {
    return () => {
      setToastList((toastList) => toastList.filter((toast) => toast.id !== id));
    };
  };

  useEffect(() => {
    subscribe("showToast", popToast);

    return () => {
      unsubscribe("showToast", popToast);
    };
  }, []);

  return (
    <ToastContainer className="toast-container">
      {toastList.map((toast) => (
        <Toast
          key={toast.id}
          show
          onClose={hideToast(toast.id)}
          delay={5000}
          autohide
          animation={true}
        >
          <Toast.Header>
            <p className="m-0 me-auto fs-5">{toast.title}</p>
          </Toast.Header>
          <Toast.Body>
            <p>{toast.message}</p>
          </Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
};
