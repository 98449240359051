import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";

interface NavItemProps {
  navItem: {
    name: string;
    to: string;
    icon: IconProp;
    disabled: boolean;
  };
  className?: string;
}

export const NavItem: React.FC<NavItemProps> = ({
  navItem,
  className = "",
}) => {
  const { name, to, icon } = navItem;

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `main-nav-item text-white position-relative text-decoration-none ${isActive && "active"
        } ${navItem.disabled && "disabled-nav-link"} ${className}`
      }
    >
      <FontAwesomeIcon icon={icon} size="2x" />
      <p className="m-0 mt-1 fs-6">{name}</p>
    </NavLink>
  );
};
