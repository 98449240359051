import React from "react";

interface CountDisplayProps {
  label: string;
  count: string;
}

export const CountDisplay: React.FC<CountDisplayProps> = ({
  label,
  count,
}) => {
  return <div className="d-flex gap-2 align-items-baseline">
    <p className="m-0 fs-5">{label}</p>
    <span className="count-bubble">{count}</span>
  </div>;
};
