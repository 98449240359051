import { useState } from "react";
import { Outlet } from "react-router-dom";
import { TopBar } from "../common/TopBar";
import { PageHelpModal } from "../common/PageHelpModal";
import { MobileHelpButton } from "../common/MobileHelpButton";
import { votingHelpContent } from "../../constants/votingHelpContent";
import { useQuery } from "@apollo/client";
import { SingleQuery, VoteSettings } from "../../types";
import { VoteSettingsContext } from "../../context/VoteSettingsContext";
import { GET_VOTE_SETTINGS } from "../../queries/getVoteSettings";
import { useAuthorization } from "../../hooks/useAuthorization";
import { PageHeading } from "../common/PageHeading";

export const VotingPage = () => {
  useAuthorization('authenticated', 'moderator');
  const { data: voteSettingData, loading } = useQuery<SingleQuery<VoteSettings>>(GET_VOTE_SETTINGS);

  const [showHelp, setShowHelp] = useState<boolean>(false);
  const handleShowHelp = () => setShowHelp(true);
  const handleHideHelp = () => setShowHelp(false);

  return (
    <>
      <section className="page d-flex flex-column">
        <TopBar pageTitle="Vote" showHelpButton onClickHelp={handleShowHelp}>
          <PageHeading className="d-lg-none d-flex">
            <h2 className="m-0 fw-bold fs-3">Vote</h2>
          </PageHeading>
        </TopBar>
        <MobileHelpButton onClick={handleShowHelp} />

        <VoteSettingsContext.Provider value={{ voteSettings: voteSettingData?.voteSetting.data, loading }}>
          <Outlet />
        </VoteSettingsContext.Provider>
      </section>

      <PageHelpModal
        pageTitle="Vote"
        show={showHelp}
        onHide={handleHideHelp}
        content={votingHelpContent}
      />
    </>
  );
};
