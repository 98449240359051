import {
  ApolloLink,
  HttpLink,
  ApolloClient,
  InMemoryCache,
  concat,
} from "@apollo/client";

function getClient(backendUrl: string) {
  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: sessionStorage.getItem("token")
          ? `Bearer ${sessionStorage.getItem("token")}`
          : undefined,
      },
    }));

    return forward(operation);
  });

  const httpLink = new HttpLink({
    uri: `${backendUrl}/graphql`,
  });

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            photos: {
              keyArgs: ["filters", "sort"],
              merge(existing = { data: [] }, incoming) {
                const idSet = new Set<string>();
                const newData = [...existing.data, ...incoming.data].filter(
                  (item) => {
                    if (idSet.has(item["__ref"])) {
                      return false;
                    } else {
                      idSet.add(item["__ref"]);
                      return true;
                    }
                  }
                );
                return { data: newData };
              },
            },
            randomPhotos: {
              keyArgs: ["seed"],
              merge(existing = { data: [] }, incoming) {
                const idSet = new Set<string>();
                const newData = [...existing.data, ...incoming.data].filter(
                  (item) => {
                    if (idSet.has(item["__ref"])) {
                      return false;
                    } else {
                      idSet.add(item["__ref"]);
                      return true;
                    }
                  }
                );
                return { data: newData };
              },
            },
          },
        },
        CategoryEntity: {
          fields: {
            attributes: {
              merge(existing = {}, incoming) {
                return { ...existing, ...incoming };
              },
            },
          },
        },
        PhotoEntity: {
          fields: {
            attributes: {
              merge(existing = {}, incoming) {
                return { ...existing, ...incoming };
              },
            },
          },
        },
        CommentEntity: {
          fields: {
            attributes: {
              merge(existing = {}, incoming) {
                return { ...existing, ...incoming };
              },
            },
          },
        },
        VoteEntity: {
          fields: {
            attributes: {
              merge(existing = {}, incoming) {
                return { ...existing, ...incoming };
              },
            },
          },
        },
        UsersPermissionsUserEntity: {
          fields: {
            attributes: {
              merge(existing = {}, incoming) {
                return { ...existing, ...incoming };
              },
            },
          },
        },
        RejectionEntity: {
          fields: {
            attributes: {
              merge(existing = {}, incoming) {
                return { ...existing, ...incoming };
              },
            },
          },
        },
      },
    }),
    link: concat(authMiddleware, httpLink),
    // name: "MTPhotoContest", blocked by CORS?
    // version: "1.0", blocked by CORS?
  });
}

export default getClient;
