import { gql } from '@apollo/client';

export const GET_VOTES_BY_USER = gql`
  query GetVotesByUser($userId: ID!) {
    votes(
      filters: {
        user: {
          id: { eq: $userId }
        }
      }
    ) {
      data {
        id
        attributes {
          voteCount
          user {
            data {
              id
            }
          }
          photo {
            data {
              id
              attributes {
                category {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
