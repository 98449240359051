import React, { FormEventHandler, useCallback, useState } from "react";
import Form from "react-bootstrap/esm/Form";
import { useReject } from "../../hooks/useReject";
import { Photo, Comment } from "../../types";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/esm/Button";
import { COMMON_REJECTION_REASONS } from "../../constants/commonRejectionReasons";

interface RejectionFormModalProps {
  show: boolean;
  onHide: () => void;
  contentType: "Photo" | "Comment";
  content: Photo | Comment;
  onReject?: (content?: Photo | Comment) => void;
}

export const RejectionFormModal: React.FC<RejectionFormModalProps> = ({
  show,
  onHide,
  contentType,
  content,
  onReject = () => { },
}) => {
  const { reject } = useReject();
  const [reasonSelect, setReasonSelect] = useState<string>("");
  const [reasonText, setReasonText] = useState<string>("");

  const handleSubmit: FormEventHandler = useCallback(
    async (e) => {
      e.preventDefault();

      const reason =
        reasonSelect === "other" && reasonText.length > 0
          ? reasonText
          : reasonSelect;
      await reject(contentType, content, reason);

      onHide();
      onReject(content);
      setReasonSelect("");
      setReasonText("");
    },
    [reject, reasonSelect, reasonText, content, contentType, onHide, onReject]
  );

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header
        closeButton
        closeVariant="white"
        className="bg-theme-primary"
      >
        <Modal.Title className="m-0 text-white">
          Reject {contentType}?
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Provide a reason for your rejection:</Form.Label>
            <Form.Select
              value={reasonSelect}
              onChange={(e) => setReasonSelect(e.target.value)}
            >
              <option value="">Choose a Reason</option>
              {COMMON_REJECTION_REASONS.filter(
                (cr) => cr.contentType === contentType
              ).map((cr) => (
                <option key={cr.id} value={cr.value}>
                  {cr.value}
                </option>
              ))}
              <option value="other">Other Reason</option>
            </Form.Select>
          </Form.Group>
          {reasonSelect === "other" && (
            <Form.Group>
              <Form.Label>Other Reason:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={reasonText}
                onChange={(e) => setReasonText(e.target.value)}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button type="submit" variant="danger">
            Submit Rejection
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
