import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";
import { NUMBER_OF_COMMENTS_TO_PULL_IN } from "../constants/constants";

export const GET_PHOTO_BY_ID: DocumentNode = gql`
  query GetPhotoById($id: ID) {
    photo(id: $id) {
      data {
        id
        attributes {
          name
          createdAt
          additionalData
          category {
            data {
              id
              attributes {
                displayName
                icon
                color
                name
              }
            }
          }
          comments(
            filters: { screeningStatus: { ne: "rejected" } }
            sort: "createdAt:desc"
            pagination: { start: 0, limit: ${NUMBER_OF_COMMENTS_TO_PULL_IN} }
          ) {
            data {
              id
              attributes {
                body
                screeningStatus
                createdAt
                user {
                  data {
                    id
                    attributes {
                      firstName
                      lastName
                      email
                    }
                  }
                }
              }
            }
          }
          likes {
            count
            currentUsersLike {
              data {
                id
              }
            }
          }
          photo {
            data {
              id
              attributes {
                url
                formats
              }
            }
          }
          user {
            data {
              id
              attributes {
                firstName
                lastName
                email
              }
            }
          }
          votes {
            id
            count
          }
        }
      }
    }
  }
`;
