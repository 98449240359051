import { useContext, useState } from "react";
import { TopBar } from "../common/TopBar";
import { useMyPhotos } from "../../hooks/useMyPhotos";
import { Loader } from "../common/Loader";
import { MyPhotoCard } from "./MyPhotoCard";
import { TextIconButton } from "../common/TextIconButton";
import { PLUS_ICON } from "../../constants/icons";
import { PhotoUploadModal } from "./PhotoUploadModal";
import { SortFunction } from "../../types";
import { GroupBySelect } from "./GroupBySelect";
import { sortPhotosByDateUploaded } from "../../util/sortPhotosByDateUploaded";
import { countPhotosByCategory } from "../../util/countPhotosByCategory";
import { CategoriesContext } from "../../context/CategoriesContext";
import { CountDisplay } from "./CountDisplay";
import { SettingsContext } from "../../context/SettingsContext";
import { PhotoDetailModal } from "./PhotoDetailModal";
import { CategoryIcon } from "../common/CategoryIcon";
import { PageHelpModal } from "../common/PageHelpModal";
import { ONBOARDING_SLIDES } from "../../constants/onboardingSlides";
import { MobileHelpButton } from "../common/MobileHelpButton";
import { useAuthorization } from "../../hooks/useAuthorization";

export const MyPhotosPage = () => {
  useAuthorization('authenticated', 'moderator');

  const { photos, loading, deletePhoto } = useMyPhotos();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [sortFunction, setSortFunction] = useState<SortFunction>(
    () => sortPhotosByDateUploaded
  );
  const [selectedPhotoId, setSelectedPhotoId] = useState<number | null>(null);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const categories = useContext(CategoriesContext);
  const {
    attributes: { maxPhotosPerCategory, isSubmissionsEnabled },
  } = useContext(SettingsContext);
  const categoryCounts = countPhotosByCategory(photos);

  const [showHelp, setShowHelp] = useState<boolean>(false);
  const handleShowHelp = () => setShowHelp(true);
  const handleHideHelp = () => setShowHelp(false);

  const handleViewFull = (id: number) => {
    setSelectedPhotoId(id);
    setShowDetailModal(true);
  };

  return (
    <section className="page d-flex flex-column">
      <TopBar pageTitle="My Photos" showHelpButton onClickHelp={handleShowHelp}>
        <GroupBySelect
          sortFunction={sortFunction}
          setSortFunction={setSortFunction}
        />
      </TopBar>
      <MobileHelpButton onClick={handleShowHelp} />

      <div className="flex-grow-1 h-full position-relative min-height-0 overflow-y-auto">
        {loading && (
          <div className="position-absolute top-0 left-0 w-100 h-100 z-3">
            <div className="position-absolute w-100 h-100 dimmer"></div>
            <Loader message="Loading My Photos..." />
          </div>
        )}
        <PhotoUploadModal
          show={showForm}
          setShow={setShowForm}
          categoryCounts={categoryCounts}
        />
        {selectedPhotoId && (
          <PhotoDetailModal
            show={showDetailModal}
            onHide={() => setShowDetailModal(false)}
            photoId={selectedPhotoId}
          />
        )}

        <section className="px-4 py-3">
          <h3 className="fs-5">Uploads Remaining</h3>
          <ul className="list-unstyled d-flex gap-4 flex-wrap m-0">
            {categories.map((c) => (
              <li key={c.id} className="d-flex gap-1 align-items-center">
                <CategoryIcon category={c} size="xl" />
                <CountDisplay
                  label={c.attributes.displayName}
                  count={`${maxPhotosPerCategory - (categoryCounts[c.id] || 0)
                    } / ${maxPhotosPerCategory}`}
                />
              </li>
            ))}
            <li>
              <CountDisplay
                label="Total"
                count={`${maxPhotosPerCategory * categories.length - photos.length
                  } / ${maxPhotosPerCategory * categories.length}`}
              />
            </li>
          </ul>
        </section>

        <hr className="px-5" />

        <ul className="list-unstyled d-flex flex-wrap justify-content-lg-start justify-content-center">
          <li className="m-4">
            <div className="my-photo-card p-1">
              <div className="position-relative aspect-ratio-square border border-secondary">
                <div className="position-absolute top-50 start-50 translate-middle">
                  <TextIconButton
                    disabled={isSubmissionsEnabled === false}
                    text="Add Photo"
                    icon={PLUS_ICON}
                    onClick={() => setShowForm(true)}
                  />
                </div>
              </div>
              <p className="fs-5 m-0 lh-base">
                {isSubmissionsEnabled ? "New Photo" : "Submissions disabled"}
              </p>
            </div>
          </li>
          {sortFunction([...photos]).map((photo) => (
            <li key={photo.id} className="m-4">
              <MyPhotoCard
                photo={photo}
                onViewFull={handleViewFull}
                deletePhoto={deletePhoto}
              />
            </li>
          ))}
        </ul>
      </div>

      <PageHelpModal
        pageTitle="My Photos"
        show={showHelp}
        onHide={handleHideHelp}
        content={ONBOARDING_SLIDES[2]}
      />
    </section>
  );
};
