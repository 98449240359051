/**
 * Subscribe to a new custom event.   
 * @param eventName name of the custom event
 * @param handler function to execute when the event is triggered, receives event as an argument
 */
function subscribe(eventName: string, handler: (data?: any) => void) {
  document.addEventListener(eventName, handler);
}

/**
 * Unsubscribe from a custom event.
 * @param eventName name of the custom event
 * @param handler function to execute when the event is triggered, must match signature of function in subscribe function
 */
function unsubscribe(eventName: string, handler: (data?: any) => void) {
  document.removeEventListener(eventName, handler);
}

/**
 * Dispatch a custom event with a data payload.
 * @param eventName name of the custom event to trigger
 * @param data data to pass into the custom event
 */
function publish(eventName: string, data?: any) {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

export { subscribe, unsubscribe, publish };