import { gql } from '@apollo/client';

export const GET_USER_BY_ID = gql`
query GetUserById($userId: ID!) {
  usersPermissionsUser(id: $userId) {
    data {
      id
      attributes {
        firstName
        lastName
        email
      }
    }
  }
}
`;
