import { gql } from "@apollo/client";

export const CHANGE_PASSWORD = gql`
mutation ChangePassword(
  $currentPassword: String!,
  $password: String!,
  $passwordConfirmation: String!
) {
	changePassword(
    currentPassword: $currentPassword,
    password: $password,
    passwordConfirmation: $passwordConfirmation
  ) {
		jwt
  }
}
`;
