import { gql } from '@apollo/client';

export const REGISTER = gql`
mutation Register($email: String!, $password: String!, $firstName: String!, $lastName: String!, $approved: ENUM_USERSPERMISSIONSUSER_APPROVED!) {
  register(input: {
    username: $email,
    email: $email,
    password: $password,
    firstName: $firstName,
    lastName: $lastName,
    approved: $approved
  }) {
    jwt
  }
}
`;
