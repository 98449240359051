import { MODERATOR_NAV_ITEM, NAV_ITEMS } from "../../constants/mainNavItems";
import { NavItem } from "./NavItem";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CLOSE_ICON, LOGOUT_ICON, MENU_ICON } from "../../constants/icons";
import { useRestrictedPageBasedOnContestStatus } from "../../hooks/useRestrictedPageBasedOnContestStatus";

export const MainNavbar = () => {
  const { isPageAllowed } = useRestrictedPageBasedOnContestStatus({
    doRedirects: false,
  });
  const { currentUser } = useContext(AuthContext);
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
  const [navItemsToDisplay, setNavItemsToDisplay] = useState(NAV_ITEMS);

  useEffect(() => {
    const closeIsMoreOptions = () => {
      if (isMoreOptionsOpen) {
        setIsMoreOptionsOpen(false);
      }
    };

    document.addEventListener("click", closeIsMoreOptions);

    return () => {
      document.removeEventListener("click", closeIsMoreOptions);
    };
  }, [isMoreOptionsOpen]);

  useEffect(() => {
    const newNavItems = NAV_ITEMS.map((navItem) => {
      if (
        currentUser?.role.type !== "moderator" &&
        isPageAllowed(navItem.to) === false
      ) {
        navItem.disabled = true;
      }

      return navItem;
    });

    if (currentUser?.role.type === "moderator") {
      newNavItems.push(MODERATOR_NAV_ITEM);
    }

    setNavItemsToDisplay(newNavItems);
  }, [isPageAllowed, currentUser, setNavItemsToDisplay]);

  const navItemElements = navItemsToDisplay.map((navItem) => (
    <NavItem navItem={navItem} key={navItem.id} className={navItem.class} />
  ));

  const logoutNavItem = (
    <NavItem navItem={{
      name: "Log out",
      to: "/auth/logout",
      icon: LOGOUT_ICON,
      disabled: false
    }} />
  );

  return (
    <>
      <div className={`short-desktop-nav ${isMoreOptionsOpen ? "expanded" : ""}`}>
        <div className="bg"></div>

        <div className="nav-container">
          <nav className="h-100 d-flex flex-lg-column flex-row flex-wrap py-lg-4 row-gap-lg-5">
            {navItemElements}
            {currentUser && logoutNavItem}
          </nav>

          <button type="button"
            className="d-block menu-btn bg-transparent border-0 text-white"
            onClick={(e) => {
              e.stopPropagation();
              setIsMoreOptionsOpen(v => !v);
            }}>
            {
              isMoreOptionsOpen
                ? <FontAwesomeIcon icon={CLOSE_ICON} size="2x" />
                : <FontAwesomeIcon icon={MENU_ICON} size="2x" />
            }
          </button>
        </div>
      </div >
      <div className="desktop-nav flex-column h-100 overflow-hidden">
        <nav className="flex-grow-1 min-height-0 d-flex flex-lg-column flex-row flex-wrap py-lg-4 row-gap-lg-5">
          {navItemElements}
          {currentUser && logoutNavItem}
        </nav>
      </div>
      <nav className="mobile-nav h-100">
        {navItemElements.length > 3 ? (
          <>
            {navItemElements.slice(0, 2)}
            <button
              className={`main-nav-item text-white position-relative text-decoration-none ${isMoreOptionsOpen && "active"
                }`}
              onClick={(event) => {
                event.stopPropagation();
                setIsMoreOptionsOpen((value) => !value);
              }}
            >
              <FontAwesomeIcon icon={MENU_ICON} size="2x" />
              <p className="m-0 mt-1 fs-6">More</p>
            </button>
            <ul
              className={`main-nav-more-options w-100 m-0 list-unstyled d-flex flex-wrap align-items-center bg-theme-primary ${isMoreOptionsOpen && "open"
                }`}
              onClick={(event) => event.stopPropagation()}
            >
              {navItemElements.slice(2).map((navItemElement) => (
                <li
                  key={navItemElement.key}
                  className="col-4 h-100 d-flex align-content-stretch"
                  onClick={() => setIsMoreOptionsOpen(false)}
                >
                  {navItemElement}
                </li>
              ))}
              {currentUser && (
                <li className="col-4 h-100 d-flex align-content-stretch"
                  onClick={() => setIsMoreOptionsOpen(false)}>
                  {logoutNavItem}
                </li>
              )}
            </ul>
          </>
        ) : (
          <>{navItemElements}</>
        )}
      </nav>
    </>
  );
};
