import "./App.css";
import { BackendUrlContext } from "./context/BackendUrlContext";
import { GlobalContextProvider } from "./components/common/GlobalContextProvider";
import { ApolloProvider } from "@apollo/client";
import getClient from "./apolloClient";
import { LoadingScreen } from "./components/authentication/LoadingScreen";
import { ThemeConfigContext } from "./context/ThemeConfigContext";
import { useTheme } from "./hooks/useTheme";
import { AuthenticationProvider } from "./components/authentication/AuthenticationProvider";

export const App: React.FC<React.PropsWithChildren> = ({ children }) => {
  const backendUrl = process.env.REACT_APP_API_URL ?? "";
  const {
    themeConfig,
    loading: themeLoading,
    error: themeError,
  } = useTheme();

  return (
    <>
      {(themeLoading) && <LoadingScreen />}

      <BackendUrlContext.Provider value={backendUrl}>
        {themeError || themeConfig === null ? (
          <div className="m-4 alert alert-danger">
            <p className="mb-2 fs-5 fw-semibold">Error Loading Theme</p>
            <p className="m-0">{themeError}</p>
          </div>
        ) : (
          <>
            {
              themeConfig && (
                <link
                  rel="stylesheet"
                  href={`${process.env.PUBLIC_URL}/${themeConfig?.styleSheetUrl}`}
                />
              )
            }
            <ThemeConfigContext.Provider value={themeConfig}>
              <ApolloProvider client={getClient(backendUrl)}>
                {/* <div className="theme-switcher position-fixed m-2">
                  <select
                    className="form-select"
                    value={themeConfig.name}
                    onChange={(e) =>
                      setThemeName((e.target as HTMLSelectElement).value)
                    }>
                    {themeOptions.map((themeOption) => (
                      <option key={themeOption} value={themeOption}>
                        {themeOption}
                      </option>
                    ))}
                  </select>
                </div> */}
                <AuthenticationProvider>
                  <GlobalContextProvider>{children}</GlobalContextProvider>
                </AuthenticationProvider>
              </ApolloProvider>
            </ThemeConfigContext.Provider>
          </>
        )}
      </BackendUrlContext.Provider>
    </>
  );
};
