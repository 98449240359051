import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { MouseEventHandler } from 'react';
import Button from 'react-bootstrap/esm/Button';

interface IconButtonProps {
  icon: IconDefinition,
  onClick: MouseEventHandler<HTMLButtonElement>,
  text?: string,
  disabled?: boolean,
  className?: string,
  size?: SizeProp,
  title?: string,
}

export const IconButton: React.FC<IconButtonProps> = ({ icon, text, onClick, disabled = false, className = "", size = "xl", title = "" }) => {
  return (
    <Button
      variant="transparent"
      onClick={onClick}
      disabled={disabled}
      className={`border-0 p-2 ${className}`}
      title={title}
    >
      <FontAwesomeIcon
        icon={icon}
        size={size}
      />
      <p className='text-small m-0 text-small-caps lh-sm'>{text}</p>
    </Button>
  );
}
