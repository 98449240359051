import React from "react";
import { Loader } from "./Loader";
import { BottomOfFeed } from "./BottomOfFeed";
import { Photo } from "../../types";

interface PhotoDisplayContainerProps {
  loading: boolean;
  photos: Photo[];
  showBottomOfFeed?: boolean;
}

export const PhotoDisplayContainer: React.FC<
  React.PropsWithChildren<PhotoDisplayContainerProps>
> = ({
  children,
  loading,
  photos,
  showBottomOfFeed = true
}) => {
    return (
      <div className="p-xl-1">
        {photos.length === 0 && loading ? (
          <div className="position-absolute top-50 start-50 translate-middle">
            <Loader message="Loading Photos..." />
          </div>
        ) : (
          <>
            {children}
            {showBottomOfFeed && <BottomOfFeed />}
            <div className="p-1" aria-hidden="true"></div>
          </>
        )}
      </div>
    );
  };
