import { useContext } from "react";
import { SettingsContext } from "../../context/SettingsContext";
import { useAuthorization } from "../../hooks/useAuthorization";

export const ContestClosedPage = () => {
  useAuthorization('authenticated', 'moderator');
  const {
    attributes: { enableSubmissionsDate, disableVotingDate },
  } = useContext(SettingsContext);

  const isBeforeContest = () => new Date() < new Date(enableSubmissionsDate);
  const isAfterContext = () => new Date() > new Date(disableVotingDate);

  return (
    <section className="page d-flex flex-column">
      <div className="flex-grow-1 overflow-y-auto text-center mt-5">
        {isBeforeContest() && (
          <>
            <h2>The Photo Contest isn't open yet!</h2>

            <p className="fs-5">
              The photo contet will open on{" "}
              {new Date(enableSubmissionsDate).toDateString()}. come back then!
            </p>
          </>
        )}
        {isAfterContext() && (
          <>
            <h2>The Photo Contest is closed.</h2>

            <p className="fs-5">
              The contest has been closed since{" "}
              {new Date(disableVotingDate).toDateString()}. Stay tuned to find
              out who won!
            </p>
          </>
        )}
      </div>
    </section>
  );
};
