import React, { MouseEventHandler } from "react";
import Button from "react-bootstrap/esm/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HELP_ICON } from "../../constants/icons";

interface MobileHelpButtonProps {
  onClick: MouseEventHandler;
}

export const MobileHelpButton: React.FC<MobileHelpButtonProps> = ({
  onClick,
}) => {
  return (
    <Button
      onClick={onClick}
      variant="light"
      className="mobile-help-button z-1"
    >
      <FontAwesomeIcon icon={HELP_ICON} size="lg" className="me-2" />
      Help
    </Button>
  );
};
