import { Link } from "react-router-dom"

export const UserBlocked: React.FC = () => {
  return (
    <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
      <div className="rounded px-md-5 px-4 py-5 col-lg-6 bg-body shadow">
        <h2 className="mb-3">User blocked</h2>
        <p>A moderator has blocked you from accessing this contest. If you think this is a mistake, please contact the contest administrators.</p>
        <img className="d-block mx-auto" src="https://media.tenor.com/x7E9uhFQsPkAAAAd/dennis-jurassic-park.gif" alt="Ah ah ah, you didn't say the magic word!" height="300" />
        <Link className="btn btn-primary" to="/auth/login">
          Back to login
        </Link>
      </div>
    </div>
  )
}
