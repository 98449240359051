import { createContext } from "react";
import { ThemeConfig } from "../types";

export const ThemeConfigContext = createContext<ThemeConfig>({
  name: "",
  organizationName: "",
  favicon: "",
  smallLogoDarkUrl: "",
  smallLogoLightUrl: "",
  fullLogoLightUrl: "",
  fullLogoDarkUrl: "",
  styleSheetUrl: ""
});
