import { useContext, useState } from "react";
import { SettingsContext } from "../context/SettingsContext";
import { Comment, Photo } from "../types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_COMMENTS_BY_PHOTO } from "../queries/getCommentsByPhoto";
import { UPDATE_COMMENT_SCREENING_STATUS } from "../mutations/updateCommentScreeningStatus";
import { CREATE_COMMENT } from "../mutations/createComment";
import { AuthContext } from "../context/AuthContext";

export const useComments = (
  photo: Photo
): {
  comments: Comment[];
  loading: boolean;
  addComment: (body: string) => Promise<any>;
  removeComment: (commentId: number) => Promise<any>;
  showMoreComments: () => void;
  showLessComments: () => void;
  totalComments: number;
} => {
  const { currentUser } = useContext(AuthContext);

  const {
    attributes: { maxCommentBodyLength },
  } = useContext(SettingsContext);

  const [comments, setComments] = useState<Comment[]>(
    photo.attributes.comments.data
  );

  const [getComments, { data, loading }] = useLazyQuery(GET_COMMENTS_BY_PHOTO, {
    variables: {
      photoId: photo.id,
      start: 0,
      limit: 5,
    },
    onCompleted: (data) => {
      setComments(data.comments.data);
    },
  });
  const [rejectCommentMutation] = useMutation(UPDATE_COMMENT_SCREENING_STATUS, {
    variables: {
      screeningStatus: "rejected",
    },
  });
  const [createCommentMutation] = useMutation(CREATE_COMMENT, {
    onCompleted: (data) => {
      setComments((comments) => [data.createComment.data, ...comments]);
    },
  });

  const addComment = async (body: string): Promise<any> => {
    if (
      body.trim().length === 0 ||
      body.length > maxCommentBodyLength ||
      currentUser === null
    )
      return;

    const response = await createCommentMutation({
      variables: { user: currentUser.id, photo: photo.id, body },
    });

    return response;
  };

  const removeComment = async (commentId: number) => {
    const response = await rejectCommentMutation({
      variables: { id: commentId },
    });

    setComments((comments) =>
      comments.filter((comment) => comment.id !== commentId)
    );

    return response;
  };

  const showMoreComments = () => {
    getComments({ variables: { limit: comments.length + 5 } });
  };

  const showLessComments = () => {
    setComments((comments) => comments.slice(0, 1));
  };

  return {
    comments,
    loading,
    addComment,
    removeComment,
    showMoreComments,
    showLessComments,
    totalComments: data?.comments.meta.pagination.total || 0,
  };
};
