import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";
import { NUMBER_OF_COMMENTS_TO_PULL_IN } from "../constants/constants";

export const CREATE_PHOTO: DocumentNode = gql`
  mutation CreatePhoto($category: ID!, $name: String!, $user: ID!, $photo: ID!, $additionalData: JSON) {
    createPhoto(
      data: {
        user: $user,
        photo: $photo,
        name: $name,
        category: $category,
        additionalData: $additionalData
      }
    ) {
      data {
        id
        attributes {
          name
          createdAt
          moderatedAt
          category {
            data {
              id
              attributes {
                displayName
                color
                icon
                name
              }
            }
          }
          comments(
            filters: { screeningStatus: { ne: "rejected" } }
            sort: "createdAt:desc"
            pagination: { start: 0, limit: ${NUMBER_OF_COMMENTS_TO_PULL_IN} }
          ) {
            data {
              id
              attributes {
                body
                screeningStatus
                createdAt
                user {
                  data {
                    id
                    attributes {
                      firstName
                      lastName
                      email
                    }
                  }
                }
              }
            }
          }
          likes {
            count
            currentUsersLike {
              data {
                id
              }
            }
          }
          photo {
            data {
              id
              attributes {
                url
                formats
              }
            }
          }
          user {
            data {
              id
              attributes {
                firstName
                lastName
                email
              }
            }
          }
          votes {
            id
            count
          }
        }
      }
    }
  }
`;
