import { useState, useEffect, useContext, useCallback } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { LOGIN } from "../mutations/login";
import { GET_ME } from "../queries/getMe";
import { BackendUrlContext } from "../context/BackendUrlContext";
import { AuthenticatedUser, RegisterInput } from "../types";
import { LOGIN_WITH_EMAIL_AND_PASSWORD } from "../mutations/loginWithEmailAndPassword";
import { REGISTER } from "../mutations/register";

export const useAuthentication = (): {
  jwt: string | null;
  currentUser: AuthenticatedUser | null;
  loginWithProvider: () => void;
  loginWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  registerUser: (userData: RegisterInput, initiallyApproved: boolean) => Promise<void>;
  loading: boolean;
  redirectUrl: string | null;
  authError: string | null;
  logout: () => void;
} => {
  const [jwt, setJwt] = useState<string | null>(
    sessionStorage.getItem("token")
  );
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [authError, setAuthError] = useState<string | null>(null);
  const backendUrl = useContext(BackendUrlContext);

  const [getMe, { data: meData, loading: meLoading, error: meError }] = useLazyQuery(GET_ME);

  const authorizeAccountStatus = useCallback((userData: AuthenticatedUser) => {
    let authorized: boolean = true;
    let redirectUrl: string = '';

    if (userData.confirmed === false) {
      redirectUrl = '/auth/not-confirmed';
      authorized = false;
    } else if (userData.approved === 'pending') {
      redirectUrl = '/auth/pending-approval';
      authorized = false;
    } else if (userData.approved === 'rejected') {
      redirectUrl = '/auth/blocked';
      authorized = false;
    }

    return { authorized, redirectUrl };
  }, []);

  const saveJwtToSessionStorage = useCallback((jwt: string) => {
    sessionStorage.setItem("token", jwt);
    setJwt(jwt);
  }, [setJwt]);

  const [loginWithProviderMutation, { data: loginWithProviderData, loading: loginWithProviderLoading }] = useMutation(
    LOGIN,
    {
      onCompleted(data) {
        const { authorized, redirectUrl } = authorizeAccountStatus(data.login.user);

        if (authorized) {
          saveJwtToSessionStorage(data.login.jwt);
        } else {
          setRedirectUrl(redirectUrl);
        }

        const url = new URL(window.location.toString());
        url.searchParams.delete("access_token");
        window.history.pushState({}, "", url); // clear access_token from URL
      },
      onError(err) {
        console.error(err);
        setAuthError(err.message);
        setRedirectUrl(null);
      }
    }
  );

  const [loginWithEmailAndPasswordMutation, { data: loginWithEmailAndPasswordData, loading: loginWithEmailAndPasswordLoading }] = useMutation(
    LOGIN_WITH_EMAIL_AND_PASSWORD,
    {
      onCompleted(data) {
        console.log('completed');
        const { authorized, redirectUrl } = authorizeAccountStatus(data.login.user);

        if (authorized) {
          saveJwtToSessionStorage(data.login.jwt);
        } else {
          setRedirectUrl(redirectUrl);
        }
      },
      onError(err) {
        console.error(err);
        // TODO better handling for incorrect user/pwd
        setAuthError(err.message);

        // TODO make less hacky (probably involves some backend stuff)
        if (err.message === 'Your account email is not confirmed') {
          setRedirectUrl('/auth/not-confirmed');
        } else {
          setRedirectUrl(null);
        }
      }
    }
  );

  const [registerUserMutation] = useMutation(REGISTER);

  useEffect(
    () => {
      if (meData?.me || loginWithProviderLoading || meLoading || loginWithEmailAndPasswordLoading || meError) {
        return;
      }

      const accessToken = new URLSearchParams(window.location.search).get(
        "access_token"
      );
      const token = sessionStorage.getItem("token");

      if (token) {
        getMe();
      } else if (accessToken) {
        loginWithProviderMutation({
          variables: {
            provider: "microsoft",
            accessToken: accessToken || "",
          },
        });
      }
    },
    [
      loginWithProviderData,
      loginWithEmailAndPasswordData,
      meData,
      meLoading,
      loginWithProviderLoading,
      loginWithEmailAndPasswordLoading,
      getMe,
      loginWithProviderMutation,
      backendUrl,
      meError,
    ]
  );

  const loginWithProvider = useCallback(() => {
    const accessToken = new URLSearchParams(window.location.search).get(
      "access_token"
    );
    const token = sessionStorage.getItem("token");

    if (token || accessToken || backendUrl === "") return;
    window.location.href = `${backendUrl}/api/connect/microsoft`;
  }, [backendUrl]);

  const loginWithEmailAndPassword = useCallback(async (email: string, password: string) => {
    await loginWithEmailAndPasswordMutation({
      variables: {
        email,
        password
      }
    });
  }, [loginWithEmailAndPasswordMutation]);

  const registerUser = useCallback(async (newUser: RegisterInput, initiallyApproved: boolean) => {
    await registerUserMutation({
      variables: {
        email: newUser.email,
        password: newUser.password,
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        approved: initiallyApproved ? "approved" : "pending"
      }
    });
  }, [registerUserMutation]);

  const logout = useCallback(() => {
    sessionStorage.removeItem('token');
    setJwt(null);
  }, [setJwt]);

  return {
    jwt,
    currentUser: meData?.me || null,
    loginWithProvider,
    loginWithEmailAndPassword,
    loading: loginWithProviderLoading || loginWithEmailAndPasswordLoading || meLoading,
    registerUser,
    redirectUrl,
    authError,
    logout
  };
};
