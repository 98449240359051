import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { VOTE_ICON } from "./icons";

export const votingHelpContent: React.FC = () => (
  <>
    <FontAwesomeIcon icon={VOTE_ICON} size="4x" className="mb-3" />
    <h3>Vote</h3>
    <br />

    <p>
      Here you can vote on your favorite photos for the contest to determine the
      winners in each category of the contest.
    </p>

    <p>
      You have a limited budget of votes, indicated in the upper right-hand
      corner of the voting page. Each photo can be awarded up to 5 votes.
    </p>

    <p>
      The 3 highest-voted photos in each category and the contest champion will receive prizes (
      <Link to="/prizes">
        see more
      </Link>
      ).
    </p>
  </>
);
