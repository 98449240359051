import React, { useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

export const LoginMicrosoft: React.FC = () => {
  const { loginWithProvider } = useContext(AuthContext);

  useEffect(() => {
    loginWithProvider();
  }, [loginWithProvider]);

  return (
    <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
      <div className="rounded px-md-5 px-4 py-5 bg-body shadow col-lg-6">
        <p className="fs-5 mb-0 text-center">Authenticating with Microsoft...</p>
      </div>
    </div>
  );
};
