import React from "react";
import Modal from "react-bootstrap/esm/Modal";

interface PageHelpModalProps {
  show: boolean;
  onHide: () => void;
  pageTitle: string;
  content: React.FC
}

export const PageHelpModal: React.FC<PageHelpModalProps> = ({
  show,
  onHide,
  pageTitle,
  content: ContentComponent,
}) => {
  return (
    <Modal show={show} onHide={onHide} fullscreen="md-down" scrollable centered>
      <Modal.Header
        closeButton
        closeVariant="white"
        className="bg-theme-primary text-white"
      >
        <Modal.Title className="m-0">{pageTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center help-modal">
        <ContentComponent />
        <hr />
        <p>
          Have any questions or issues? Please{" "}
          <a href="https://helpdesk.mccormicktaylor.com/support/tickets/new">
            submit a Helpdesk ticket
          </a>
          .
        </p>
        <p>
          Under the "Category" input, select "Marketing", and under the
          "Sub-Category" input, select "Photo Contest".
        </p>
      </Modal.Body>
    </Modal>
  );
};
