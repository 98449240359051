import { RefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ScrollableElementContext } from "../context/ScrollableElementContext";

// takes in a callback, calls the callback when the ref is scrolled to show it's bottom on screen, returns a ref
export const useCallbackWhenAtBottom = <T extends HTMLElement>(
  callback: () => void,
  timeout: number
): RefObject<T> => {
  const [isBlockedByTimeout, setIsBlockedByTimeout] = useState<boolean>(false);
  const ref = useRef<T>(null);
  const scrollableElement = useContext(ScrollableElementContext);

  const checkScrollPosition = useCallback(() => {
    if (ref.current === null || scrollableElement === null || isBlockedByTimeout) {
      return;
    }

    setIsBlockedByTimeout(true);
    setTimeout(() => {
      setIsBlockedByTimeout(false);
    }, timeout);

    const element = ref.current;
    const bottomFromTopOfViewport =
      element.scrollHeight -
      (scrollableElement.scrollTop + scrollableElement.offsetHeight);

    if (bottomFromTopOfViewport <= 0) {
      callback();
    }
  }, [callback, scrollableElement, setIsBlockedByTimeout, isBlockedByTimeout, timeout]);

  useEffect(() => {
    if (scrollableElement === null) return;

    scrollableElement.addEventListener("scroll", checkScrollPosition);

    return () => {
      scrollableElement.removeEventListener("scroll", checkScrollPosition);
    };
  }, [checkScrollPosition, scrollableElement]);

  useEffect(checkScrollPosition, [checkScrollPosition]);

  return ref;
};
