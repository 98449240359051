import { useContext, useEffect } from "react";
import { ScrollableElementContext } from "../context/ScrollableElementContext";

export const useScrollIntoView = (id: string): void => {
  const scrollableElement = useContext(ScrollableElementContext);

  useEffect(() => {
    const element = document.getElementById(id);

    if (element && scrollableElement) {
      const height = element.offsetTop - 80;
      scrollableElement.scrollTop = height;
    }
  }, [id, scrollableElement]);
}