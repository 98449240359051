import React, { useEffect, useRef } from 'react';
import { TopBar } from '../common/TopBar';
import { PageHeading } from '../common/PageHeading';
import { useAuthorization } from '../../hooks/useAuthorization';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_USER_BY_ID } from '../../queries/getUserById';
import { useQuery } from '@apollo/client';
import { ScrollableElementContext } from '../../context/ScrollableElementContext';
import { SingleQuery, User } from '../../types';
import { LayoutControls } from '../common/LayoutControls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ACCOUNT_ICON } from '../../constants/icons';
import { Loader } from '../common/Loader';
import { PhotoProvider } from '../common/PhotoProvider';
import { GET_PHOTOS_BY_USER_DETAILED } from '../../queries/getPhotosByUserDetailed';

export const ProfilePage: React.FC = () => {
  useAuthorization('authenticated', 'moderator');

  const navigate = useNavigate();

  const scrollableRef = useRef(null);

  const { id: userId } = useParams();

  const { data: userData, loading: userLoading, error: userError } = useQuery<SingleQuery<User>>(GET_USER_BY_ID, {
    variables: {
      userId
    }
  });

  useEffect(() => {
    if (!userData?.usersPermissionsUser?.data && !userLoading && !userError) {
      navigate("/");
    }
  }, [navigate, userData, userLoading, userError]);

  let body: JSX.Element;
  if (userLoading) {
    body = (
      <Loader message="Loading user profile..." />
    );
  } else if (userError) {
    body = (
      <div className="alert alert-danger" role="alert">
        {userError?.message}
      </div>
    )
  } else if (!userData?.usersPermissionsUser?.data) {
    body = (
      <div className="alert alert-information" role="alert">
        This user page does not exist. Please wait while redirecting you back home...
      </div>
    );
  } else {
    const userFullName = userData.usersPermissionsUser.data.attributes.firstName + " " + userData.usersPermissionsUser.data.attributes.lastName;

    body = (
      <>
        <div>
          <div className="d-flex align-items-center gap-3 my-3">
            <div>
              <FontAwesomeIcon icon={ACCOUNT_ICON} size="4x" />
            </div>
            <div className="flex-grow-1">
              <h2 className="fs-4">{userFullName}</h2>
              <h3 className="fs-6 fw-normal text-secondary">{userData.usersPermissionsUser.data.attributes.email}</h3>
            </div>
          </div>
        </div>

        <hr />

        <PhotoProvider query={GET_PHOTOS_BY_USER_DETAILED}
          queryOptions={{
            variables: {
              userId
            }
          }}
          endOfFeedMessage={{
            type: 'profilePage',
            message: `No more photos by ${userFullName}`,
            showUploadPrompt: false
          }} />
      </>
    );
  }

  return (
    <section className="page d-flex flex-column">
      <TopBar pageTitle="Profile">
        <PageHeading className="d-lg-none d-flex">
          <h2 className="m-0 fw-bold fs-3">Profile</h2>
        </PageHeading>
        <LayoutControls />
      </TopBar>

      <div className="flex-grow-1 overflow-y-auto" ref={scrollableRef}>
        <ScrollableElementContext.Provider value={scrollableRef.current}>
          <div className="container-lg h-100">
            {body}
          </div>
        </ScrollableElementContext.Provider>
      </div>
    </section>
  );
}
