import { useMutation } from "@apollo/client";
import { useState, ChangeEvent, FormEvent, useContext, useEffect } from "react";
import { UpdateUserInfoInput } from "../../../types";
import { Loader } from "../../common/Loader";
import { UPDATE_USER_INFO } from "../../../mutations/updateUserInfo";
import { AuthContext } from "../../../context/AuthContext";

export const UpdateUserInfo: React.FC = () => {
  const { currentUser } = useContext(AuthContext);
  const [input, setInput] = useState<UpdateUserInfoInput>({
    firstName: '',
    lastName: ''
  });

  useEffect(() => {
    if (!currentUser) return;

    setInput({
      firstName: currentUser.firstName,
      lastName: currentUser.lastName
    })
  }, [currentUser]);

  const [updateUserInfo, { loading, error }] = useMutation(UPDATE_USER_INFO, {
    variables: {
      userId: currentUser?.id
    },
    onError(err) {
      console.error(err);
    }
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(input => ({
      ...input,
      [e.target.name]: e.target.value
    }));
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const response = await updateUserInfo({
      variables: {
        ...input
      }
    });

    if (!response.errors) {
      window.location.reload();
    }
  }

  if (loading) {
    return <Loader message="Updating user info..." />
  }

  return (
    <>
      {
        error && (
          <div className="alert alert-warning" role="alert">
            <p className="fs-5 fw-semibold">There was an error updating your user info.</p>
            <p>{error.message ?? ""}</p>
          </div>
        )
      }
      <form action="PATCH" className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="firstName" className="form-label">
            First Name
          </label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            className="form-control"
            required
            value={input.firstName}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="lastName" className="form-label">
            Last Name
          </label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            className="form-control"
            required
            value={input.lastName}
            onChange={handleInputChange}
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Update User Info
        </button>
      </form>
    </>
  );
}
