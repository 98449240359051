import { useCallback } from "react";
import { ColumnPhoto } from "../photo/ColumnPhoto";
import { useSearchParams } from "react-router-dom";
import { useScrollIntoView } from "../../hooks/useScrollIntoView";
import { useCallbackWhenAtBottom } from "../../hooks/useCallbackWhenAtBottom";
import { Photo } from "../../types";

interface ColumnPhotoDisplayProps {
  photos: Photo[];
  onScrollBottom: () => void;
}

export const ColumnPhotoDisplay: React.FC<ColumnPhotoDisplayProps> = ({
  photos,
  onScrollBottom,
}) => {
  const [params] = useSearchParams();

  useScrollIntoView(`photo-id-${params.get("photo")}` || "");

  const refetch = useCallback(() => {
    if (photos.length > 0) {
      onScrollBottom();
    }
  }, [photos, onScrollBottom]);

  const ref = useCallbackWhenAtBottom<HTMLDivElement>(refetch, 1000);

  const photoElements = photos.map((photo) => (
    <ColumnPhoto photo={photo} key={photo.id} />
  ));

  return (
    <div
      ref={ref}
      className="photo-feed align-items-center gap-xl-5 gap-md-4 py-2"
    >
      {photoElements}
    </div>
  );
};
