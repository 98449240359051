import React, { useEffect, useState } from "react";
import { Category } from "../../types";
import {
  IconDefinition,
  IconLookup,
  IconName,
  SizeProp,
  findIconDefinition,
} from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface CategoryIconProps {
  category: Category;
  size?: SizeProp;
  color?: string;
}

export const CategoryIcon: React.FC<CategoryIconProps> = ({
  category,
  size = "2x",
  color = "inherit",
}) => {
  const [icon, setIcon] = useState<IconDefinition | null>(null);
  useEffect(() => {
    const iconLookup: IconLookup = {
      prefix: "fas",
      iconName: category.attributes.icon as IconName,
    };
    setIcon(findIconDefinition(iconLookup));
  }, [category.attributes.icon, setIcon]);

  return icon && <FontAwesomeIcon icon={icon} size={size} color={color} />;
};
