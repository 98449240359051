import { sleep } from "./sleep";

export async function animateContent(
  content: HTMLDivElement,
  contentChangeFunction: Function,
  exitSide: "left" | "right",
  enterSide: "left" | "right"
): Promise<void> {
  const exitClass = `content-${exitSide}`;
  const enterClass = `content-${enterSide}`;

  content.classList.replace("content-center", exitClass);
  await sleep(200);

  content.classList.replace("animate-content", "dont-animate-content");
  contentChangeFunction();
  content.classList.replace(exitClass, enterClass);
  await sleep(50);

  content.classList.replace("dont-animate-content", "animate-content");
  content.classList.replace(enterClass, "content-center");
}
