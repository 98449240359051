import { LIST_ICON, GRID_ICON } from '../../constants/icons';
import { IconButton } from './IconButton';
import { useSearchParams } from 'react-router-dom';

interface LayoutControlsProps {
  disabled?: boolean;
}

export const LayoutControls: React.FC<LayoutControlsProps> = ({ disabled = false }) => {
  const [, setSearchParams] = useSearchParams();
  const handleChangeView = (view: string): void => {
    setSearchParams({ view });
  }

  return (
    <div className='d-flex'>
      <IconButton
        icon={LIST_ICON}
        text='feed'
        onClick={() => handleChangeView('feed')}
        className='text-white'
        disabled={disabled}
      />

      <IconButton 
        icon={GRID_ICON}
        text='grid'
        onClick={() => handleChangeView('grid')}
        className='text-white'
        disabled={disabled}
      />
    </div>
  )
}
