import { createContext } from "react";
import { AuthenticatedUser, RegisterInput } from "../types";

export const AuthContext = createContext<{
  jwt: string | null;
  currentUser: AuthenticatedUser | null;
  loginWithProvider: () => void;
  loginWithEmailAndPassword: (email: string, password: string) => void;
  loading: boolean;
  redirectUrl: string | null;
  registerUser: (newUser: RegisterInput, initiallyApproved: boolean) => Promise<void>;
  authError: string | null;
  logout: () => void;
}>({
  jwt: null,
  currentUser: null,
  loginWithProvider: () => { },
  loginWithEmailAndPassword: (_: string, __: string) => { },
  loading: true,
  redirectUrl: null,
  registerUser: async (_: RegisterInput, __: boolean) => { },
  authError: null,
  logout: () => { }
});
