import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const CREATE_LIKE: DocumentNode = gql`
# Creates a new like between a user and a photo
mutation CreateLike($user: ID!, $photo: ID!) {
  createLike(
    data: { 
      user: $user, 
      photo: $photo
    }
  ) {
    data {
      id
      attributes {
        user {
          data {
            id
          }
        }
        photo {
          data {
            id
          }
        }
      }
    }
  }
}
`;