
export const dateToHowLongAgo = (dateText: string): string => {
  const date = new Date(dateText);
  const now = Date.now();

  const timeDifference = now.valueOf() - date.valueOf();

  const timeDifferenceSeconds = Math.floor(timeDifference / 1000);
  const timeDifferenceMinutes = Math.floor(timeDifferenceSeconds / 60);
  const timeDifferenceHours = Math.floor(timeDifferenceMinutes / 60);
  const timeDifferenceDays = Math.floor(timeDifferenceHours / 24);

  let message;

  if (timeDifferenceDays > 0) {
    message = `${timeDifferenceDays}d`;
  } else if (timeDifferenceHours > 0) {
    message = `${timeDifferenceHours}hr`;
  } else if (timeDifferenceMinutes > 0) {
    message = `${timeDifferenceMinutes}min`;
  } else {
    message = "now";
  }

  return message;
}