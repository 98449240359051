import { useCallback, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PHOTOS_BY_USER } from "../queries/getPhotosByUser";
import { Photo, PhotoUpload } from "../types";
import { CREATE_PHOTO } from "../mutations/createPhoto";
import { DELETE_PHOTO } from "../mutations/deletePhoto";

export const useMyPhotos = () => {
  const { currentUser } = useContext(AuthContext);

  const userId = currentUser?.id;

  const { data, loading, error } = useQuery(GET_PHOTOS_BY_USER, {
    variables: {
      userId,
    },
  });
  const photos: Photo[] = data?.photos.data || [];

  const [createPhotoMutation] = useMutation(CREATE_PHOTO);
  const [deletePhotoMutation] = useMutation(DELETE_PHOTO);

  /**
   * Create a new photo given the photo's data, imageId (from createMedia), and categoryId.
   */
  const createPhoto = useCallback(
    async (photo: PhotoUpload, imageId: number, categoryId: number) => {
      const data = await createPhotoMutation({
        variables: {
          user: userId,
          category: categoryId,
          name: photo.name,
          photo: imageId,
          additionalData: photo.additionalData
        },
        refetchQueries: [GET_PHOTOS_BY_USER],
      });

      return data;
    },
    [createPhotoMutation, userId]
  );

  /**
   * Delete a photo given it's id.
   */
  const deletePhoto = useCallback(
    async (photoId: number) => {
      return deletePhotoMutation({
        variables: { id: photoId },
        refetchQueries: [GET_PHOTOS_BY_USER],
      });
    },
    [deletePhotoMutation]
  );

  return {
    photos,
    loading,
    error,
    createPhoto,
    deletePhoto,
  };
};
