import { ChangeEvent, useContext } from "react";
import FormSelect from "react-bootstrap/esm/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FILTER_ICON } from "../../constants/icons";
import { CategoriesContext } from "../../context/CategoriesContext";

interface DropdownCategoryFilterProps {
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
}

export const DropdownCategoryFilter: React.FC<DropdownCategoryFilterProps> = ({
  selectedOption,
  setSelectedOption,
}) => {
  const categories = useContext(CategoriesContext);

  const dropdownOptions = categories.map((c) => (
    <option key={c.id} value={c.attributes.name}>
      {c.attributes.displayName}
    </option>
  ));
  dropdownOptions.unshift(
    <option value="all" key="all">
      All Photos
    </option>
  );

  const handleChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    setSelectedOption(e.target.value);
  };

  return (
    <div className="d-flex gap-2 align-items-center">
      <FontAwesomeIcon icon={FILTER_ICON} size="xl" />
      <FormSelect
        value={selectedOption}
        onChange={handleChange}
        className="fw-bold"
      >
        {dropdownOptions}
      </FormSelect>
    </div>
  );
};
