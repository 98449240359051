import React, {
  FormEventHandler,
  ChangeEventHandler,
  useState,
  useContext,
} from "react";
import Form from "react-bootstrap/esm/Form";
import { Photo } from "../../types";
import { Comment } from "./Comment";
import { useComments } from "../../hooks/useComments";
import { SettingsContext } from "../../context/SettingsContext";
import { TextIconButton } from "../common/TextIconButton";
import { SEND_ICON } from "../../constants/icons";

interface CommentSectionProps {
  photo: Photo;
  readonly?: boolean;
}

export const CommentSection: React.FC<CommentSectionProps> = ({
  photo,
  readonly = false,
}) => {
  const {
    attributes: { maxCommentBodyLength },
  } = useContext(SettingsContext);

  const {
    comments,
    loading,
    showMoreComments,
    showLessComments,
    totalComments,
    removeComment,
    addComment,
  } = useComments(photo);

  const [body, setBody] = useState<string>("");

  const handleSubmitCommentForm: FormEventHandler = (event) => {
    event.preventDefault();

    addComment(body);
    setBody("");
  };

  const handleChangeCommentInput: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const newBody = event.target.value;
    if (newBody.length <= maxCommentBodyLength) {
      setBody(newBody);
    }
  };

  return (
    <div className="px-2">
      <div className="py-2">
        {
          readonly === false && (
            <Form
              action=""
              className="d-flex gap-1 align-items-center"
              onSubmit={handleSubmitCommentForm}
            >
              <Form.Control
                id="comment-body"
                name="body"
                placeholder="Add Comment..."
                value={body}
                onChange={handleChangeCommentInput}
                disabled={readonly}
                autoComplete="off"
              />
              <TextIconButton
                type="submit"
                text="Post"
                icon={SEND_ICON}
                className="bg-transparent text-secondary border-secondary"
                disabled={readonly}
              />
            </Form>
          )
        }
      </div>

      <div className="comment-list p-1 overflow-auto">
        <ol className="list-unstyled mb-0">
          {comments.map((comment) => (
            <li key={comment.id}>
              <Comment comment={comment} removeComment={removeComment} />
            </li>
          ))}
          {loading && (
            <li className="text-secondary text-center">Loading...</li>
          )}
          {comments.length === 0 && totalComments === 0 && (
            <li className="text-secondary text-center">No Comments Yet.</li>
          )}
        </ol>
      </div>

      <div className="py-2 d-flex justify-content-evenly">
        <button
          className="border-0 bg-transparent fw-semibold"
          onClick={showMoreComments}
          disabled={loading || comments.length === totalComments}
        >
          Show More
        </button>
        <button
          className="border-0 bg-transparent fw-semibold"
          onClick={showLessComments}
          disabled={loading || comments.length <= 1}
        >
          Show Less
        </button>
      </div>
    </div>
  );
};
