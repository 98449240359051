import { useMutation } from "@apollo/client";
import { ChangeEvent, useState, FormEvent } from "react";
import { CHANGE_PASSWORD } from "../../../mutations/changePassword";
import { ChangePasswordInput } from "../../../types";
import { Loader } from "../../common/Loader";

export const ChangePassword: React.FC = () => {
  const [input, setInput] = useState<ChangePasswordInput>({
    currentPassword: "",
    password: "",
    passwordConfirmation: ""
  });

  const [changePassword, { data, loading, error }] = useMutation(CHANGE_PASSWORD, {
    onError(err) {
      console.error(err);
    }
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(input => ({
      ...input,
      [e.target.name]: e.target.value
    }));
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    changePassword({
      variables: {
        ...input
      }
    });
  }

  if (loading) {
    return <Loader message="Changing password..." />
  }

  return (
    <>
      {
        error && (
          <div className="alert alert-warning" role="alert">
            <p className="fs-5 fw-semibold">There was an error changing your password.</p>
            <p>{error.message ?? ""}</p>
          </div>
        )
      }
      {
        data && (
          <div className="alert alert-success" role="alert">
            <p className="fs-5 fw-semibold">Successfully changed your password.</p>
          </div>
        )
      }
      <form action="PATCH" className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="currentPassword" className="form-label">
            Old Password
          </label>
          <input
            type="password"
            name="currentPassword"
            id="currentPassword"
            className="form-control"
            required
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="password" className="form-label">
            New Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            className="form-control"
            required
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="passwordConfirmation" className="form-label">
            Confirm New Password
          </label>
          <input
            type="password"
            name="passwordConfirmation"
            id="passwordConfirmation"
            className="form-control"
            required
            onChange={handleInputChange}
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Change Password
        </button>
      </form>
    </>
  );
}
