import React from "react";
import { useSearchParams } from "react-router-dom";
import { DocumentNode } from "graphql";
import { usePhotos } from "../../hooks/usePhotos";
import { QueryHookOptions, WatchQueryFetchPolicy } from "@apollo/client";
import { END_OF_FEED_COPY } from "../../constants/endOfFeedCopy";
import { EndOfFeedMessageContext } from "../../context/EndOfFeedMessageContext";
import { ColumnPhotoDisplay } from "./ColumnPhotoDisplay";
import { GridPhotoDisplay } from "./GridPhotoDisplay";
import { PhotoDisplayContainer } from "./PhotoDisplayContainer";
import { Photo } from "../../types";

interface PhotoProviderProps {
  query: DocumentNode;
  queryOptions: QueryHookOptions;
  endOfFeedMessage?: typeof END_OF_FEED_COPY["default"];
}

export const PhotoProvider: React.FC<PhotoProviderProps> = ({
  query,
  queryOptions = {},
  endOfFeedMessage = END_OF_FEED_COPY["default"],
}) => {
  const { photos, loading, getNextPage } = usePhotos("photos", query, queryOptions);

  const [params, setSearchParams] = useSearchParams();
  const viewMode = params.get("view");
  const handleClickGridPhoto = (photo: Photo) => {
    setSearchParams({
      view: "feed",
      photo: photo.id.toString(),
    });
  };

  if (["commentedPhotos", "likedPhotos"].includes(endOfFeedMessage.type)) {
    if (photos?.length === 0) {
      const key = endOfFeedMessage.type + "Empty";
      endOfFeedMessage = END_OF_FEED_COPY[key as keyof typeof END_OF_FEED_COPY];
    }
  }

  return (
    <EndOfFeedMessageContext.Provider value={endOfFeedMessage}>
      <PhotoDisplayContainer photos={photos} loading={loading}>
        {viewMode === "feed" ? (
          <ColumnPhotoDisplay photos={photos} onScrollBottom={getNextPage} />
        ) : (
          <GridPhotoDisplay
            photos={photos}
            onScrollBottom={getNextPage}
            onClickPhoto={handleClickGridPhoto}
          />
        )}
      </PhotoDisplayContainer>
    </EndOfFeedMessageContext.Provider>
  );
};

export const queryProviderComponent = (
  query: DocumentNode,
  fetchPolicy: WatchQueryFetchPolicy,
  endOfFeedCopy: keyof typeof END_OF_FEED_COPY = "default"
): React.FC => {
  return () => {
    return (
      <PhotoProvider
        query={query}
        queryOptions={{ fetchPolicy }}
        endOfFeedMessage={END_OF_FEED_COPY[endOfFeedCopy]}
      />
    );
  };
};
