import { DocumentNode, gql } from "@apollo/client";

export const LOGIN: DocumentNode = gql`
  mutation LoginWithProvider($provider: String!, $accessToken: String!) {
    login(
      input: {
        identifier: ""
        password: ""
        provider: $provider
        accessToken: $accessToken
      }
    ) {
      jwt
      user {
        id
        username
        email
        voteBalance
        hasBeenOnboarded
        firstName
        lastName
        approved
        confirmed
        role {
          id
          type
        }
      }
    }
  }
`;
