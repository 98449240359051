export const COMMON_REJECTION_REASONS = [
  {
    id: 1,
    contentType: "Photo",
    value: "Subjects with insufficient PPE / unsafe conditions",
  },
  {
    id: 2,
    contentType: "Photo",
    value: "Inappropriate / offensive subject matter",
  },
  {
    id: 3,
    contentType: "Comment",
    value: "Disrespectful / inappropriate language or unkind sentiments",
  },
  {
    id: 4,
    contentType: "Comment",
    value:
      "Discussion of sensitive topics (eg. relgion, politics, sexual orientation, race/ethnicity, etc.)",
  },
];
