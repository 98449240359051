import { useState } from "react";

export const useSlideshow = (slides: any[], startingSlide: number = 0) => {
  const totalSlides = slides.length;
  const [currentSlide, setCurrentSlide] = useState<number>(startingSlide);

  const onNext = (): void => {
    if (currentSlide < totalSlides - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const onLast = (): void => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  return {
    currentSlide,
    totalSlides,
    onNext,
    onLast,
  };
};
