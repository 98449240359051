import { Settings } from "../types";

export const DEFAULT_SETTINGS: Settings = {
  attributes: {
    isVotingEnabled: false,
    isSubmissionsEnabled: false,
    maxPhotosPerCategory: 3,
    maxCommentBodyLength: 140,
    maxPhotoNameLength: 50,
    enableSubmissionsDate: new Date().toISOString().slice(0, 10),
    enableVotingDate: new Date().toISOString().slice(0, 10),
    disableVotingDate: new Date().toISOString().slice(0, 10),
  },
};
