export const END_OF_FEED_COPY = {
  default: {
    type: "default",
    message: "That's all for now... How about uploading a new photo?",
    showUploadPrompt: true,
  },
  myPhotos: {
    type: "myPhotos",
    message: "That's all for now... How about uploading a new photo?",
    showUploadPrompt: true,
  },
  myPhotosEmpty: {
    type: "myPhotosEmpty",
    message:
      "You have not submitted any photos yet, but when you do, they will appear here.",
    showUploadPrompt: true,
  },
  myPhotosPending: {
    type: "myPhotosPending",
    message:
      'You have photos pending review. See "Pending Review" for more details.',
    showUploadPrompt: false,
  },
  likedPhotos: {
    type: "likedPhotos",
    message: "No more liked photos.",
    showUploadPrompt: false,
  },
  likedPhotosEmpty: {
    type: "likedPhotosEmpty",
    message:
      "You have not liked any photos yet, but when you do, they will appear here.",
    showUploadPrompt: false,
  },
  commentedPhotos: {
    type: "commentedPhotos",
    message: "No more commented photos.",
    showUploadPrompt: false,
  },
  commentedPhotosEmpty: {
    type: "commentedPhotosEmpty",
    message:
      "You have not commented on any photos yet, but when you do, they will appear here.",
    showUploadPrompt: false,
  },
  commentedPhotosPending: {
    type: "commentedPhotosPending",
    message:
      'You have comments pending review. See "Pending Review" for more details.',
    showUploadPrompt: false,
  },
};
