import React, { useContext } from "react";
import { OnboardingSlideshow } from "./OnboardingSlideshow";
import { ONBOARDING_SLIDES } from "../../constants/onboardingSlides";
import Modal from "react-bootstrap/esm/Modal";
import { AuthContext } from "../../context/AuthContext";

interface OnboardingModalProps {
  show: boolean;
  onHide: () => void;
}

export const OnboardingModal: React.FC<OnboardingModalProps> = ({
  show,
  onHide,
}) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <Modal
      show={show}
      onHide={onHide}
      fullscreen="sm-down"
      backdrop={currentUser?.hasBeenOnboarded ? true : "static"}
      scrollable
    >
      <Modal.Header
        closeButton={currentUser?.hasBeenOnboarded}
        closeVariant="white"
        className="bg-theme-primary"
      />
      <Modal.Body>
        <OnboardingSlideshow slides={ONBOARDING_SLIDES} onHide={onHide} />
      </Modal.Body>
    </Modal>
  );
};
