import { gql } from "@apollo/client";

export const GET_COMMENTS_BY_SCREENING_STATUS = gql`
  query GetCommentsByScreeningStatus(
    $screeningStatus: String!
    $page: Int!
    $pageSize: Int!
  ) {
    comments(
      filters: { screeningStatus: { eq: $screeningStatus } }
      sort: "createdAt:asc"
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      data {
        id
        attributes {
          body
          screeningStatus
          createdAt
          user {
            data {
              id
              attributes {
                firstName
                lastName
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          pageCount
          pageSize
          page
        }
      }
    }
  }
`;
