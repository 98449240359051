import React from "react";
import { Media } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DISCARD_ICON } from "../../constants/icons";

interface LightboxProps {
  image?: Media;
  src?: string;
  alt: string;
  show: boolean;
  onHide: () => void;
}

export const Lightbox: React.FC<LightboxProps> = ({
  image,
  src,
  alt,
  show,
  onHide,
}) => {
  if (!show) {
    return <></>;
  }

  return (
    <div className="lightbox position-fixed w-100 h-100 top-0 start-0">
      <div className="w-100 h-100 dimmer bg-black" onClick={onHide}></div>
      <div className="position-absolute start-50 top-50 translate-middle">
        <button
          className="position-absolute top-0 end-0 m-2 text-dark bg-white rounded-pill border-dark border border-2"
          onClick={onHide}
          aria-label="close lightbox"
        >
          <FontAwesomeIcon icon={DISCARD_ICON}></FontAwesomeIcon>
        </button>
        <picture>
          <source
            media="(min-width: 1000px)"
            srcSet={image?.attributes.formats.large?.url}
          />
          <source
            media="(min-width: 750px)"
            srcSet={image?.attributes.formats.medium?.url}
          />
          <source
            media="(max-width: 500px)"
            srcSet={image?.attributes.formats.small?.url}
          />
          <img
            src={image?.attributes.url || src}
            alt={alt}
            className="constrain-size object-fit-contain"
          />
        </picture>
      </div>
    </div>
  );
};
