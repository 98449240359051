import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export const MODERATE_NAV_ITEMS = [
  {
    id: 1,
    name: "To Do",
    icon: icon({ name: "list", style: "solid" }),
    to: "/moderate",
  },
  {
    id: 2,
    name: "Photos",
    icon: icon({ name: "images", style: "solid" }),
    to: "/moderate/photos",
  },
  {
    id: 3,
    name: "Comments",
    icon: icon({ name: "comments", style: "solid" }),
    to: "/moderate/comments",
  },
  {
    id: 4,
    name: "Rejections",
    icon: icon({ name: "ban", style: "solid" }),
    to: "/moderate/rejections",
  },
  {
    id: 5,
    name: "Voting",
    icon: icon({ name: "ballot", style: "solid" }),
    to: "/moderate/voting"
  },
  {
    id: 6,
    name: "Users",
    icon: icon({ name: "user", style: "solid" }),
    to: "/moderate/users"
  }
];

export type MyActivityTab = "Liked Photos" | "Commented Photos";
