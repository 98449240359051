import { useCallback, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { MainNavbar } from "../navigation/MainNavbar";
import { ToastNotifier } from "../common/ToastNotifier";
import { OnboardingModal } from "../about/OnboardingModal";
import { SingletonConfirmationModal } from "../common/SingletonConfirmationModal";
import { AuthContext } from "../../context/AuthContext";

export const Layout = () => {
  const { currentUser } = useContext(AuthContext);

  const [showOnboarding, setShowOnboarding] = useState<boolean>(false);
  const handleHideOnboarding = useCallback(
    () => setShowOnboarding(false),
    [setShowOnboarding]
  );

  useEffect(() => {
    if (currentUser?.hasBeenOnboarded === false) {
      setShowOnboarding(true);
    }
  }, [currentUser, setShowOnboarding]);

  return (
    <div className="layout">
      <OnboardingModal show={showOnboarding} onHide={handleHideOnboarding} />
      <header className="navbar-container z-3">
        <MainNavbar />
      </header>

      <main>
        <ToastNotifier />
        <SingletonConfirmationModal />
        <Outlet />
      </main>

      <footer className="footer-label position-fixed bottom-0 end-0 p-2 mb-2 rounded-start shadow d-lg-block d-none">
        &copy; {new Date().getFullYear()} McCormick Taylor
      </footer>
    </div>
  );
};
