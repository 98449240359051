import { gql } from "@apollo/client";

export const NOTIFY_USER_ABOUT_REJECTION = gql`
  mutation NotifyUserAboutRejection($rejectionId: ID!) {
    notifyUserAboutRejection(rejectionId: $rejectionId) {
      success
      error
    }
  }
`;
