import { useCallback, useContext, useState } from "react";
import { BackendUrlContext } from "../context/BackendUrlContext";
import { AuthContext } from "../context/AuthContext";

export const useCsvDownload = (url: string, filename: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<string>("");

  const { jwt } = useContext(AuthContext);
  const backendUrl = useContext(BackendUrlContext);

  // In order to download a file, the browser has to load the URL pointing to the file (can't use AJAX).
  // The URL must be loaded through an anchor tag or through setting window.location.href.
  // The file endpoint must be protected under user auth, which strapi handles through HTTP headers.
  // There is no way to set HTTP headers in an anchor tag request, so the download endpoint can't be directly hit.
  // WORKAROUND: fetch the csv as a raw string, then programatically create + click an anchor tag that downloads the csv
  // string with a filename specified by the anchor tag's 'download' attribute.
  const onComplete = useCallback(
    async (response: Response) => {
      // preparing data for download
      const csv = await response.text();

      // creating and clicking anchor tag
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`
      );
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    [filename]
  );

  return {
    async exportCsv() {
      if (jwt === null) {
        throw new Error("User is not logged in.");
      }
      setIsLoading(true);
      setStatusMessage("Generating File...");

      try {
        const response = await fetch(`${backendUrl}/api${url}`, {
          method: "GET",
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        });

        if (response.ok) {
          setStatusMessage("Successfully generated CSV file, downloading...");
          await onComplete(response);
          setStatusMessage("Successfully downloaded CSV file.");
        } else {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
      } catch (err) {
        console.error(err);
        setStatusMessage(`Failed to generate CSV file`);
      }
      setIsLoading(false);
    },
    isLoading,
    statusMessage,
    url: `${backendUrl}/api${url}`,
  };
};
