import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import { SquareContainedPhoto } from "../common/SquareContainedPhoto";
import Stack from "react-bootstrap/esm/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IMAGE_ICON } from "../../constants/icons";
import { TextIconButton } from "../common/TextIconButton";
import Ratio from "react-bootstrap/esm/Ratio";

interface ImageInputWithPreviewProps {
  value: File | null;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onVisited?: Function;
  showValidation?: boolean;
}

export const ImageInputWithPreview: React.FC<ImageInputWithPreviewProps> = ({
  value,
  onChange,
  onVisited,
  showValidation = true
}) => {
  const [acceptingDrop, setAcceptingDrop] = useState<boolean>(false);
  const [photoUrl, setPhotoUrl] = useState<string>("");
  const [validationMessage, setValidationMessage] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  // update the photo url when the image file changes
  useEffect(() => {
    if (value !== null) {
      setPhotoUrl(URL.createObjectURL(value));
    }
  }, [value]);

  const handleChooseImageButtonClick = () => {
    if (inputRef.current === null) {
      return;
    }

    inputRef.current.click();

    if (onVisited) {
      onVisited();
    }
  };

  const handleDrop: React.DragEventHandler<HTMLDivElement> = (
    e: React.DragEvent<HTMLDivElement>
  ) => {
    e.preventDefault();

    if (
      inputRef.current === null ||
      e.dataTransfer === null ||
      e.dataTransfer.files.length < 1
    ) {
      return;
    }

    const file = e.dataTransfer.files[0];

    if (
      ["image/jpeg", "image/png", "image/webp"].includes(file.type) === false
    ) {
      setAcceptingDrop(false);
      setValidationMessage(
        "Photo must be an image file in format PNG, JPEG, or WEBP."
      );
      return;
    }

    inputRef.current.files = e.dataTransfer.files;
    const changeEvent = new Event("change", { bubbles: true });
    inputRef.current.dispatchEvent(changeEvent); // trigger onChange
    setAcceptingDrop(false);
  };

  const handleOnDragLeave: MouseEventHandler<HTMLDivElement> = (e) => {
    const startX = e.currentTarget.offsetLeft;
    const startY = e.currentTarget.offsetTop;
    const endX = startX + e.currentTarget.offsetWidth;
    const endY = startY + e.currentTarget.offsetHeight;

    const mouseX = e.clientX;
    const mouseY = e.clientY;

    if (
      mouseX <= startX ||
      mouseX >= endX ||
      mouseY <= startY ||
      mouseY >= endY
    ) {
      setAcceptingDrop(false);
    }
  };

  useEffect(() => {
    if (value === null) {
      setValidationMessage("Please input a photo");
    } else {
      setValidationMessage("");
    }
  }, [value]);

  return (
    <div>
      <Ratio
        aspectRatio="1x1"
        className={`position-relative border ${acceptingDrop ? "border-3 border-primary" : "border"
          }`}
        onDragEnter={() => {
          setAcceptingDrop(true);
        }}
        onDragLeave={handleOnDragLeave}
        onDragOver={(e) => {
          e.preventDefault();
          if (acceptingDrop === false) {
            setAcceptingDrop(true);
          }
        }}
        onDrop={handleDrop}
      >
        {value ? (
          <div>
            <SquareContainedPhoto
              photoUrl={photoUrl}
              alt="Preview of uploaded image file"
            />
          </div>
        ) : (
          <div className="top-50 start-50 translate-middle w-auto h-auto">
            <Stack
              className={`${acceptingDrop ? "text-primary" : "text-secondary"}`}
              gap={1}
            >
              <FontAwesomeIcon icon={IMAGE_ICON} size="6x" />

              <TextIconButton
                onClick={handleChooseImageButtonClick}
                text="Choose Image"
                icon={IMAGE_ICON}
                disabled={acceptingDrop}
              />

              <input
                id="image-input"
                type="file"
                name="image"
                accept="image/png, image/jpeg, image/webp"
                className="d-none"
                onChange={onChange}
                onBlur={() => onVisited && onVisited()}
                ref={inputRef}
              />

              <p className="text-center">or drag and drop file here</p>
            </Stack>
          </div>
        )}
      </Ratio>
      {(showValidation && value === null) && (
        <p className="m-0 text-validation">{validationMessage}</p>
      )}
    </div>
  );
};
