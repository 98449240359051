import React, { useContext } from "react";
import { ThemeConfigContext } from "../../context/ThemeConfigContext";

interface PageHeadingProps {
  className?: string;
}

export const PageHeading: React.FC<
  React.PropsWithChildren<PageHeadingProps>
> = ({ children, className }) => {
  const { smallLogoLightUrl, organizationName } = useContext(ThemeConfigContext);

  const baseUrl = window.location.origin;

  return (
    <div
      className={`page-heading fs-3 m-0 gap-2 align-items-center ${className}`}
    >
      <img
        src={baseUrl + '/' + smallLogoLightUrl}
        alt={`${organizationName} Logo`}
        className="d-block mt-logo"
        width="48"
        height="48"
      />
      <div className="vr"></div>
      {children}
    </div>
  );
};
