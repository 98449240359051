import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { FULLSCREEN_ICON } from "../../constants/icons";
import { CategoriesContext } from "../../context/CategoriesContext";
import { SettingsContext } from "../../context/SettingsContext";
import { useRestQueryBuilder } from "../../hooks/useRestQueryBuilder";
import {
  PaginatedRestIndexQuery,
  PhotoWithVoteData,
  QueryOptions,
  SelectOption,
} from "../../types";
import { Loader } from "../common/Loader";
import { TextIconButton } from "../common/TextIconButton";
import { Lightbox } from "../photo/Lightbox";
import { SelectFilterWidget } from "./SelectFilterWidget";
import { SortWidget } from "./SortWidget";
import { TextFilterWidget } from "./TextFilterWidget";
import { PaginationControl } from "../common/PaginationControl";
import { useCsvDownload } from "../../hooks/useCsvDownload";
import { AsyncTaskButton } from "./AsyncTaskButton";

export const ModerateVotesDashboard = () => {
  const categoryOptions: SelectOption<number>[] = useContext(
    CategoriesContext
  ).map((c) => ({
    value: c.id,
    displayName: c.attributes.displayName,
  }));
  const settings = useContext(SettingsContext);
  const [lightboxPhoto, setLightboxPhoto] = useState<PhotoWithVoteData | null>(
    null
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [transformedPhotos, setTransformedPhotos] =
    useState<PhotoWithVoteData[]>();
  const [query, setQuery] = useState<QueryOptions>({
    filters: [
      {
        field: "screeningStatus",
        comparison: "eq",
        value: "approved",
      },
    ],
    populate: ["user", "category", "photo", "comments", "likes", "votes"],
    pagination: {
      page: pageNumber,
      pageSize: 10,
    },
  });

  const { exportCsv, isLoading, statusMessage } = useCsvDownload(
    "/csv/votes",
    "photo-contest-votes_" + new Date().toISOString() + ".csv"
  );

  useEffect(() => {
    setQuery((q) => {
      q.pagination.page = pageNumber;

      return { ...q };
    });
  }, [setQuery, pageNumber]);

  const {
    data: photoData,
    loading,
    errors,
  } = useRestQueryBuilder<PaginatedRestIndexQuery<PhotoWithVoteData>>(
    "photosRanked",
    query
  );

  useEffect(() => {
    if (photoData === undefined) {
      setTransformedPhotos(undefined);
      return;
    }

    if (loading) {
      return;
    }

    let transformed = photoData.data;

    if (query.sort === undefined) {
      transformed = [...transformed].sort((a, b) => {
        const aSummary = getVoteMetricSummary(a);
        const bSummary = getVoteMetricSummary(b);

        if (aSummary.votes !== bSummary.votes) {
          return aSummary.votes > bSummary.votes ? -1 : 1;
        } else if (aSummary.likes !== bSummary.likes) {
          return aSummary.likes > bSummary.likes ? -1 : 1;
        } else if (aSummary.comments !== bSummary.comments) {
          return aSummary.comments > bSummary.comments ? -1 : 1;
        } else {
          return 0;
        }
      });
    }

    setTransformedPhotos(transformed);
  }, [photoData, query.sort, loading]);

  let body: JSX.Element;
  if (loading && (photoData === undefined || transformedPhotos === undefined)) {
    body = <Loader message="Loading Photos..." />;
  } else if (
    errors.length > 0 ||
    photoData === undefined ||
    transformedPhotos === undefined
  ) {
    body = (
      <div className="alert alert-warning" role="alert">
        <p className="fs-5 fw-semibold">
          There was an error loading voteable photos.
        </p>
        <ul>
          {errors.map((err, i) => (
            <li key={`err-${i}`}>{err}</li>
          ))}
        </ul>
        <Link to="/moderate">Back to Moderation Dashboard</Link>
      </div>
    );
  } else {
    const { page, total, pageSize, pageCount } = photoData.meta.pagination;

    body = (
      <>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="align-top">
                Rank
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Id&nbsp;
                <SortWidget query={query} setQuery={setQuery} field="id" />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Name&nbsp;
                <SortWidget query={query} setQuery={setQuery} field="name" />
                <TextFilterWidget
                  query={query}
                  setQuery={setQuery}
                  field="name"
                />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Category&nbsp;
                <SortWidget
                  query={query}
                  setQuery={setQuery}
                  field="category.id"
                />
                <SelectFilterWidget
                  query={query}
                  setQuery={setQuery}
                  field="category.id"
                  options={categoryOptions}
                />
              </th>
              <th scope="col" className="align-top">
                Photo
              </th>
              <th scope="col" className="align-top cursor-pointer">
                User&nbsp;
                <SortWidget
                  query={query}
                  setQuery={setQuery}
                  field={["user.lastName", "user.firstName"]}
                />
                <TextFilterWidget
                  query={query}
                  setQuery={setQuery}
                  field={["user.lastName", "user.firstName"]}
                />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Votes
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Likes
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Comments
              </th>
            </tr>
          </thead>

          <tbody>
            {transformedPhotos.map((p, index) => (
              <tr key={p.id}>
                <td className="fw-bold">
                  {query?.sort === undefined
                    ? (page - 1) * 10 + index + 1
                    : "-"}
                </td>
                <th scope="row">{p.id}</th>
                <td>{p.attributes.name}</td>
                <td>{p.attributes.category.data.attributes.displayName}</td>
                <td>
                  <TextIconButton
                    text="View Full"
                    icon={FULLSCREEN_ICON}
                    size="sm"
                    onClick={() => setLightboxPhoto(p)}
                  />
                </td>
                <td>
                  {p.attributes.user.data.attributes.lastName},{" "}
                  {p.attributes.user.data.attributes.firstName}
                </td>
                <td>
                  {p.attributes.votes.data.reduce(
                    (prev, curr) => prev + curr.attributes.voteCount,
                    0
                  )}
                </td>
                <td>{p.attributes.likes.data.length}</td>
                <td>{p.attributes.comments.data.length}</td>
              </tr>
            ))}
            {transformedPhotos.length === 0 && (
              <tr>
                <td colSpan={9} className="py-5 text-center">
                  <p className="text-secondary fst-italic">
                    There are no photos up for voting currently.
                  </p>
                  <Link to="/moderate">Back to Moderation Dashboard</Link>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <PaginationControl
          page={pageNumber}
          pageSize={pageSize}
          total={total}
          pageCount={pageCount}
          setPageNumber={setPageNumber}
        />
      </>
    );
  }
  return (
    <>
      <div className="container">
        <h2>Voting Statistics</h2>

        <p className="text-secondary">
          Voting runs from{" "}
          {new Date(settings.attributes.enableVotingDate).toLocaleString()} to{" "}
          {new Date(settings.attributes.disableVotingDate).toLocaleString()}
        </p>

        <div className="d-flex gap-2 align-items-center">
          <AsyncTaskButton callback={exportCsv}
            isLoading={isLoading}
            statusMessage={statusMessage}
            buttonText="Export Votes as CSV" />
        </div>

        {body}
      </div>

      <Lightbox
        show={lightboxPhoto !== null}
        image={lightboxPhoto?.attributes.photo.data}
        alt={lightboxPhoto?.attributes.name ?? "lightbox photo"}
        onHide={() => {
          setLightboxPhoto(null);
        }}
      />
    </>
  );
};

function getVoteMetricSummary(photo: PhotoWithVoteData) {
  return {
    votes: photo.attributes.votes.data.reduce(
      (sum, curr) => sum + curr.attributes.voteCount,
      0
    ),
    comments: photo.attributes.comments.data.length,
    likes: photo.attributes.likes.data.length,
  };
}
