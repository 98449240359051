import { useMutation } from "@apollo/client";
import { ChangeEvent, FormEvent, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CHANGE_EMAIL } from "../../../mutations/changeEmail";
import { ChangeEmailInput } from "../../../types";
import { publish } from "../../../util/events";
import { Loader } from "../../common/Loader";
import { confirmEventName } from "../../common/SingletonConfirmationModal";

export const ChangeEmail: React.FC = () => {
  const [input, setInput] = useState<ChangeEmailInput>({
    email: "",
    password: ""
  });
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);

  const [changeEmail, { data, loading, error }] = useMutation(CHANGE_EMAIL, {
    onError(err) {
      console.error(err);
    }
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(input => ({
      ...input,
      [e.target.name]: e.target.value
    }));
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    publish(
      confirmEventName,
      {
        message: "You will be signed out and required to confirm your new email before accessing your account again.",
        onConfirm: async () => {
          const res = await changeEmail({
            variables: {
              ...input
            }
          });

          if (!res.errors) {
            navigate('/auth/logout');
          }
        }
      }
    );
  }

  if (loading) {
    return <Loader message="Changing email..." />
  }

  return (
    <>
      {
        error && (
          <div className="alert alert-warning" role="alert">
            <p className="fs-5 fw-semibold">There was an error changing your email.</p>
            <p>{error.message ?? ""}</p>
          </div>
        )
      }
      {
        data && (
          <div className="alert alert-success" role="alert">
            <p className="fs-5 fw-semibold">Successfully changed your email. You are now being logged out.</p>
          </div>
        )
      }
      <form action="PATCH" className="d-flex flex-column gap-3" ref={formRef} onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email" className="form-label">
            New Email Address
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="form-control"
            required
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            className="form-control"
            required
            onChange={handleInputChange}
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Change Email
        </button>
      </form>
    </>
  );
}
