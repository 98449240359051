import React, { useContext, useRef } from "react";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { SlideshowContext } from "../../context/SlideshowContext";
import { animateContent } from "../../util/animateContent";

interface OnboardingCardProps {
  content: React.FC
}

export const OnboardingCard: React.FC<OnboardingCardProps> = ({
  content: ContentComponent
}) => {
  const { currentSlide, totalSlides, onNext, onLast } =
    useContext(SlideshowContext);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const handleNext = (): void => {
    if (contentRef.current === null) return;

    const contentContainer = contentRef.current;
    animateContent(contentContainer, onNext, "left", "right");
  };

  const handleBack = (): void => {
    if (contentRef.current === null) return;

    const card = contentRef.current;
    animateContent(card, onLast, "right", "left");
  };

  return (
    <div className="onboarding-card d-flex flex-column justify-content-between card-center flex-grow-1 overflow-hidden">
      <section
        ref={contentRef}
        className="p-3 text-center fs-5 overflow-y-auto animate-content content-center"
      >
        <ContentComponent />
      </section>

      <Stack
        direction="horizontal"
        gap={4}
        className="p-3 justify-content-center"
      >
        <Button
          onClick={handleBack}
          variant="secondary"
          size="lg"
          className="shadow"
          disabled={currentSlide === 0}
        >
          Back
        </Button>

        <Button
          onClick={handleNext}
          variant="secondary"
          size="lg"
          className="shadow"
          disabled={currentSlide === totalSlides - 1}
        >
          Next
        </Button>
      </Stack>
    </div>
  );
};
