import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, ChangeEvent, useEffect, useRef } from 'react';
import { FILTER_ICON } from '../../constants/icons';
import { QueryOptions } from "../../types";

type TextFilterWidgetProps = {
  query?: QueryOptions,
  setQuery: React.Dispatch<React.SetStateAction<QueryOptions>>,
  field: string | string[]
};

export function TextFilterWidget({ setQuery, field }: TextFilterWidgetProps) {
  const timeoutRef = useRef<NodeJS.Timeout>();

  // clears timeout on component unmount
  useEffect(() => () => {
    clearTimeout(timeoutRef.current);
  }, []);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }

      timeoutRef.current = setTimeout(() => {
        setQuery(q => {
          const filters = q.filters.filter(x => {
            if (Array.isArray(x.field) && Array.isArray(field)) {
              let isEqual = true;

              for (const f in x.field) {
                if (field.includes(f) === false) {
                  isEqual = false;
                  break;
                }
              }

              return isEqual;
            } else {
              return x.field === field;
            }
          });

          filters.push({
            field,
            comparison: 'containsi',
            value: e.target.value
          });

          return {
            ...q,
            filters
          };
        });
      }, 250);
    },
    [field, setQuery]
  );

  return (
    <span className="d-flex gap-2 align-items-center">
      <label htmlFor={`${field}-filter`} title="filter" className="order-1">
        <FontAwesomeIcon icon={FILTER_ICON} />
      </label>
      <input id={`${field}-filter`}
        type="text"
        className="form-control form-control-sm"
        name={`${field}-filter`}
        autoComplete="off"
        aria-autocomplete="none"
        onChange={handleChange} />
    </span>
  );
}
