import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { MouseEventHandler } from "react";
import Button, { ButtonProps } from "react-bootstrap/esm/Button";
import Stack from "react-bootstrap/esm/Stack";

interface TextIconButtonProps extends ButtonProps {
  text: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon?: IconDefinition;
  variant?: string;
  disabled?: boolean;
  className?: string;
  size?: "lg" | "sm";
}

export const TextIconButton: React.FC<TextIconButtonProps> = ({
  text,
  type = "button",
  onClick = () => {},
  icon,
  variant = "primary",
  disabled = false,
  className = "",
  size,
}) => {
  return (
    <Button
      disabled={disabled}
      variant={variant}
      onClick={onClick}
      size={size}
      type={type}
      className={className}
    >
      <Stack direction="horizontal" gap={2} className="justify-content-center">
        <span className="text-nowrap">{text}</span>
        {icon && <FontAwesomeIcon icon={icon} size="lg" />}
      </Stack>
    </Button>
  );
};
