import Spinner from 'react-bootstrap/esm/Spinner'

interface LoaderProps {
  message?: string
}

export const Loader: React.FC<LoaderProps> = ({ message = "loading..." }) => {
  return (
    <div className='h-100 d-flex justify-content-center align-items-center pb-7'>
      <div className="loader text-center p-3 shadow rounded">
        <p className="fs-5"><em>{message}</em></p>
        <Spinner />
      </div>
    </div>
  )
}
