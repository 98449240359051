import { gql } from "@apollo/client";

export const CREATE_REJECTION = gql`
  mutation CreateRejection(
    $photo: ID
    $comment: ID
    $moderator: ID!
    $reason: String!
  ) {
    createRejection(
      data: {
        photo: $photo
        comment: $comment
        moderator: $moderator
        reason: $reason
      }
    ) {
      data {
        id
        attributes {
          reason
          hasBeenCommunicated
          createdAt
          photo {
            data {
              id
              attributes {
                user {
                  data {
                    id
                    attributes {
                      firstName
                      lastName
                      email
                    }
                  }
                }
              }
            }
          }
          comment {
            data {
              id
              attributes {
                user {
                  data {
                    id
                    attributes {
                      firstName
                      lastName
                      email
                    }
                  }
                }
              }
            }
          }
          moderator {
            data {
              id
              attributes {
                firstName
                lastName
                email
              }
            }
          }
        }
      }
    }
  }
`;
