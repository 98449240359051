import { DocumentNode, gql } from "@apollo/client";

export const DELETE_PHOTO: DocumentNode = gql`
# Delete a photo given it's id
mutation DeletePhoto($id: ID!) {
  deletePhoto(id: $id) {
    data {
      id
    }
  }
}
`;