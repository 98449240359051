import React from "react";
import { Placeholder } from "react-bootstrap";

interface SquareFilledPhotoProps {
  photoUrl: string;
  alt: string;
}

export const SquareFilledPhoto: React.FC<SquareFilledPhotoProps> = ({
  photoUrl,
  alt,
}) => {
  const handleLoad: React.ReactEventHandler<HTMLImageElement> = (e) => {
    e.currentTarget.classList.add("loaded-img");
  };

  return (
    <div className="img-container ratio ratio-1x1 position-relative overflow-hidden">
      <Placeholder
        bg="secondary"
        as="img"
        className="position-absolute top-0 start-0 w-100 h-100"
      />
      <img
        onLoad={handleLoad}
        src={photoUrl}
        alt={alt}
        className="position-absolute top-0 start-0 w-100 h-100 object-fit-cover loadable-image"
      />
    </div>
  );
};
