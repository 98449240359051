import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { LoadingScreen } from "./LoadingScreen";

export const AuthPage: React.FC = () => {
  const { currentUser, loading, redirectUrl } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser !== null) {
      navigate("/");
    } else if (redirectUrl) {
      navigate(redirectUrl);
    }
  }, [currentUser, navigate, redirectUrl]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="position-fixed top-0 start-0 w-100 h-100 z-3 bg-body">
      <div className="position-absolute top-0 start-0 w-100 h-100 z-n1 bg-auth"></div>
      <Outlet />
    </div>
  );
};
