import React, { useState } from "react";
import { Photo } from "../../types";
import { LikeButton } from "./LikeButton";
import { SquareContainedPhoto } from "../common/SquareContainedPhoto";
import { dateToHowLongAgo } from "../../util/dateToHowLongAgo";
import { CategoryIcon } from "../common/CategoryIcon";
import { CommentSection } from "./CommentSection";
import { Lightbox } from "./Lightbox";
import { Link } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";

interface ColumnPhotoProps {
  photo: Photo;
  readonly?: boolean;
  noComments?: boolean;
  noLikes?: boolean;
  noUser?: boolean;
}

export const ColumnPhoto: React.FC<ColumnPhotoProps> = ({
  photo,
  readonly = false,
  noComments = false,
  noLikes = false,
  noUser = false,
}) => {
  const [showLightbox, setShowLightbox] = useState<boolean>(false);

  const {
    id,
    attributes: {
      name,
      photo: image,
      user,
      category,
      likes,
      comments,
      createdAt,
    },
  } = photo;

  const userFullName = `${user.data.attributes.firstName} ${user.data.attributes.lastName}`;

  const handleHide = () => {
    setShowLightbox(false);
  };

  return (
    <>
      <div id={`photo-id-${id}`} className="photo-feed-item mb-3 w-100">
        {!noUser && (
          <div className="feed-photo-section d-flex justify-content-between align-items-end">
            <p className="fs-5">
              <Link to={`/profile/${user.data.id}`} className="profile-link">
                {userFullName}
              </Link>
            </p>
            <p>{dateToHowLongAgo(createdAt)}</p>
          </div>
        )}
        <div className="feed-photo-section d-flex flex-column gap-2">
          <div
            className="h-100"
            onClick={() => {
              setShowLightbox(true);
            }}
          >
            <SquareContainedPhoto photo={image.data} alt={name} />
          </div>
          <h2 className="fs-3">{name}</h2>
          <h3 className="fs-5 fw-normal d-flex gap-2 align-items-center">
            <span
              // className="ratio-1x1 p-1 bg-light rounded shadow-sm"
              style={{ color: category.data.attributes.color }}
            >
              <CategoryIcon category={category.data} size="1x" />
            </span>
            {category.data.attributes.displayName}
          </h3>

          {
            photo.attributes.additionalData &&
            (() => {
              const prjData = photo.attributes.additionalData;

              return (
                <div>
                  <Row>
                    <Col>
                      <Form.Group className="mb-2">
                        <Form.Label>Project Name</Form.Label>
                        <Form.Control type="text" readOnly={true} value={prjData.projectName} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <Form.Group className="mb-2">
                        <Form.Label>Project Number</Form.Label>
                        <Form.Control type="text" readOnly={true} value={prjData.projectNumber} />
                      </Form.Group>
                    </Col>
                    <Col sm={8}>
                      <Form.Group className="mb-2">
                        <Form.Label>Client Name</Form.Label>
                        <Form.Control type="text" readOnly={true} value={prjData.clientName} />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              );
            })()
          }
        </div>

        {!noLikes && likes && (
          <div className="feed-photo-section">
            <LikeButton photoId={id} likes={likes} disabled={readonly} />
          </div>
        )}

        {!noComments && comments && (
          <CommentSection photo={photo} readonly={readonly} />
        )}
      </div>

      <Lightbox
        image={image.data}
        alt={name}
        show={showLightbox}
        onHide={handleHide}
      />
    </>
  );
};
