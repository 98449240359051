import { gql } from "@apollo/client";

export const LOGIN_WITH_EMAIL_AND_PASSWORD = gql`
  mutation LoginWithEmailAndPassword($email: String!, $password: String!) {
    login(input: {
      provider: ""
      accessToken: ""
      identifier: $email
      password: $password
    }) {
      jwt
      user {
        id
        username
        email
        voteBalance
        hasBeenOnboarded
        firstName
        lastName
        confirmed
        approved
        role {
          id
          type
        }
      }
    }
  }
`;
