import { createContext } from "react";
import { VoteSettings } from "../types";

type VoteSettingsContextType = {
  voteSettings?: VoteSettings,
  loading: boolean
};

export const VoteSettingsContext = createContext<VoteSettingsContextType>({
  voteSettings: undefined,
  loading: true
});
