import React from "react";
import { Media } from "../../types";

interface SquareContainedPhotoProps {
  photoUrl?: string;
  photo?: Media;
  alt: string;
}

export const SquareContainedPhoto: React.FC<SquareContainedPhotoProps> = ({
  photoUrl,
  photo,
  alt,
}) => {
  const handleLoad: React.ReactEventHandler<HTMLImageElement> = (e) => {
    e.currentTarget.classList.add("loaded-img");
  };

  return (
    <div className="overflow-hidden position-relative">
      <div
        className="aspect-ratio-square blur dim"
        style={{
          backgroundImage: `url("${
            photo?.attributes.formats.small?.url || photo?.attributes.url || photoUrl
          }")`,
        }}
      ></div>
      <div className="position-absolute top-0 left-0 w-100 h-100">
        <picture className="w-100 h-100 d-flex justify-content-center align-items-center">
          <source
            media="(min-width: 1200px)"
            srcSet={photo?.attributes.formats.medium?.url}
          />
          <source
            media="(max-width: 1199px)"
            srcSet={photo?.attributes.formats.small?.url}
          />
          <img
            onLoad={handleLoad}
            src={photo?.attributes.url || photoUrl}
            alt={alt}
            className="contain loadable-image"
          />
        </picture>
      </div>
    </div>
  );
};
