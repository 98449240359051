import { gql } from '@apollo/client';

export const UPDATE_USER_APPROVED = gql`
mutation UpdateUserApproved($userId: ID!, $approved: ENUM_USERSPERMISSIONSUSER_APPROVED!) {
	updateUsersPermissionsUser(
    id: $userId,
    data: {
      approved: $approved
    }
  ) {
    data {
      id
      attributes {
				approved
      }
    }
  }
}
`;
