import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const SET_VOTE: DocumentNode = gql`
  mutation SetVote($photoId: ID!, $count: Int) {
    setVote(photoId: $photoId, count: $count) {
      id
      count
    }
  }
`;