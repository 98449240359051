import React, { useEffect } from "react";
import { Comment as CommentType, Photo, SingleQuery } from "../../types";
import Modal from "react-bootstrap/esm/Modal";
import { useLazyQuery } from "@apollo/client";
import { GET_COMMENT_BY_ID } from "../../queries/getCommentById";
import { GET_PHOTO_BY_ID } from "../../queries/getPhotoById";
import { Comment } from "../photo/Comment";
import { ColumnPhoto } from "../photo/ColumnPhoto";

interface RejectionContentModalProps {
  show: boolean;
  onHide: () => void;
  contentType?: "Comment" | "Photo";
  contentId?: number;
}

export const RejectionContentModal: React.FC<RejectionContentModalProps> = ({
  show,
  onHide,
  contentType,
  contentId,
}) => {
  const [getComment, { data: commentData, loading: commentLoading }] =
    useLazyQuery<SingleQuery<CommentType>>(GET_COMMENT_BY_ID);
  const [getPhoto, { data: photoData, loading: photoLoading }] =
    useLazyQuery<SingleQuery<Photo>>(GET_PHOTO_BY_ID);

  useEffect(() => {
    if (contentType === undefined || contentId === undefined) {
      return;
    }

    if (contentType === "Photo") {
      getPhoto({
        variables: {
          id: contentId,
        },
      });
    } else if (contentType === "Comment") {
      getComment({
        variables: {
          id: contentId,
        },
      });
    }
  }, [contentType, getPhoto, getComment, contentId]);

  return (
    <Modal show={show} onHide={onHide} centered scrollable>
      <Modal.Header
        closeButton
        closeVariant="white"
        className="bg-theme-primary"
      ></Modal.Header>
      <Modal.Body>
        {(commentLoading || photoLoading) && <p>Loading...</p>}
        {contentType === "Comment" && commentData && (
          <Comment comment={commentData.comment.data} />
        )}
        {contentType === "Photo" && photoData && (
          <ColumnPhoto
            photo={photoData.photo.data}
            readonly
            noComments
            noLikes
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
