import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const CREATE_COMMENT: DocumentNode = gql`
  # Create a comment belonging to a user and a photo
  mutation CreateComment($user: ID!, $photo: ID!, $body: String!) {
    createComment(data: { user: $user, photo: $photo, body: $body }) {
      data {
        id
        attributes {
          body
          screeningStatus
          createdAt
          user {
            data {
              id
              attributes {
                firstName
                lastName
                email
              }
            }
          }
          photo {
            data {
              id
            }
          }
        }
      }
    }
  }
`;
