import { gql } from "@apollo/client";

export const GET_USER_LOGINS = gql`
query GetUserLogins($startDate: DateTime!, $endDate: DateTime!) {
	userLogins(
    filters: {
      and: [
        {
          createdAt: { gte: $startDate }
        },
        {
          createdAt: { lte: $endDate }
        }
      ]
    }
  ) {
    data {
      id
      attributes {
        createdAt
      }
    }
  }
}
`;
