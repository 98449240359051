import React, { Dispatch, SetStateAction } from "react";
import Modal from "react-bootstrap/esm/Modal";
import { PhotoUploadForm } from "../submit/PhotoUploadForm";

interface PhotoUploadModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  categoryCounts: {
    [categoryId: number]: number;
  };
}

export const PhotoUploadModal: React.FC<PhotoUploadModalProps> = ({
  show,
  setShow,
  categoryCounts,
}) => {
  const onHide = () => setShow(false);

  return (
    <Modal
      fullscreen="md-down"
      show={show}
      onHide={onHide}
      scrollable
      backdrop="static"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="text-white bg-theme-secondary"
      >
        <Modal.Title className="m-0">Photo Form</Modal.Title>
      </Modal.Header>
      <Modal.Body className="position-relative">
        <PhotoUploadForm
          onSubmitComplete={onHide}
          categoryCounts={categoryCounts}
        />
      </Modal.Body>
    </Modal>
  );
};
