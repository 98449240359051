import { useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeConfigContext } from "../../context/ThemeConfigContext";

export const LoginPicker: React.FC = () => {
  const { fullLogoDarkUrl, fullLogoLightUrl } = useContext(ThemeConfigContext);

  let logoUrl: string;
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    logoUrl = fullLogoDarkUrl;
  } else {
    logoUrl = fullLogoLightUrl;
  }

  const baseUrl = window.location.origin;

  return (
    <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
      <div className="rounded px-md-5 px-4 py-5 col-lg-6 bg-body shadow">
        <img src={baseUrl + '/' + logoUrl} alt="logo" className="w-100" />
        <h2 className="mb-4 text-center">Photo Contest Login</h2>
        <div className="d-flex flex-column align-items-center gap-2">
          <Link className="btn btn-primary" to="microsoft">
            Authenticate with Microsoft Account
          </Link>
          {/*
          <Link className="btn btn-primary" to="email">
            Log in with email and password
          </Link>
          <Link className="btn btn-secondary" to="/auth/register">
            Register email and password account
          </Link>

          <p className="text-secondary">
            Accounts registered with email and password must be approved by a moderator before the user can access their account.
          </p>
          */}
        </div>
      </div>
    </div>
  );
};
