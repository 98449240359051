import React from "react";
import { Pagination } from "../../types";

interface PaginationControlProps extends Pagination {
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

export const PaginationControl: React.FC<PaginationControlProps> = ({
  page,
  pageCount,
  pageSize,
  total,
  setPageNumber
}) => {
  const pageStart = Math.min(total, (page - 1) * pageSize + 1);
  const pageEnd = Math.min(total, page * pageSize);

  return (
    <>
      <p className="text-center text-secondary">
        Showing {pageStart} to {pageEnd} of {total}
      </p>
      <nav aria-label="Moderation navigation" className="mb-5">
        <ul className="pagination justify-content-center">
          <li className="page-item">
            <button
              className="page-link"
              onClick={() => setPageNumber((pn) => Math.max(pn - 1, 1))}
              disabled={page === 1 || page === 0}
              aria-label="Previous"
            >
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>
          {[...Array(pageCount)].map((_, i) => (
            <li className="page-item" key={i}>
              <button
                className={
                  "page-link " +
                  (page === i + 1 ? "text-decoration-underline" : "")
                }
                onClick={() => setPageNumber(i + 1)}
              >
                {i + 1}
              </button>
            </li>
          ))}
          <li className="page-item">
            <button
              className="page-link"
              onClick={() => setPageNumber((pn) => Math.min(pn + 1, pageCount))}
              disabled={page >= pageCount}
              aria-label="Next"
            >
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
};
