import { Photo } from "../types";

export function countPhotosByCategory(photos: Photo[]): {
  [categoryId: number]: number;
} {
  return photos
    .map((p) => p.attributes.category.data)
    .reduce<{ [categoryId: number]: number }>((previous, current) => {
      const categoryId = current.id;

      previous[categoryId] ||= 0;
      previous[categoryId]++;

      return previous;
    }, []);
}
