import { useContext } from 'react'
import Spinner from 'react-bootstrap/esm/Spinner'
import Stack from 'react-bootstrap/esm/Stack'
import { ThemeConfigContext } from '../../context/ThemeConfigContext'

export const LoadingScreen = () => {
  const { fullLogoLightUrl, fullLogoDarkUrl, organizationName } = useContext(ThemeConfigContext);

  let logoUrl: string;
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    logoUrl = fullLogoDarkUrl;
  } else {
    logoUrl = fullLogoLightUrl;
  }

  const baseUrl = window.location.origin;

  return (
    <Stack
      className="justify-content-center align-items-center vh-100"
    >
      {fullLogoLightUrl && (
        <>
          <img
            src={baseUrl + '/' + logoUrl}
            alt={`${organizationName} Logo`}
            className="loading-screen-logo mw-100 p-2 object-fit-contain"
          />
          <h1 className="mb-5">Photo Contest</h1>
        </>
      )}

      <p className="text-secondary mb-2"><em>loading...</em></p>
      <Spinner animation="border" role="status" variant="primary" />
    </Stack>
  )
}
