import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { Photo, UsePhotosHook } from "../types";
import { DocumentNode, QueryHookOptions, useQuery } from "@apollo/client";
import { IndexQuery } from "../types";

export const usePhotos: UsePhotosHook = (
  dataKey: string = "photos",
  query: DocumentNode,
  options: QueryHookOptions = {}
) => {
  const { currentUser } = useContext(AuthContext);
  const [photos, setPhotos] = useState<Photo[]>([]);

  const { data, loading, fetchMore } = useQuery<IndexQuery<Photo>>(query, {
    ...options,
    variables: {
      userId: currentUser?.id,
      start: 0,
      limit: 5,
      ...options.variables,
    },
  });

  const getNextPage = (): void => {
    if (data === undefined) {
      return;
    }

    fetchMore({
      variables: { start: data[dataKey].data.length || 0 },
    });
  };

  useEffect(() => {
    if (data === undefined) {
      return;
    }

    setPhotos(data[dataKey].data || []);
  }, [data, dataKey]);

  return {
    photos,
    setPhotos,
    loading,
    getNextPage,
  };
};
