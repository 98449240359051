import { useCallback, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Media } from "../types";
import { BackendUrlContext } from "../context/BackendUrlContext";

export const useMedia = () => {
  const { jwt } = useContext(AuthContext);
  const backendUrl = useContext(BackendUrlContext);

  /**
   * Upload a media file to the server, resolves with created Media object.
   */
  const createMedia = useCallback(
    async (file: File): Promise<Media> => {
      if (jwt === null) throw new Error("User is not signed in");

      const formData = new FormData();
      formData.append("files", file, file.name);

      // make fetch
      const response = await fetch(`${backendUrl}/api/upload`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${jwt}`,
        },
        body: formData,
      });

      // parse result, and get image id
      const data = await response.json();
      const media: Media = {
        id: data[0].id,
        attributes: {
          url: data[0].url,
          formats: data[0].formats,
        },
      };

      return media;
    },
    [jwt, backendUrl]
  );

  return { createMedia };
};
