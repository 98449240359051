import { Photo } from "../types";

const SORT_ORDER = {
  approved: 0,
  rejected: 1,
  pending: 2,
  heldForReview: 3
};

export function sortPhotosByScreeningStatus(photos: Photo[]): Photo[] {
  return photos.sort(
    (photoA, photoB) =>
      SORT_ORDER[photoA.attributes.screeningStatus] -
      SORT_ORDER[photoB.attributes.screeningStatus]
  );
}
