import React, { useContext } from "react";
import Button from "react-bootstrap/esm/Button";
import { OnboardingCard } from "./OnboardingCard";
import { useSlideshow } from "../../hooks/useSlideshow";
import { SlideshowContext } from "../../context/SlideshowContext";
import { AuthContext } from "../../context/AuthContext";
import { UPDATE_USER_ONBOARDING } from "../../mutations/updateUserOnboarding";
import { useMutation } from "@apollo/client";
import { GET_ME } from "../../queries/getMe";

interface OnboardingSlideshowProps {
  slides: React.FC[];
  startingSlide?: number;
  onHide: () => void;
}

export const OnboardingSlideshow: React.FC<OnboardingSlideshowProps> = ({
  slides,
  startingSlide,
  onHide,
}) => {
  const slideshowData = useSlideshow(slides, startingSlide);
  const { currentSlide, totalSlides } = slideshowData;
  const { currentUser } = useContext(AuthContext);
  const [updateUserOnboarding] = useMutation(UPDATE_USER_ONBOARDING, {
    refetchQueries: [GET_ME],
  });

  const isLastSlide = currentSlide === totalSlides - 1;

  const handleClose = (): void => {
    if (currentUser === null || currentUser.hasBeenOnboarded) {
      return;
    }

    updateUserOnboarding({
      variables: {
        id: currentUser.id,
      },
    });

    onHide();
  };

  const slideElements = slides.map(
    (slide: React.FC): JSX.Element => <OnboardingCard content={slide} />
  );

  return (
    <>
      <SlideshowContext.Provider value={slideshowData}>
        {slideElements[currentSlide]}
      </SlideshowContext.Provider>
      <div className="text-center m-3">
        {!currentUser?.hasBeenOnboarded && isLastSlide && (
          <Button
            onClick={handleClose}
            variant="primary"
            size="lg"
            className="shadow"
          >
            Close
          </Button>
        )}
      </div>
    </>
  );
};
