import { LinkContainer } from "react-router-bootstrap";
import { TextIconButton } from "./TextIconButton";
import { UPLOAD_ICON } from "../../constants/icons";
import { useContext } from "react";
import { EndOfFeedMessageContext } from "../../context/EndOfFeedMessageContext";

export const BottomOfFeed = () => {
  const { message, showUploadPrompt } = useContext(EndOfFeedMessageContext);

  return (
    <div className="text-center text-secondary m-4">
      <p className="mb-2">
        <em>{message}</em>
      </p>
      {showUploadPrompt && (
        <LinkContainer to="/my-photos">
          <TextIconButton
            text="Upload Photos"
            icon={UPLOAD_ICON}
            variant="secondary"
          />
        </LinkContainer>
      )}
    </div>
  );
};
