import { useCallback, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useMutation } from "@apollo/client";
import { CREATE_LIKE } from "../mutations/createLike";
import { DELETE_LIKE } from "../mutations/deleteLike";
import { Like } from "../types";

export const useLikes = (
  photoId: number,
  likes: { count: number; currentUsersLike: { data: Like | null } }
): {
  count: number;
  isLiked: boolean;
  onClick: React.MouseEventHandler;
} => {
  const { currentUser } = useContext(AuthContext);

  const [addLike, { loading: createLikeLoading }] = useMutation(CREATE_LIKE, {
    update(cache, { data: { createLike } }) {
      cache.modify({
        id: `PhotoEntity:${photoId}`,
        fields: {
          attributes(existing = {}) {
            return {
              ...existing,
              likes: {
                count: existing.likes.count + 1,
                currentUsersLike: createLike,
              },
            };
          },
        },
      });
    },
    optimisticResponse: {
      createLike: {
        __typename: "LikeEntityResponse",
        data: {
          __typename: "LikeEntity",
          id: "temp-id",
          attributes: {
            __typename: "Like",
            user: {
              __typename: "UsersPermissionsUserEntityResponse",
              data: {
                __typename: "UsersPermissionsUserEntity",
                id: currentUser?.id,
              },
            },
            photo: {
              __typename: "PhotoEntityResponse",
              data: {
                __typename: "PhotoEntity",
                id: photoId,
              },
            },
          },
        },
      },
    },
  });

  const [removeLike, { loading: removeLikeLoading }] = useMutation(
    DELETE_LIKE,
    {
      update(cache, { data: { deleteLike } }) {
        if (deleteLike.data === null) {
          console.warn(`WARNING: Attempt to delete null data.`, deleteLike);
          return;
        }

        cache.modify({
          id: `PhotoEntity:${photoId}`,
          fields: {
            attributes(existing = {}) {
              return {
                ...existing,
                likes: {
                  count: existing.likes.count - 1,
                  currentUsersLike: { data: null },
                },
              };
            },
          },
        });
        cache.evict({ id: `LikeEntity:${deleteLike.data.id}` });
        cache.gc();
      },
    }
  );

  let clickHandler = useCallback(() => {
    if (createLikeLoading || removeLikeLoading || currentUser === null) {
    } else if (likes.currentUsersLike.data) {
      const likeId = likes.currentUsersLike.data?.id;
      removeLike({
        variables: { id: likeId },
        optimisticResponse: {
          deleteLike: {
            __typename: "LikeEntityResponse",
            data: {
              __typename: "LikeEntity",
              id: likeId,
              attributes: {
                __typename: "Like",
                photo: {
                  __typename: "PhotoEntityResponse",
                  data: null,
                },
              },
            },
          },
        },
      });
    } else {
      addLike({
        variables: {
          user: currentUser?.id,
          photo: photoId,
        },
      });
    }
  }, [
    createLikeLoading,
    removeLikeLoading,
    currentUser,
    likes,
    removeLike,
    addLike,
    photoId,
  ]);

  return {
    count: likes.count,
    isLiked: likes.currentUsersLike.data !== null,
    onClick: clickHandler,
  };
};
