import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { BackendUrlContext } from "../context/BackendUrlContext";
import { QueryOptions } from "../types";

type UseRestQueryBuilderOutput<T> = {
  data?: T,
  loading: boolean,
  errors: string[]
};

function transformFilterField(field: string) {
  return field.split('.').map(f => `[${f}]`).join('');
}

export function useRestQueryBuilder<T>(endpoint: string, query: QueryOptions): UseRestQueryBuilderOutput<T> {
  const backendUrl = useContext(BackendUrlContext);
  const { jwt } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<string[]>([]);
  const [data, setData] = useState<T>();

  useEffect(() => {
    (async function() {
      if (!endpoint || !jwt || !backendUrl) return;

      setLoading(true);

      const queries: string[] = [];

      for (const filter of query.filters) {
        if (Array.isArray(filter.field)) {
          let count = 0;
          for (const field of filter.field) {
            const transformedField = transformFilterField(field);
            queries.push(`filters[$or][${count}]${transformedField}[$${filter.comparison}]=${filter.value}`);
            count += 1;
          }
        } else {
          const transformedField = transformFilterField(filter.field);
          queries.push(`filters${transformedField}[$${filter.comparison}]=${filter.value}`);
        }
      }

      let populateCount = 0;
      for (const subfield of query.populate) {
        queries.push(`populate[${populateCount}]=${subfield}`);
        populateCount += 1;
      }

      if (query.sort) {
        if (Array.isArray(query.sort.field)) {
          let count = 0;
          for (const f of query.sort.field) {
            queries.push(`sort[${count}]=${f}:${query.sort.direction}`);
            count += 1;
          }
        } else {
          queries.push(`sort[0]=${query.sort.field}:${query.sort.direction}`);
        }
      }

      if (query.pagination) {
        queries.push(`pagination[page]=${query.pagination.page}`);
        queries.push(`pagination[pageSize]=${query.pagination.pageSize}`);
      }

      const url = `${backendUrl}/api/${endpoint}?${queries.join('&')}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });

      if (response.ok === false) {
        setErrors([response.statusText]);
        setData(undefined);
      } else {
        const parsedData = await response.json();

        setErrors([]);
        setData(parsedData);
      }

      setLoading(false);
    })();
  }, [query, backendUrl, endpoint, setData, setErrors, setLoading, jwt]);

  return {
    data,
    loading,
    errors
  };
}
