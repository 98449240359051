import { useMutation } from "@apollo/client";
import { Photo, Comment } from "../types";
import { CREATE_REJECTION } from "../mutations/createRejection";
import { UPDATE_PHOTO_SCREENING_STATUS } from "../mutations/updatePhotoScreeningStatus";
import { UPDATE_COMMENT_SCREENING_STATUS } from "../mutations/updateCommentScreeningStatus";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { GET_COMMENTS_BY_SCREENING_STATUS } from "../queries/getCommentsByScreeningStatus";
import { GET_COMMENTS_BY_PHOTO } from "../queries/getCommentsByPhoto";
import { GET_REJECTIONS } from "../queries/getRejections";

export const useReject = (): {
  reject: (
    contentType: "Photo" | "Comment",
    content: Comment | Photo,
    reason: string
  ) => Promise<any>;
} => {
  const [createRejection] = useMutation(CREATE_REJECTION, {
    refetchQueries: [GET_REJECTIONS],
  });
  const [updatePhotoScreeningStatus] = useMutation(UPDATE_PHOTO_SCREENING_STATUS);
  const [updateCommentScreeningStatus] = useMutation(
    UPDATE_COMMENT_SCREENING_STATUS,
    {
      refetchQueries: [GET_COMMENTS_BY_SCREENING_STATUS, GET_COMMENTS_BY_PHOTO],
    }
  );
  const { currentUser } = useContext(AuthContext);

  return {
    reject: async (
      contentType: "Photo" | "Comment",
      content: Comment | Photo,
      reason: string
    ) => {
      if (currentUser === null) {
        throw new Error("Missing user context.");
      }

      const updateScreeningStatusOptions = {
        variables: { id: content.id, screeningStatus: "rejected" },
      };
      if (contentType === "Photo") {
        await updatePhotoScreeningStatus(updateScreeningStatusOptions);
      } else if (contentType === "Comment") {
        await updateCommentScreeningStatus(updateScreeningStatusOptions);
      }

      const createRejectionOptions = {
        variables: {
          [contentType.toLowerCase()]: content.id,
          reason,
          moderator: currentUser.id,
        },
      };

      return createRejection(createRejectionOptions);
    },
  };
};
