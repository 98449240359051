import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  APPROVED_ICON,
  REJECTED_ICON,
} from "../../constants/icons";
import { TextIconButton } from "../common/TextIconButton";
import { useMutation } from "@apollo/client";
import { publish } from "../../util/events";
import { Loader } from "../common/Loader";
import { PaginationControl } from "../common/PaginationControl";
import { UPDATE_USER_APPROVED } from "../../mutations/updateUserApproved";
import { PaginatedRestIndexQuery, QueryOptions, DetailedUser, SelectOption } from "../../types";
import { useRestQueryBuilder } from "../../hooks/useRestQueryBuilder";
import { SortWidget } from "./SortWidget";
import { TextFilterWidget } from "./TextFilterWidget";
import { SelectFilterWidget } from "./SelectFilterWidget";

const ACCOUNT_PROVIDER_OPTIONS: SelectOption<string>[] = [
  {
    value: 'microsoft',
    displayName: 'Microsoft'
  },
  {
    value: 'local',
    displayName: 'Local'
  }
];

const MODERATION_STATUS_OPTIONS: SelectOption<string>[] = [
  {
    value: 'approved',
    displayName: 'Approved'
  },
  {
    value: 'rejected',
    displayName: 'Rejected'
  },
  {
    value: 'pending',
    displayName: 'Pending'
  }
];

export const ModerateUsers: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [query, setQuery] = useState<QueryOptions>({
    filters: [],
    populate: [],
    pagination: {
      page: pageNumber,
      pageSize: 10
    }
  });

  const { data: userData, loading, errors } = useRestQueryBuilder<PaginatedRestIndexQuery<DetailedUser>>('users', query);

  const [setApprovedStatus] = useMutation(UPDATE_USER_APPROVED);

  useEffect(() => {
    setQuery((q) => {
      q.pagination.page = pageNumber;
      return { ...q };
    });
  }, [pageNumber]);

  const approveUser = useCallback(
    async (user: DetailedUser) => {
      if (user.attributes.approved === 'approved') return;

      await setApprovedStatus({
        variables: {
          userId: user.id,
          approved: "approved",
        },
      });

      publish("showToast", {
        id: `toast-${new Date().toISOString()}`,
        title: "User Approved",
        message: `User with ID ${user.id} is now admitted to the contest.`,
      });

      setQuery(q => ({ ...q }));
    },
    [setApprovedStatus]
  );

  const rejectUser = useCallback(
    async (user: DetailedUser) => {
      if (user.attributes.approved === 'rejected') return;

      await setApprovedStatus({
        variables: {
          userId: user.id,
          approved: "rejected",
        },
      });

      publish("showToast", {
        id: `toast-${new Date().toISOString()}`,
        title: "User Rejected",
        message: `User with ID ${user.id} will not be admitted to the contest.`,
      });

      setQuery(q => ({ ...q }));
    },
    [setApprovedStatus]
  );

  let body: JSX.Element;
  if (loading && userData === undefined) {
    body = <Loader message="Loading Users..." />;
  } else if (errors.length > 0 || userData === undefined) {
    body = (
      <div className="alert alert-warning" role="alert">
        <p className="fs-5 fw-semibold">There was an error loading users.</p>
        <ul>
          {
            errors.map((err, i) => (
              <li key={`err-${i}`}>{err}</li>
            ))
          }
        </ul>
        <Link to="/moderate">Back to Moderation Dashboard</Link>
      </div>
    );
  } else {
    const users = userData.data;

    const { total, pageSize, pageCount } = userData.meta.pagination;

    body = (
      <>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="align-top cursor-pointer">
                Id&nbsp;
                <SortWidget query={query} setQuery={setQuery} field="id" />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Last, First&nbsp;
                <SortWidget query={query} setQuery={setQuery} field={['lastName', 'firstName']} />
                <TextFilterWidget query={query}
                  setQuery={setQuery}
                  field={['lastName', 'firstName']} />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Email&nbsp;
                <SortWidget query={query} setQuery={setQuery} field="email" />
                <TextFilterWidget query={query}
                  setQuery={setQuery}
                  field="email" />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Registered At&nbsp;
                <SortWidget query={query} setQuery={setQuery} field="createdAt" />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Account Provider&nbsp;
                <SortWidget query={query} setQuery={setQuery} field="provider" />
                <SelectFilterWidget query={query}
                  setQuery={setQuery}
                  field="provider"
                  options={ACCOUNT_PROVIDER_OPTIONS} />
              </th>
              <th scope="col" className="align-top cursor-pointer">
                Moderation Status&nbsp;
                <SortWidget query={query} setQuery={setQuery} field="approved" />
                <SelectFilterWidget query={query}
                  setQuery={setQuery}
                  field="approved"
                  options={MODERATION_STATUS_OPTIONS} />
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((u) => (
              <tr key={u.id}>
                <th scope="row">{u.id}</th>
                <td>{u.attributes.lastName}, {u.attributes.firstName}</td>
                <td>{u.attributes.email}</td>
                <td>{new Date(u.attributes.createdAt).toLocaleString()}</td>
                <td className="text-capitalize">{u.attributes.provider}</td>
                <td>
                  <div className="d-flex gap-2">
                    <TextIconButton
                      text={u.attributes.approved === 'approved' ? 'Approved' : 'Approve'}
                      icon={APPROVED_ICON}
                      variant={u.attributes.approved === 'approved' ? 'success' : 'outline-success'}
                      size="sm"
                      onClick={() => approveUser(u)}
                    />
                    <TextIconButton
                      text={u.attributes.approved === 'rejected' ? 'Rejected' : 'Reject'}
                      icon={REJECTED_ICON}
                      variant={u.attributes.approved === 'rejected' ? 'danger' : 'outline-danger'}
                      size="sm"
                      onClick={() => rejectUser(u)}
                    />

                    {
                      u.attributes.approved === 'pending' && (
                        <span className="badge border border-secondary text-secondary align-self-center ms-3">Pending Review</span>
                      )
                    }
                  </div>
                </td>
              </tr>
            ))}

            {users.length === 0 && loading === false && (
              <tr>
                <td colSpan={6} className="py-5 text-center">
                  <p className="text-secondary fst-italic">
                    There are no more users to moderate.
                  </p>
                  <Link to="/moderate">Back to Moderation Dashboard</Link>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <PaginationControl
          page={pageNumber}
          pageSize={pageSize}
          total={total}
          pageCount={pageCount}
          setPageNumber={setPageNumber}
        />
      </>
    );
  }

  return (
    <>
      <section className="container">
        <h2>Moderate Users</h2>
        <p>
          <span className="fw-bold">Instructions: </span>
          Reject any users who should not be part of the contest. Users who register with an
          email and password may not be part of the contest's intended audience.
        </p>

        {/* <div className="d-flex gap-2 my-2">
          <TextIconButton text="Add User"
            icon={PLUS_ICON}
            variant="secondary"
            onClick={() => setShowAddUserModal(true)} />
          <UsersToCsv />
        </div> */}

        {body}
      </section>
    </>
  );
}
