import { gql } from '@apollo/client';

export const UPDATE_USER_INFO = gql`
mutation UpdateUserInfo($userId: ID!, $firstName: String!, $lastName: String!) {
	updateUsersPermissionsUser(
    id: $userId,
    data: {
      firstName: $firstName,
      lastName: $lastName
    }
  ) {
    data {
      id
      attributes {
        firstName
        lastName
      }
    }
  }
}
`;
