import { gql } from "@apollo/client";

export const GET_MODERATION_TASK_COUNTS = gql`
  query GetTaskCounts {
    rejections(
      filters: { hasBeenCommunicated: { eq: false } }
      pagination: { start: 0, limit: 0 }
    ) {
      data {
        id
      }
      meta {
        pagination {
          total
        }
      }
    }
    photos(
      filters: {
        or: [
          { screeningStatus: { eq: "pending" } },
          { screeningStatus: { eq: "heldForReview" } }
        ]
      }
      pagination: { start: 0, limit: 0 }
    ) {
      data {
        id
      }
      meta {
        pagination {
          total
        }
      }
    }
    comments(
      filters: { screeningStatus: { eq: "pending" } }
      pagination: { start: 0, limit: 0 }
    ) {
      data {
        id
      }
      meta {
        pagination {
          total
        }
      }
    }
    usersPermissionsUsers(
      filters: { approved: { eq: "pending" } }
      pagination: { start: 0, limit: 0 }
    ) {
      data {
        id
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
