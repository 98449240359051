import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { DISCARD_ICON, MENU_ICON } from "../../constants/icons";
import { NavItemType } from "../../types";

interface SubNavBarProps {
  navItems: NavItemType[];
}

export const SubNavBar: React.FC<SubNavBarProps> = ({ navItems }) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const location = useLocation();

  const toggleIsNavExpanded = () =>
    setIsNavExpanded((isNavExpanded) => !isNavExpanded);

  const navItemElements = navItems.map((navItem) => (
    <NavLink
      key={navItem.id}
      to={navItem.to}
      end
      className={({ isActive }) =>
        `sub-nav-item d-flex gap-2 align-items-center position-relative h-100 text-decoration-none text-white ${
          isActive && "active"
        }`
      }
      onClick={() => setIsNavExpanded(false)}
    >
      <FontAwesomeIcon icon={navItem.icon} size="lg" />
      <p className="m-0">{navItem.name}</p>
    </NavLink>
  ));

  const activeNavItem = navItems.find(
    (ni) => ni.to === location.pathname
  );

  return (
    <>
      <button
        className="d-lg-none d-flex align-items-center gap-2 bg-transparent border-0 text-white"
        onClick={toggleIsNavExpanded}
      >
        <FontAwesomeIcon
          icon={isNavExpanded ? DISCARD_ICON : MENU_ICON}
          size="2x"
        />
        {activeNavItem?.name}
      </button>
      <nav
        className={`sub-nav flex-grow-1 align-items-lg-center ${
          isNavExpanded ? "expanded" : ""
        }`}
      >
        {navItemElements}
      </nav>
    </>
  );
};
