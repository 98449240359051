import React from "react";

interface AsyncTaskButtonProps {
  callback: () => void;
  isLoading: boolean;
  statusMessage: string;
  buttonText: string;
}

export const AsyncTaskButton: React.FC<AsyncTaskButtonProps> = ({
  callback,
  isLoading,
  statusMessage,
  buttonText,
}) => {
  return (
    <div className="d-flex align-items-center gap-2">
      <button
        className="btn btn-primary text-capitalize"
        onClick={() => callback()}
        disabled={isLoading}
      >
        {buttonText}
      </button>
      <p className="m-0">{statusMessage}</p>
    </div>
  );
};
