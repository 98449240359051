import React, { useContext, useState } from "react";
import { Comment as CommentType } from "../../types";
import { dateToHowLongAgo } from "../../util/dateToHowLongAgo";
import { AuthContext } from "../../context/AuthContext";
import { RejectionFormModal } from "../moderate/RejectionFormModal";
import { publish } from "../../util/events";
import { Link } from "react-router-dom";

interface CommentProps {
  comment: CommentType;
  removeComment?: (id: number) => void;
}

export const Comment: React.FC<CommentProps> = ({
  comment,
  removeComment: removeCommentDom,
}) => {
  const [showRejectionForm, setShowRejectionForm] = useState<boolean>(false);
  const { currentUser } = useContext(AuthContext);

  const handleRemove = async () => {
    setShowRejectionForm(true);
  };

  const afterReject = () => {
    removeCommentDom && removeCommentDom(comment.id);

    publish("showToast", {
      id: `toast-${new Date().toISOString()}`,
      title: "Removal Successful",
      message: `Comment ${comment.id} is now hidden from public view.`,
    });
  };

  const { body, createdAt, user } = comment.attributes;

  const usersFullName = `${user.data.attributes.firstName} ${user.data.attributes.lastName}`;

  return (
    <>
      <RejectionFormModal
        show={showRejectionForm}
        onHide={() => setShowRejectionForm(false)}
        onReject={afterReject}
        contentType="Comment"
        content={comment}
      />
      <div>
        <div className="d-flex gap-2">
          <span className="fw-bold">
            <Link to={`/profile/${user.data.id}`} className="profile-link">
              {usersFullName}
            </Link>
          </span>
          <span className="text-secondary">{dateToHowLongAgo(createdAt)}</span>
          {currentUser?.role.type === "moderator" && removeCommentDom && (
            <button
              onClick={handleRemove}
              className="p-0 bg-transparent border-0 text-decoration-underline text-danger"
            >
              remove
            </button>
          )}
        </div>

        <p>{body}</p>
      </div>
    </>
  );
};
