import { AuthContext } from "../../context/AuthContext";
import { useAuthentication } from "../../hooks/useAuthentication";
import { LoadingScreen } from "./LoadingScreen";

export const AuthenticationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const authData = useAuthentication();

  if (authData.loading) {
    return <LoadingScreen />;
  }

  if (authData.authError) {
    return (
      <div className="alert alert-danger m-4">
        <p className="fs-5">Error: {authData.authError}</p>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
  );
};
