import React, { MouseEventHandler } from "react";
import { SquareFilledPhoto } from "../common/SquareFilledPhoto";
import { Photo } from "../../types";

interface GridPhotoProps {
  photo: Photo;
  onClick: MouseEventHandler;
  showVotes?: boolean;
}

export const GridPhoto: React.FC<GridPhotoProps> = ({
  photo,
  onClick,
  showVotes = false,
}) => {
  const { name, photo: image, votes } = photo.attributes;

  const { url, formats } = image.data.attributes;

  const isVoted = votes && votes.count > 0;

  return (
    <div
      onClick={onClick}
      className={"photo-grid-item p-1 position-relative cursor-pointer " + (showVotes && isVoted ? 'voted-photo' : '')}
    >
      <SquareFilledPhoto photoUrl={formats.small?.url || url} alt={name} />
    </div>
  );
};
