import { useRef, useContext } from 'react';
import { useAuthorization } from '../../../hooks/useAuthorization';
import { TopBar } from '../../common/TopBar';
import { ScrollableElementContext } from '../../../context/ScrollableElementContext';
import { AuthContext } from '../../../context/AuthContext';
import { Loader } from '../../common/Loader';
import { ChangeEmail } from './ChangeEmail';
import { ChangePassword } from './ChangePassword';
import { PageHeading } from '../../common/PageHeading';
import { UpdateUserInfo } from './UpdateUserInfo';

export const ManageAccountPage: React.FC = () => {
  useAuthorization("moderator", "authenticated");
  const scrollableRef = useRef(null);

  const { currentUser } = useContext(AuthContext);

  return (
    <section className="page d-flex flex-column">
      <TopBar pageTitle="My Account">
        <PageHeading className="d-lg-none d-flex">
          <h2 className="m-0 fw-bold fs-3">My Account</h2>
        </PageHeading>
      </TopBar>

      <div className="flex-grow-1 overflow-y-auto" ref={scrollableRef}>
        <ScrollableElementContext.Provider value={scrollableRef.current}>
          <div className="container py-lg-4 py-3">
            {
              currentUser ? (
                <div className="col-xl-6 col-lg-8 col-md-10 col-12 mx-auto p-4 border rounded">
                  <h2>{currentUser.firstName} {currentUser.lastName}</h2>
                  <p className="mb-2">{currentUser.email}</p>

                  <hr />
                  {
                    currentUser.provider === 'local' ? (
                      <>
                        <div className="mb-3">
                          <h3>Change Email</h3>

                          <ChangeEmail />
                        </div>
                        <hr />
                        <div className="mb-3">
                          <h3>Change Password</h3>

                          <ChangePassword />
                        </div>
                      </>
                    ) : (
                      <div>
                        <p className="mb-2">
                          You are logged in with a <span className="text-capitalize">{currentUser.provider}</span> account.
                        </p>
                      </div>
                    )
                  }
                  <hr />
                  <div className="mb-3">
                    <h3>Update User Info</h3>

                    <UpdateUserInfo />
                  </div>
                </div>
              ) : (
                <Loader message="Loading user data..." />
              )
            }
          </div>
        </ScrollableElementContext.Provider>
      </div>
    </section>
  );
}
