import React from "react";
import Stack from "react-bootstrap/esm/Stack";
import { PageHeading } from "./PageHeading";
import { IconButton } from "./IconButton";
import { HELP_ICON } from "../../constants/icons";

interface TopBarProps {
  pageTitle: string;
  showHelpButton?: boolean;
  onClickHelp?: () => void;
}

/**
 * Takes a pageTitle (string) as props, as well as child control elements that will appear on the right side of the header.
 */
export const TopBar: React.FC<React.PropsWithChildren<TopBarProps>> = ({
  pageTitle,
  showHelpButton,
  onClickHelp = () => { },
  children, // controllable elements for the page
}) => {
  return (
    <div className="top-bar-container sticky-top z-2">
      <Stack
        direction="horizontal"
        className="top-bar bg-theme-secondary text-white p-3 justify-content-between z-2"
      >
        <PageHeading>
          <h2 className="m-0 fw-bold fs-3">{pageTitle}</h2>
          {showHelpButton && (
            <IconButton
              onClick={onClickHelp}
              icon={HELP_ICON}
              className="text-white opacity-75"
              size="lg"
              title="Help"
            />
          )}
        </PageHeading>

        <div className="top-bar-controls d-flex align-items-center justify-content-between gap-2">
          {React.Children.toArray(children)}
        </div>
      </Stack>
    </div>
  );
};
