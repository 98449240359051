import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/index.scss";
import { App } from "./App";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fas as fasPro } from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(fas, fasPro);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <App>
    <RouterProvider router={router} />
  </App>
);
