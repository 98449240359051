import React, {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useContext,
  useState,
} from "react";
import { ImageInputWithPreview } from "./ImageInputWithPreview";
import Form from "react-bootstrap/esm/Form";
import { TextIconButton } from "../common/TextIconButton";
import { RESET_ICON, UPLOAD_ICON } from "../../constants/icons";
import { CategoriesContext } from "../../context/CategoriesContext";
import { publish } from "../../util/events";
import { usePhotoForm } from "../../hooks/usePhotoForm";
import { SettingsContext } from "../../context/SettingsContext";
import { Loader } from "../common/Loader";
import Markdown from "react-markdown";
import { Col, Modal, Row } from "react-bootstrap";
import { Category } from "../../types";

interface PhotoUploadFormProps {
  onSubmitComplete: Function;
  categoryCounts: {
    [categoryId: number]: number;
  };
}

type BoolDict = {
  [key: string]: boolean
};

export const PhotoUploadForm: React.FC<PhotoUploadFormProps> = ({
  onSubmitComplete,
  categoryCounts,
}) => {
  const [validated, setValidated] = useState<BoolDict>({});
  const [loading, setLoading] = useState(false);
  const [hasReadDisclaimer, setHasReadDisclaimer] = useState(false);
  const categories = useContext(CategoriesContext);
  const { formData, submit, reset, setField, isValid, isValidField } =
    usePhotoForm();
  const {
    attributes: {
      maxPhotosPerCategory,
      isSubmissionsEnabled,
      maxPhotoNameLength
    },
  } = useContext(SettingsContext);

  const { image, name, category } =
    formData;

  const onClickClear: MouseEventHandler<HTMLButtonElement> = () => {
    setValidated({});
    reset();
  }

  // fires when input element changes
  const onChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { type, name, value, files } = e.target;

    setField(name, type === "file" ? files && files[0] : value);
  };

  // fires when select element changes
  const onChangeSelect: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const { name, value } = e.target;

    setField(name, value);
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    if (!hasReadDisclaimer) return;
    setLoading(true);

    if (isSubmissionsEnabled) {
      const response = await submit();

      if (response.errors) {
        // combines all error messages into a single string.
        const errorMessage = response.errors
          .map((em) => em.message)
          .join(" - ");

        publish("showToast", {
          id: `toast-${new Date().toISOString()}`,
          title: "Submission Failed",
          message: errorMessage,
        });
      } else {
        publish("showToast", {
          id: `toast-${new Date().toISOString()}`,
          title: "Submission Successful",
          message:
            "Photo was successfully uploaded. You will be notified via email when a moderator has reviewed it for publishing.",
        });
        reset();
        onSubmitComplete();
      }
    } else {
      publish("showToast", {
        id: `toast-${new Date().toISOString()}`,
        title: "Submissions Unavailable",
        message: "Submissions are not enabled currently.",
      });
    }

    setLoading(false);
  };

  return (
    <>
      <Form noValidate
        className="d-flex flex-column gap-2"
        onSubmit={onSubmit}>
        <ImageInputWithPreview onChange={onChangeInput}
          showValidation={!!validated["image"]}
          onVisited={() => setValidated(v => ({ ...v, image: true }))}
          value={image} />

        <Form.Group controlId="photoName">
          <Form.Label>Photo Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Photo Name"
            name="name"
            value={name || ""}
            onChange={onChangeInput}
            onBlur={() => setValidated(v => ({ ...v, name: true }))}
            isValid={validated["name"] && isValidField("name")}
            isInvalid={validated["name"] && !isValidField("name")}
            autoComplete="off"
          />
          <Form.Control.Feedback type="invalid">
            Name must be between 0 and {maxPhotoNameLength} characters.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="category">
          <Form.Label>Category</Form.Label>
          <Form.Select
            name="category"
            value={category || ""}
            onChange={onChangeSelect}
            onBlur={() => setValidated(v => ({ ...v, category: true }))}
            isValid={validated["category"] && isValidField("category")}
            isInvalid={validated["category"] && !isValidField("category")}
          >
            <option value="">Choose a Category</option>
            {categories.map((c) => (
              <option
                key={c.id}
                value={c.attributes.name}
                disabled={categoryCounts[c.id] >= maxPhotosPerCategory}
              >
                {c.attributes.displayName}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please enter a category
          </Form.Control.Feedback>
          <Form.Text>
            <CategoryFormText category={categories.find(c => c.attributes.name === category)} />
          </Form.Text>
        </Form.Group>

        {
          formData.category === "project" && (
            <fieldset>
              <Row>
                <Col>
                  <Form.Group controlId="projectName">
                    <Form.Label>Project Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex: Swamp Creek Bridge #40C"
                      name="projectName"
                      value={formData.projectName || ""}
                      onChange={onChangeInput}
                      onBlur={() => setValidated(v => ({ ...v, projectName: true }))}
                      isValid={validated["projectName"] && isValidField("projectName")}
                      isInvalid={validated["projectName"] && !isValidField("projectName")}
                      autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please include the project name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={4} className="mb-2">
                  <Form.Group controlId="projectNumber">
                    <Form.Label>Project Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex: 05885.000"
                      name="projectNumber"
                      value={formData.projectNumber || ""}
                      onChange={onChangeInput}
                      onBlur={() => setValidated(v => ({ ...v, projectNumber: true }))}
                      isValid={validated["projectNumber"] && isValidField("projectNumber")}
                      isInvalid={validated["projectNumber"] && !isValidField("projectNumber")}
                      autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please include the project number.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm={8}>
                  <Form.Group controlId="clientName">
                    <Form.Label>Client Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex: Berks County"
                      name="clientName"
                      value={formData.clientName || ""}
                      onChange={onChangeInput}
                      onBlur={() => setValidated(v => ({ ...v, clientName: true }))}
                      isValid={validated["clientName"] && isValidField("clientName")}
                      isInvalid={validated["clientName"] && !isValidField("clientName")}
                      autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please include the client name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>
          )
        }

        <Form.Group className="d-flex gap-2 border p-2 mt-2">
          <Form.Check
            id="disclaimer-check"
            checked={hasReadDisclaimer}
            onChange={(e) => {
              setHasReadDisclaimer(e.target.checked);
              // mark image as validated in case it hasn't been input yet
              setValidated(v => ({ ...v, image: true }));
            }}
          />
          <Form.Label className="m-0" htmlFor="disclaimer-check">
            <span className="fw-bold">I understand </span> that any photo
            uploaded and approved in the McCormick Taylor photo contest has the
            potential to be distributed by the Marketing department internally
            and publicly.
          </Form.Label>
        </Form.Group>

        <Form.Group className="d-flex gap-2 justify-content-end">
          <TextIconButton
            text="Clear"
            icon={RESET_ICON}
            variant="secondary"
            onClick={onClickClear}
            type="reset"
            disabled={loading}
          />
          <TextIconButton
            text="Save"
            icon={UPLOAD_ICON}
            variant="primary"
            type="submit"
            disabled={!isValid() || !hasReadDisclaimer || loading}
          />
        </Form.Group>
      </Form>
      {loading && (
        <>
          <div className="position-fixed w-100 h-100 top-0 start-0 dim"></div>
          <div className="position-fixed top-50 start-50 translate-middle">
            <Loader message="Submitting Photo..." />
          </div>
        </>
      )}
    </>
  );
};

interface CategoryFormTextProps {
  category?: Category
};

function CategoryFormText({ category }: CategoryFormTextProps): JSX.Element {
  const [showDescriptionModal, setShowDescriptionModal] = useState<boolean>(false);

  if (!category) {
    return <></>;
  }

  return (
    <>
      <div className="d-flex gap-2 align-items-center flex-wrap my-2 text-secondary">
        <div>
          {category.attributes.details}
        </div>
        <button type="button"
          className="btn btn-sm btn-secondary"
          onClick={() => setShowDescriptionModal(true)}>
          Learn More
        </button>
      </div>

      <Modal show={showDescriptionModal}
        onHide={() => setShowDescriptionModal(false)}
        backdrop="static"
        centered={true}
        backdropClassName="nested-backdrop"
        dialogClassName="nested-dialog"
        fullscreen="sm-down">
        <Modal.Header closeButton
          closeVariant="white"
          className="text-white bg-theme-primary">
          <Modal.Title>{category.attributes.displayName}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Markdown>{category.attributes.description}</Markdown>
        </Modal.Body>
      </Modal>
    </>
  );
}
