import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useModerationTaskCounts } from "../../hooks/useModerationTaskCounts";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useUserLoginData } from "../../hooks/useUserLoginData";
import { Loader } from '../common/Loader';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ONE_WEEK_MS = 1000 * 60 * 60 * 24 * 6;

export const ModerateIndex = () => {
  const [chartData, setChartData] = useState<ChartData<'bar', number[]>>();
  const [startDate] = useState<Date>(new Date(Date.now() - ONE_WEEK_MS));
  const [endDate] = useState<Date>(new Date());
  const taskCounts = useModerationTaskCounts();

  const { data, loading, error } = useUserLoginData(startDate, endDate);

  useEffect(() => {
    setChartData({
      labels: data.map(d => d.label),
      datasets: [{
        label: "# of logins",
        data: data.map(d => d.count),
        backgroundColor: 'rgb(100, 100, 255)',
        borderWidth: 1
      }]
    })
  }, [data]);

  const options = {
    plugins: {
      title: {
        display: true,
        text: "User Logins in Past Week",
        color: '#ddd'
      }
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <section className="col-xl-4 col-12 order-xl-0 order-1">
          <nav>
            <ul className="mt-4 d-flex flex-column gap-2 list-unstyled mb-0">
              <li className="border rounded p-3 d-flex flex-wrap gap-2 align-items-center">
                <span className="count-bubble bg-success">
                  {taskCounts.photos}
                </span>
                <h3 className="mb-0">Photos</h3>
                <Link to="/moderate/photos" className="ms-auto moderate-index-link">
                  Moderate Photos
                </Link>
              </li>
              <li className="border rounded p-3 d-flex flex-wrap gap-2 align-items-center">
                <span className="count-bubble bg-success">
                  {taskCounts.comments}
                </span>
                <h3 className="mb-0">Comments</h3>
                <Link to="/moderate/comments" className="ms-auto moderate-index-link">
                  Moderate Comments
                </Link>
              </li>
              <li className="border rounded p-3 d-flex flex-wrap gap-2 align-items-center">
                <span className="count-bubble bg-success">
                  {taskCounts.rejections}
                </span>
                <h3 className="mb-0">Rejections</h3>
                <Link to="/moderate/rejections" className="ms-auto moderate-index-link">
                  Manage Rejections
                </Link>
              </li>
              <li className="border rounded p-3 d-flex flex-wrap gap-2 align-items-center">
                <h3 className="mb-0">Voting</h3>
                <Link to="/moderate/voting" className="ms-auto moderate-index-link">
                  View Voting Stats
                </Link>
              </li>
              <li className="border rounded p-3 d-flex flex-wrap gap-2 align-items-center">
                <span className="count-bubble bg-success">
                  {taskCounts.users}
                </span>
                <h3 className="mb-0">Users</h3>
                <Link to="/moderate/users" className="ms-auto moderate-index-link">
                  Moderate Users
                </Link>
              </li>
            </ul>
          </nav>
        </section>

        <section className="col-xl-8 col-12">
          <div className="border rounded mt-4 py-3">
            {
              loading &&
              <Loader message="Loading user logins" />
            }
            {
              !loading && error &&
              <div className="alert alert-danger">
                <p>
                  {error.message}
                </p>
              </div>
            }
            {
              !loading && !error && chartData &&
              <Bar data={chartData} options={options} />
            }
          </div>
        </section>
      </div>
    </div>
  );
};
