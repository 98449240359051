import { useCallback, useContext, useEffect } from "react";
import { GridPhoto } from "../photo/GridPhoto";
import { useCallbackWhenAtBottom } from "../../hooks/useCallbackWhenAtBottom";
import { Photo } from "../../types";
import { ScrollableElementContext } from "../../context/ScrollableElementContext";

interface GridPhotoDisplayProps {
  photos: Photo[];
  onScrollBottom?: () => void;
  onClickPhoto: (photo: Photo) => void;
  showVotes?: boolean;
}

export const GridPhotoDisplay: React.FC<GridPhotoDisplayProps> = ({
  photos,
  onScrollBottom,
  onClickPhoto,
  showVotes = false,
}) => {
  const refetch = useCallback(() => {
    if (photos.length > 0 && onScrollBottom) {
      onScrollBottom();
    }
  }, [photos, onScrollBottom]);

  const ref = useCallbackWhenAtBottom<HTMLDivElement>(refetch, 1000);
  const scrollableRef = useContext(ScrollableElementContext);

  // resets scroll to the top on load, prevents repeated loading due to scroll being locked at the bottom after
  // transferring from the column/feed view.
  useEffect(() => {
    if (scrollableRef) {
      scrollableRef.scrollTop = 0;
    }
  }, [scrollableRef]);

  const photoElements = photos.map((photo) => (
    <GridPhoto
      key={photo.id}
      photo={photo}
      onClick={() => onClickPhoto(photo)}
      showVotes={showVotes}
    />
  ));

  return (
    <div ref={ref} className="photo-grid">
      {photoElements}
    </div>
  );
};
