import { gql } from "@apollo/client";

export const GET_VOTEABLE_PHOTOS_BY_CATEGORY = gql`
  query GetVoteablePhotosByCategory($categoryId: ID!) {
    photos(
      filters: {
        and: [
          { category: { id: { eq: $categoryId } } }
          { isVoteable: { eq: true } }
        ]
      }
    ) {
      data {
        id
        attributes {
          name
          createdAt
          moderatedAt
          additionalData
          category {
            data {
              id
              attributes {
                displayName
                icon
                color
                name
              }
            }
          }
          photo {
            data {
              id
              attributes {
                url
                formats
              }
            }
          }
          user {
            data {
              id
              attributes {
                firstName
                lastName
                email
              }
            }
          }
          votes {
            id
            count
          }
        }
      }
    }
  }
`;
