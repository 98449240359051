import React, { useEffect, useState } from "react";
import { publish } from "../../util/events";
import { useMutation } from "@apollo/client";
import {
  PaginatedRestIndexQuery,
  QueryOptions,
  Rejection,
  SelectOption,
  SuccessStatus,
} from "../../types";
import { UPDATE_REJECTION_COMMUNICATION_STATUS } from "../../mutations/updateRejectionCommunicationStatus";
import { PaginationControl } from "../common/PaginationControl";
import { RejectionContentModal } from "./RejectionContentModal";
import { Loader } from "../common/Loader";
import { Link } from "react-router-dom";
import { NOTIFY_USER_ABOUT_REJECTION } from "../../mutations/updateNotifyAndCompleteRejection";
import { confirmEventName } from "../common/SingletonConfirmationModal";
import { useRestQueryBuilder } from "../../hooks/useRestQueryBuilder";
import { SortWidget } from "./SortWidget";
import { TextFilterWidget } from "./TextFilterWidget";
import { SelectFilterWidget } from "./SelectFilterWidget";
import { useCsvDownload } from "../../hooks/useCsvDownload";
import { AsyncTaskButton } from "./AsyncTaskButton";

const COMMUNICATION_STATUS_OPTIONS: SelectOption<string>[] = [
  {
    value: "true",
    displayName: "Complete",
  },
  {
    value: "false",
    displayName: "Incomplete",
  },
];

export const ManageRejections = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rejectedContent, setRejectedContent] = useState<{
    id: number;
    type: "Comment" | "Photo";
  } | null>(null);
  const [query, setQuery] = useState<QueryOptions>({
    filters: [],
    sort: undefined,
    populate: ["moderator", "comment", "photo", "photo.user", "comment.user"],
    pagination: {
      page: pageNumber,
      pageSize: 10,
    },
  });

  const { exportCsv, isLoading, statusMessage } = useCsvDownload("/csv/rejections", "photo-contest-rejections_" + new Date().toISOString() + ".csv");

  const {
    data: rejectionData,
    loading,
    errors,
  } = useRestQueryBuilder<PaginatedRestIndexQuery<Rejection>>(
    "rejections",
    query
  );

  useEffect(() => {
    setQuery((q) => {
      q.pagination.page = pageNumber;
      return { ...q };
    });
  }, [pageNumber]);

  const [setRejectionCommunicationStatus] = useMutation(
    UPDATE_REJECTION_COMMUNICATION_STATUS
  );

  const [
    notifyUserAboutRejection,
    { data: notificationStatus, loading: notificationLoading },
  ] = useMutation<SuccessStatus>(NOTIFY_USER_ABOUT_REJECTION);

  const markRejectionAsComplete = async (rejectionId: number) => {
    const response = await setRejectionCommunicationStatus({
      variables: {
        id: rejectionId,
        hasBeenCommunicated: true,
      },
    });

    publish("showToast", {
      id: `toast-${new Date().toISOString()}`,
      title: "Marked as Complete",
      message: `Marked rejection with ID ${rejectionId} as completed.`,
    });

    setQuery((q) => ({ ...q }));

    return response;
  };

  const handleComplete = (rejectionId: number) => {
    publish(confirmEventName, {
      message:
        "Are you sure that the user was contacted directly by a moderator?",
      onConfirm: () => markRejectionAsComplete(rejectionId),
    });
  };

  let body: JSX.Element;
  if (loading && rejectionData === undefined) {
    body = <Loader message="Loading rejections..." />;
  } else if (errors.length > 0 || rejectionData === undefined) {
    body = (
      <div className="alert alert-danger my-3" role="alert">
        <h6 className="fs-5">Error: Failed to get rejections</h6>
        <ul>
          {errors.map((err, i) => (
            <li key={`err-${i}`}>{err}</li>
          ))}
        </ul>
      </div>
    );
  } else {
    const { pageSize, pageCount, total } = rejectionData.meta.pagination;

    body = (
      <>
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col" className="align-top cursor-pointer">
                  Id&nbsp;
                  <SortWidget query={query} setQuery={setQuery} field="id" />
                </th>
                <th scope="col" className="align-top cursor-pointer">
                  Moderator&nbsp;
                  <SortWidget
                    query={query}
                    setQuery={setQuery}
                    field={["moderator.lastName", "moderator.firstName"]}
                  />
                  <TextFilterWidget
                    query={query}
                    setQuery={setQuery}
                    field={["moderator.lastName", "moderator.firstName"]}
                  />
                </th>
                <th scope="col" className="align-top cursor-pointer">
                  Rejected At&nbsp;
                  <SortWidget
                    query={query}
                    setQuery={setQuery}
                    field="createdAt"
                  />
                </th>
                <th scope="col" className="align-top cursor-pointer">
                  Content
                  <SortWidget
                    query={query}
                    setQuery={setQuery}
                    field="photo.id"
                  />
                </th>
                <th scope="col" className="align-top cursor-pointer">
                  Reason&nbsp;
                  <SortWidget
                    query={query}
                    setQuery={setQuery}
                    field="reason"
                  />
                  <TextFilterWidget
                    query={query}
                    setQuery={setQuery}
                    field="reason"
                  />
                </th>
                <th scope="col" className="align-top cursor-pointer">
                  User
                  <SortWidget
                    query={query}
                    setQuery={setQuery}
                    field={[
                      "photo.user.lastName",
                      "comment.user.lastName",
                      "photo.user.firstName",
                      "comment.user.firstName",
                    ]}
                  />
                  <TextFilterWidget
                    query={query}
                    setQuery={setQuery}
                    field={[
                      "photo.user.lastName",
                      "photo.user.firstName",
                      "comment.user.firstName",
                      "comment.user.lastName",
                    ]}
                  />
                </th>
                <th scope="col" className="align-top cursor-pointer">
                  Communication&nbsp;
                  <SortWidget
                    query={query}
                    setQuery={setQuery}
                    field="hasBeenCommunicated"
                  />
                  <SelectFilterWidget
                    query={query}
                    setQuery={setQuery}
                    field="hasBeenCommunicated"
                    options={COMMUNICATION_STATUS_OPTIONS}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {rejectionData.data.map((r) => {
                let contentType: "Comment" | "Photo",
                  userFullName: string,
                  contentId: number;
                if (r.attributes.comment.data !== null) {
                  contentType = "Comment";
                  contentId = r.attributes.comment.data.id;
                  const user = r.attributes.comment.data.attributes.user.data;
                  userFullName = `${user.attributes.lastName}, ${user.attributes.firstName}`;
                } else if (r.attributes.photo.data !== null) {
                  contentType = "Photo";
                  contentId = r.attributes.photo.data.id;
                  const user = r.attributes.photo.data.attributes.user.data;
                  userFullName = `${user.attributes.lastName}, ${user.attributes.firstName}`;
                } else {
                  return (
                    <tr>
                      <td colSpan={8}>
                        Invalid Row: Could not determine content type.
                      </td>
                    </tr>
                  );
                }

                return (
                  <tr key={r.id}>
                    <th scope="row">{r.id}</th>
                    <td>
                      {r.attributes.moderator.data.attributes.lastName},{" "}
                      {r.attributes.moderator.data.attributes.firstName}
                    </td>
                    <td>{new Date(r.attributes.createdAt).toLocaleString()}</td>
                    <td>
                      <button
                        className="btn border-secondary text-secondary"
                        type="button"
                        onClick={() =>
                          setRejectedContent({
                            type: contentType,
                            id: contentId,
                          })
                        }
                      >
                        {contentType}
                      </button>
                    </td>
                    <td>{r.attributes.reason}</td>
                    <td>{userFullName}</td>
                    <td>
                      <div className="d-flex gap-2">
                        <button
                          className="btn border-success text-success"
                          type="button"
                          disabled={
                            r.attributes.hasBeenCommunicated ||
                            notificationLoading
                          }
                          onClick={async () => {
                            const res: any = await notifyUserAboutRejection({
                              variables: { rejectionId: r.id },
                            });

                            if (res.data?.notifyUserAboutRejection.success) {
                              markRejectionAsComplete(r.id);
                            } else {
                              publish("showToast", {
                                id: Date.now(),
                                title: "Failed to send message",
                                message: `Error sending the automated rejection notification email: ${notificationStatus?.error}`,
                              });
                            }
                          }}
                        >
                          {r.attributes.hasBeenCommunicated
                            ? "Completed"
                            : "Notify User & Mark Complete"}
                        </button>
                        <button
                          className="btn border-secondary text-secondary"
                          type="button"
                          onClick={() => handleComplete(r.id)}
                          disabled={
                            r.attributes.hasBeenCommunicated ||
                            notificationLoading
                          }
                        >
                          {r.attributes.hasBeenCommunicated
                            ? "Completed"
                            : "Mark Complete"}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}

              {rejectionData.data.length === 0 && (
                <tr>
                  <td colSpan={8} className="py-5 text-center">
                    <p className="text-secondary fst-italic">
                      There are no more rejections to manage.
                    </p>
                    <Link to="/moderate">Back to Moderation Dashboard</Link>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <PaginationControl
          page={pageNumber}
          pageSize={pageSize}
          pageCount={pageCount}
          total={total}
          setPageNumber={setPageNumber}
        />
      </>
    );
  }

  return (
    <>
      <RejectionContentModal
        show={rejectedContent !== null}
        onHide={() => setRejectedContent(null)}
        contentId={rejectedContent?.id}
        contentType={rejectedContent?.type}
      />

      <section className="container">
        <div>
          <h2>Manage Rejections</h2>
          <p>
            <span className="fw-bold">Instructions:</span> Employees whose
            photos or comments are rejected by a moderator should receive some
            form of communication from a moderator. This can be an automated
            email or a personal message from the moderator. The items below are
            rejections that have not yet been communicated to the affected
            users.
          </p>
        </div>

        <div className="d-flex gap-2 align-items-center">
          <AsyncTaskButton callback={exportCsv}
            isLoading={isLoading}
            statusMessage={statusMessage}
            buttonText="Export Rejections as CSV" />
        </div>

        {body}
      </section>
    </>
  );
};
