import React, { useContext, useRef, useState } from "react";
import { DocumentNode } from "graphql";
import { IndexQuery, Photo, Vote } from "../../types";
import { ScrollableElementContext } from "../../context/ScrollableElementContext";
import { PhotoDisplayContainer } from "../common/PhotoDisplayContainer";
import { CategoriesContext } from "../../context/CategoriesContext";
import { AuthContext } from "../../context/AuthContext";
import { usePhotos } from "../../hooks/usePhotos";
import { GridPhoto } from "../photo/GridPhoto";
import { Lightbox } from "../photo/Lightbox";
import { useMutation, useQuery } from "@apollo/client";
import { SET_VOTE } from "../../mutations/setVote";
import { GET_ME } from "../../queries/getMe";
import { VoteSettingsContext } from "../../context/VoteSettingsContext";
import { GET_VOTES_BY_USER } from "../../queries/getVotesByUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { STAR_ICON, EMPTY_STAR_ICON, ARROW_RIGHT, ARROW_LEFT } from "../../constants/icons";
import { OverlayTrigger, Tooltip, TooltipProps } from "react-bootstrap";
import { CategoryIcon } from "../common/CategoryIcon";

const AddVoteTooltip = (props: TooltipProps) => (
  <Tooltip {...props}>
    Add Vote
  </Tooltip>
);

const RemoveVoteTooltip = (props: TooltipProps) => (
  <Tooltip {...props}>
    Remove Vote
  </Tooltip>
);

interface VoteSubpageProps {
  query: DocumentNode;
  photosDataKey: string;
}

export const VoteSubpage: React.FC<VoteSubpageProps> = ({
  query,
  photosDataKey,
}) => {
  const categories = useContext(CategoriesContext);
  const [categoryIndex, setCategoryIndex] = useState<number>(0);
  const { currentUser } = useContext(AuthContext);
  const { voteSettings } =
    useContext(VoteSettingsContext);

  const { photos, loading } = usePhotos(photosDataKey, query, {
    variables: {
      categoryId: categories[categoryIndex].id,
    },
  });
  const [lightboxPhoto, setLightboxPhoto] = useState<Photo | null>(null);
  const scrollableRef = useRef(null);

  const [setVote, { loading: setVoteLoading }] = useMutation(SET_VOTE);

  const { data: usersVotes } = useQuery<
    IndexQuery<Vote>
  >(GET_VOTES_BY_USER, {
    variables: {
      userId: currentUser?.id,
    },
  });

  let userTotalVotes = -1,
    userTotalVotesThisCategory = -1;
  if (usersVotes !== undefined) {
    userTotalVotes = usersVotes.votes.data.reduce(
      (sum, curr) => (sum += curr.attributes.voteCount),
      0
    );

    userTotalVotesThisCategory = usersVotes.votes.data
      .filter(
        (v) =>
          v.attributes.photo.data.attributes.category.data.id ===
          categories[categoryIndex].id
      )
      .reduce((sum, curr) => (sum += curr.attributes.voteCount), 0);
  }

  const handleClickGridPhoto = (photo: Photo) => {
    setLightboxPhoto(photo);
  };

  const handleNextCategory = () => {
    setCategoryIndex((curr) => (curr + 1) % categories.length);
  };

  const handlePrevCategory = () => {
    setCategoryIndex((curr) => (curr === 0 ? categories.length - 1 : curr - 1));
  };

  const handleVotePhoto = (p: Photo) => {
    let count: number;
    if (p.attributes.votes?.count) {
      count = 0;
    } else {
      count = 1;
    }

    if (
      voteSettings === undefined ||
      userTotalVotes === -1 ||
      userTotalVotesThisCategory === -1 ||
      (voteSettings.attributes.maxVotes &&
        userTotalVotes + count > voteSettings.attributes.maxVotes) ||
      (voteSettings.attributes.maxVotesPerCategory &&
        userTotalVotesThisCategory + count >
        voteSettings.attributes.maxVotesPerCategory)
    ) {
      return;
    }

    setVote({
      variables: {
        photoId: p.id,
        count,
      },
      update(cache, { data: { setVote } }) {
        cache.modify({
          id: `PhotoEntity:${p.id}`,
          fields: {
            attributes(existing = {}) {
              return {
                ...existing,
                votes: setVote,
              };
            },
          },
        });
        cache.modify({
          id: `UsersPermissionsUser:${currentUser?.id}`,
          fields: {
            voteBalance(existing = 0) {
              return existing + count - setVote.count;
            },
          },
        });
      },
      refetchQueries: [GET_ME, GET_VOTES_BY_USER],
    });
  };

  return (
    <>
      <div className="flex-grow-1 overflow-y-auto" ref={scrollableRef}>
        <ScrollableElementContext.Provider value={scrollableRef.current}>
          <div className="container pb-lg-5 pb-0">
            {voteSettings &&
              voteSettings.attributes.maxVotes &&
              userTotalVotes !== -1 && (
                <p className="mb-0 mt-2 text-secondary">
                  {voteSettings.attributes.maxVotes - userTotalVotes}{" "}
                  of {voteSettings.attributes.maxVotes} votes remaining overall.
                </p>
              )}

            <div className="d-flex justify-content-between align-items-center gap-3 my-3">
              <h3 className="fs-5 fw-normal d-flex gap-2 align-items-center mb-0">
                <span style={{ color: categories[categoryIndex].attributes.color }}>
                  <CategoryIcon category={categories[categoryIndex]} size="1x" />
                </span>
                {categories[categoryIndex].attributes.displayName}
              </h3>
              <button
                className="btn text-secondary border-secondary order-first"
                type="button"
                onClick={handlePrevCategory}
                aria-label="Previous category"
              >
                <FontAwesomeIcon icon={ARROW_LEFT} size="xl" />
              </button>
              <button
                className="btn text-secondary border-secondary"
                type="button"
                onClick={handleNextCategory}
                aria-label="Next category"
              >
                <FontAwesomeIcon icon={ARROW_RIGHT} size="xl" />
              </button>
            </div>

            {voteSettings &&
              voteSettings.attributes.maxVotesPerCategory &&
              userTotalVotesThisCategory !== -1 && (
                <p className="text-secondary">
                  {voteSettings.attributes.maxVotesPerCategory -
                    userTotalVotesThisCategory}{" "}
                  of {voteSettings.attributes.maxVotesPerCategory} votes
                  remaining in this category.
                </p>
              )}

            <PhotoDisplayContainer
              loading={loading}
              photos={photos}
              showBottomOfFeed={false}
            >
              <div className="photo-grid voting-grid">
                {photos.map((p) => (
                  <div className="d-flex flex-column mb-3" key={p.id}>
                    <GridPhoto
                      key={p.id}
                      photo={p}
                      onClick={() => handleClickGridPhoto(p)}
                      showVotes={true}
                    />
                    <p className="fs-5 mb-1">{p.attributes.name}</p>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={p.attributes.votes?.count ? RemoveVoteTooltip : AddVoteTooltip}
                    >
                      {p.attributes.votes?.count ? (
                        <button
                          className="btn text-secondary border-secondary bg-transparent"
                          type="button"
                          disabled={setVoteLoading}
                          onClick={() => handleVotePhoto(p)}
                        >
                          <FontAwesomeIcon icon={STAR_ICON}
                            size="2x"
                            color="goldenrod" />
                        </button>
                      ) : (
                        <button
                          className="btn text-secondary border-secondary bg-transparent"
                          type="button"
                          disabled={
                            setVoteLoading ||
                            userTotalVotes ===
                            voteSettings?.attributes.maxVotes ||
                            userTotalVotesThisCategory ===
                            voteSettings?.attributes.maxVotesPerCategory
                          }
                          onClick={() => handleVotePhoto(p)}
                        >
                          <FontAwesomeIcon icon={EMPTY_STAR_ICON}
                            size="2x"
                            color="goldenrod" />
                        </button>
                      )}
                    </OverlayTrigger>
                  </div>
                ))}
              </div>
            </PhotoDisplayContainer>
          </div>
        </ScrollableElementContext.Provider>
      </div>
      <Lightbox
        show={lightboxPhoto !== null}
        image={lightboxPhoto?.attributes.photo.data}
        alt={lightboxPhoto?.attributes.name ?? "lightbox photo"}
        onHide={() => {
          setLightboxPhoto(null);
        }}
      />
    </>
  );
};

export const voteSubpageWithQuery = (props: VoteSubpageProps): React.FC => {
  return () => <VoteSubpage {...props} />;
};
