import { gql } from "@apollo/client";

export const GET_REJECTIONS = gql`
  query GetRejections($page: Int!, $pageSize: Int!) {
    rejections(
      filters: { hasBeenCommunicated: { eq: false } }
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      data {
        id
        attributes {
          reason
          createdAt
          hasBeenCommunicated
          isHeldForReview
          photo {
            data {
              id
              attributes {
                user {
                  data {
                    id
                    attributes {
                      firstName
                      lastName
                      email
                    }
                  }
                }
              }
            }
          }
          comment {
            data {
              id
              attributes {
                user {
                  data {
                    id
                    attributes {
                      firstName
                      lastName
                      email
                    }
                  }
                }
              }
            }
          }
          moderator {
            data {
              id
              attributes {
                firstName
                lastName
                email
              }
            }
          }
          heldForReviewByUser {
            data {
              id
              attributes {
                firstName
                lastName
                email
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          pageCount
          total
        }
      }
    }
  }
`;
