import { Link } from "react-router-dom"

export const UserNotYetApproved: React.FC = () => {
  return (
    <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
      <div className="rounded px-md-5 px-4 py-5 bg-body shadow col-lg-6">
        <h2 className="mb-3">User not approved</h2>
        <p className="mb-3">A moderator must approve your account first before you can access the contest. Please try again later.</p>
        <Link className="btn btn-primary" to="/auth/login">
          Back to login
        </Link>
      </div>
    </div>
  )
}
