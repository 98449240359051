import { gql } from '@apollo/client';

export const CHANGE_EMAIL = gql`
mutation ChangeEmail($email: String!, $password: String!) {
  changeEmail(
    email: $email,
    password: $password
  ) {
    jwt
  }
}
`;
