import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { FILTER_ICON } from '../../constants/icons';
import { QueryOptions, Filter, SelectOption } from '../../types';

type SelectFilterWidgetProps = {
  query?: QueryOptions,
  setQuery: React.Dispatch<React.SetStateAction<QueryOptions>>,
  field: string,
  options: SelectOption<number | string>[]
};

export function SelectFilterWidget({ query, setQuery, field, options }: SelectFilterWidgetProps) {
  const [filter, setFilter] = useState<Filter>();

  useEffect(() => {
    setFilter(query?.filters.find(x => x.field === field));
  }, [setFilter, query, field]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setQuery(q => {
        const filters = q.filters.filter(x => x.field !== field);

        if (e.target.value !== 'null') {
          filters.push({
            field,
            comparison: 'eq',
            value: e.target.value
          });
        }

        return {
          ...q,
          filters
        };
      });
    },
    [setQuery, field]
  );

  return (
    <span className="d-flex gap-2 align-items-center">
      <label htmlFor={`${field}-filter`} title="filter" className="order-1">
        <FontAwesomeIcon icon={FILTER_ICON} />
      </label>
      <select id={`${field}-filter`}
        className="form-select form-select-sm"
        name={`${field}-filter`}
        autoComplete="off"
        value={filter?.value ?? 'null'}
        onChange={handleChange}>
        <option value="null">All</option>
        {
          options.map(o => (
            <option key={`option-${o.value}`} value={o.value}>
              {o.displayName}
            </option>
          ))
        }
      </select>
    </span>
  );
}
