import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export const LIKE_ICON = icon({ name: "thumbs-up", style: "solid" });

export const UNLIKE_ICON = icon({ name: "thumbs-up", style: "regular" });

export const BALLOT_ICON = icon({ name: "ballot", style: "solid" });

export const SEND_ICON = icon({ name: "paper-plane", style: "solid" });

export const GRID_ICON = icon({ name: "grid", style: "solid" });

export const UPLOAD_ICON = icon({ name: "upload", style: "solid" });

export const PLUS_ICON = icon({ name: "plus", style: "solid" });

export const IMAGES_ICON = icon({ name: "images", style: "solid" });

export const DISCARD_ICON = icon({ name: "xmark", style: "solid" });

export const EDIT_ICON = icon({ name: "pen-to-square", style: "solid" });

export const TRASH_ICON = icon({ name: "trash-can", style: "solid" });

export const APPROVED_ICON = icon({ name: "check", style: "solid" });

export const REJECTED_ICON = icon({ name: "ban", style: "solid" });

export const HOLD_FOR_REVIEW_ICON = icon({ name: "octagon-exclamation", style: "solid" });

export const SKIP_ICON = icon({ name: "trash-undo", style: "solid" });

export const PENDING_ICON = icon({ name: "clock", style: "solid" });

export const SCREENING_STATUS_ICONS = {
  approved: APPROVED_ICON,
  rejected: REJECTED_ICON,
  pending: PENDING_ICON,
};

export const RESET_ICON = icon({ name: "eraser", style: "solid" });

export const LOADING_ICON = icon({ name: "hourglass-half", style: "solid" });

export const COMMENT_ICON = icon({ name: "comment", style: "solid" });

export const IMAGE_ICON = icon({ name: "image", style: "solid" });

export const UNDO_ICON = icon({ name: "undo", style: "solid" });

export const FLAG_ICON = icon({ name: "flag-checkered", style: "solid" });

export const LIST_ICON = icon({ name: "list", style: "solid" });

export const SUBMIT_ICON = icon({ name: "circle-plus", style: "solid" });

export const MY_ACTIVITY_ICON = icon({ name: "comments", style: "solid" });

export const MODERATE_ICON = icon({ name: "id-card", style: "solid" });

export const WARNING_ICON = icon({
  name: "triangle-exclamation",
  style: "solid",
});

export const HELP_ICON = icon({ name: "question-circle", style: "solid" });

export const CAMERA_ICON = icon({ name: "camera", style: "solid" });

export const FILTER_ICON = icon({ name: "filter", style: "regular" });

export const MENU_ICON = icon({ name: "bars", style: "solid" });

export const VOTE_ICON = icon({ name: "check-to-slot", style: "solid" });

export const FULLSCREEN_ICON = icon({ name: "expand-arrows", style: "solid" });

export const STAR_ICON = icon({ name: "star", style: "solid" });

export const EMPTY_STAR_ICON = icon({ name: "star", style: "regular" });

export const PRIZES_ICON = icon({ name: "medal", style: "solid" });

export const SEARCH_ICON = icon({ name: "search", style: "regular" });

export const LOGOUT_ICON = icon({ name: "door-open", style: "regular" });

export const ACCOUNT_ICON = icon({ name: "circle-user", style: "solid" });

export const SORT_ICON = icon({ name: 'sort', style: 'regular' });

export const SORT_UP_ICON = icon({ name: 'sort-up', style: 'regular' });

export const SORT_DOWN_ICON = icon({ name: 'sort-down', style: 'regular' });

export const ARROW_RIGHT = icon({ name: 'arrow-right', style: 'solid' });

export const ARROW_LEFT = icon({ name: 'arrow-left', style: 'solid' });

export const CLOSE_ICON = icon({ name: 'close', style: 'solid' });
