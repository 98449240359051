import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const UPDATE_USER_ONBOARDING: DocumentNode = gql`
  mutation UpdateUser($id: ID!) {
    updateUsersPermissionsUser(
      id: $id
      data: {
        hasBeenOnboarded: true
      }
    ) {
      data {
        id
        attributes {
          hasBeenOnboarded
        }
      }
    }
  }
`