import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PointerEventHandler } from "react";

interface MyPhotoCardButtonProps {
  text: string;
  icon: IconDefinition;
  onClick: PointerEventHandler<HTMLButtonElement>;
  className?: string;
}

export const MyPhotoCardButton: React.FC<MyPhotoCardButtonProps> = ({
  text,
  icon,
  onClick,
  className,
}) => {
  return (
    <button
      className={`btn btn-sm btn-outline border-secondary text-secondary ${className}`}
      onClick={onClick}
    >
      {text} <FontAwesomeIcon icon={icon} size="1x"></FontAwesomeIcon>
    </button>
  );
};
